/*! =========================================================
 *
 * Material Kit PRO - V1.3.0
 *
 * =========================================================
 *
 * Product Page: https://www.creative-tim.com/product/material-kit-pro
 * Available with purchase of license from https://www.creative-tim.com/product/material-kit-pro
 * Copyright 2017 Creative Tim (https://www.creative-tim.com)
 * License Creative Tim (https://www.creative-tim.com/license)
 *
 * ========================================================= */
/* ANIMATION */
/* SHADOWS */
/* Shadows (from mdl http://www.getmdl.io/) */
body {
  /* background-color: #E5E5E5; */
  background-color: #ffffff;
  color: #3c4858;
}

body.inverse {
  background: #333333;
}

body.inverse,
body.inverse .form-control {
  color: #ffffff;
}

body.inverse .modal,
body.inverse .modal .form-control,
body.inverse .panel-default,
body.inverse .panel-default .form-control,
body.inverse .card,
body.inverse .card .form-control {
  background-color: initial;
  color: initial;
}

.nav-open-absolute body {
  overflow: hidden;
}

blockquote p {
  font-style: italic;
}

.life-of-material-kit {
  background: #ffffff;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

.serif-font {
  font-family: "Roboto Slab", "Times New Roman", serif;
}

a {
  color: #004000;
}

a:hover,
a:focus {
  color: #fff;
  text-decoration: none;
}

a.text-info:hover,
a.text-info:focus {
  color: #00a5bb;
}

a .material-icons {
  vertical-align: middle;
}

.dropdown.open .caret,
.dropup.open .caret,
.btn-group.bootstrap-select.open .caret {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

/*           Animations              */
.animation-transition-general,
.carousel .carousel-indicators li {
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
}

.animation-transition-slow {
  -webkit-transition: all 370ms linear;
  -moz-transition: all 370ms linear;
  -o-transition: all 370ms linear;
  -ms-transition: all 370ms linear;
  transition: all 370ms linear;
}

.caret {
  -webkit-transition: transform 150ms ease 0s;
  -moz-transition: transform 150ms ease 0s;
  -o-transition: transform 150ms ease 0s;
  -ms-transition: transform 150ms ease 0s;
  transition: transform 150ms ease 0s;
}

.bootstrap-tagsinput .tag,
.bootstrap-tagsinput [data-role="remove"],
.animation-transition-fast,
.navbar,
.panel .panel-heading i,
.bootstrap-datetimepicker-widget table td > div,
.bootstrap-datetimepicker-widget table th > div,
.bootstrap-datetimepicker-widget table th,
.bootstrap-datetimepicker-widget table td span,
.presentation-page .section-cards .card,
.index-page .section-cards .card,
.product-page .flexi-nav > li a {
  -webkit-transition: all 150ms ease 0s;
  -moz-transition: all 150ms ease 0s;
  -o-transition: all 150ms ease 0s;
  -ms-transition: all 150ms ease 0s;
  transition: all 150ms ease 0s;
}

legend {
  border-bottom: 0;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: 0;
}

a:focus,
a:active,
button:active,
button:focus,
button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: 0 !important;
}

.mb-5 {
  margin-bottom: 15px;
}

.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  padding-top: 0;
}

.form-horizontal .radio {
  margin-bottom: 10px;
}

.form-horizontal label {
  text-align: right;
}

.form-horizontal label.control-label {
  margin: 0;
}

.form-newsletter .input-group,
.form-newsletter .form-group {
  float: left;
  width: 78%;
  margin-right: 2%;
  margin-top: 9px;
}

.form-newsletter .btn {
  float: left;
  width: 20%;
  margin: 9px 0 0;
}

.form-file-upload .input-group-btn:last-child > .btn-round {
  border-radius: 30px;
}

.form-file-upload .input-group {
  width: 100%;
}

.btn,
.navbar .navbar-nav > li > a.btn {
  border: none;
  border-radius: 3px;
  position: relative;
  padding: 12px 30px;
  margin: 10px 1px;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0;
  will-change: box-shadow, transform;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.btn::-moz-focus-inner,
.navbar .navbar-nav > li > a.btn::-moz-focus-inner {
  border: 0;
}

.btn,
.btn.btn-default,
.navbar .navbar-nav > li > a.btn,
.navbar .navbar-nav > li > a.btn.btn-default {
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14),
    0 3px 1px -2px rgba(153, 153, 153, 0.2),
    0 1px 5px 0 rgba(153, 153, 153, 0.12);
}

.btn,
.btn:hover,
.btn:focus,
.btn:active,
.btn.active,
.btn:active:focus,
.btn:active:hover,
.btn.active:focus,
.btn.active:hover,
.open > .btn.dropdown-toggle,
.open > .btn.dropdown-toggle:focus,
.open > .btn.dropdown-toggle:hover,
.btn.btn-default,
.btn.btn-default:hover,
.btn.btn-default:focus,
.btn.btn-default:active,
.btn.btn-default.active,
.btn.btn-default:active:focus,
.btn.btn-default:active:hover,
.btn.btn-default.active:focus,
.btn.btn-default.active:hover,
.open > .btn.btn-default.dropdown-toggle,
.open > .btn.btn-default.dropdown-toggle:focus,
.open > .btn.btn-default.dropdown-toggle:hover,
.navbar .navbar-nav > li > a.btn,
.navbar .navbar-nav > li > a.btn:hover,
.navbar .navbar-nav > li > a.btn:focus,
.navbar .navbar-nav > li > a.btn:active,
.navbar .navbar-nav > li > a.btn.active,
.navbar .navbar-nav > li > a.btn:active:focus,
.navbar .navbar-nav > li > a.btn:active:hover,
.navbar .navbar-nav > li > a.btn.active:focus,
.navbar .navbar-nav > li > a.btn.active:hover,
.open > .navbar .navbar-nav > li > a.btn.dropdown-toggle,
.open > .navbar .navbar-nav > li > a.btn.dropdown-toggle:focus,
.open > .navbar .navbar-nav > li > a.btn.dropdown-toggle:hover,
.navbar .navbar-nav > li > a.btn.btn-default,
.navbar .navbar-nav > li > a.btn.btn-default:hover,
.navbar .navbar-nav > li > a.btn.btn-default:focus,
.navbar .navbar-nav > li > a.btn.btn-default:active,
.navbar .navbar-nav > li > a.btn.btn-default.active,
.navbar .navbar-nav > li > a.btn.btn-default:active:focus,
.navbar .navbar-nav > li > a.btn.btn-default:active:hover,
.navbar .navbar-nav > li > a.btn.btn-default.active:focus,
.navbar .navbar-nav > li > a.btn.btn-default.active:hover,
.open > .navbar .navbar-nav > li > a.btn.btn-default.dropdown-toggle,
.open > .navbar .navbar-nav > li > a.btn.btn-default.dropdown-toggle:focus,
.open > .navbar .navbar-nav > li > a.btn.btn-default.dropdown-toggle:hover {
  background-color: #999999;
  color: #ffffff;
}

.btn:focus,
.btn:active,
.btn:hover,
.btn.btn-default:focus,
.btn.btn-default:active,
.btn.btn-default:hover,
.navbar .navbar-nav > li > a.btn:focus,
.navbar .navbar-nav > li > a.btn:active,
.navbar .navbar-nav > li > a.btn:hover,
.navbar .navbar-nav > li > a.btn.btn-default:focus,
.navbar .navbar-nav > li > a.btn.btn-default:active,
.navbar .navbar-nav > li > a.btn.btn-default:hover {
  box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2);
}

.btn.disabled,
.btn.disabled:hover,
.btn.disabled:focus,
.btn.disabled.focus,
.btn.disabled:active,
.btn.disabled.active,
.btn:disabled,
.btn:disabled:hover,
.btn:disabled:focus,
.btn:disabled.focus,
.btn:disabled:active,
.btn:disabled.active,
.btn[disabled],
.btn[disabled]:hover,
.btn[disabled]:focus,
.btn[disabled].focus,
.btn[disabled]:active,
.btn[disabled].active,
fieldset[disabled] .btn,
fieldset[disabled] .btn:hover,
fieldset[disabled] .btn:focus,
fieldset[disabled] .btn.focus,
fieldset[disabled] .btn:active,
fieldset[disabled] .btn.active,
.btn.btn-default.disabled,
.btn.btn-default.disabled:hover,
.btn.btn-default.disabled:focus,
.btn.btn-default.disabled.focus,
.btn.btn-default.disabled:active,
.btn.btn-default.disabled.active,
.btn.btn-default:disabled,
.btn.btn-default:disabled:hover,
.btn.btn-default:disabled:focus,
.btn.btn-default:disabled.focus,
.btn.btn-default:disabled:active,
.btn.btn-default:disabled.active,
.btn.btn-default[disabled],
.btn.btn-default[disabled]:hover,
.btn.btn-default[disabled]:focus,
.btn.btn-default[disabled].focus,
.btn.btn-default[disabled]:active,
.btn.btn-default[disabled].active,
fieldset[disabled] .btn.btn-default,
fieldset[disabled] .btn.btn-default:hover,
fieldset[disabled] .btn.btn-default:focus,
fieldset[disabled] .btn.btn-default.focus,
fieldset[disabled] .btn.btn-default:active,
fieldset[disabled] .btn.btn-default.active,
.navbar .navbar-nav > li > a.btn.disabled,
.navbar .navbar-nav > li > a.btn.disabled:hover,
.navbar .navbar-nav > li > a.btn.disabled:focus,
.navbar .navbar-nav > li > a.btn.disabled.focus,
.navbar .navbar-nav > li > a.btn.disabled:active,
.navbar .navbar-nav > li > a.btn.disabled.active,
.navbar .navbar-nav > li > a.btn:disabled,
.navbar .navbar-nav > li > a.btn:disabled:hover,
.navbar .navbar-nav > li > a.btn:disabled:focus,
.navbar .navbar-nav > li > a.btn:disabled.focus,
.navbar .navbar-nav > li > a.btn:disabled:active,
.navbar .navbar-nav > li > a.btn:disabled.active,
.navbar .navbar-nav > li > a.btn[disabled],
.navbar .navbar-nav > li > a.btn[disabled]:hover,
.navbar .navbar-nav > li > a.btn[disabled]:focus,
.navbar .navbar-nav > li > a.btn[disabled].focus,
.navbar .navbar-nav > li > a.btn[disabled]:active,
.navbar .navbar-nav > li > a.btn[disabled].active,
fieldset[disabled] .navbar .navbar-nav > li > a.btn,
fieldset[disabled] .navbar .navbar-nav > li > a.btn:hover,
fieldset[disabled] .navbar .navbar-nav > li > a.btn:focus,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.focus,
fieldset[disabled] .navbar .navbar-nav > li > a.btn:active,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.active,
.navbar .navbar-nav > li > a.btn.btn-default.disabled,
.navbar .navbar-nav > li > a.btn.btn-default.disabled:hover,
.navbar .navbar-nav > li > a.btn.btn-default.disabled:focus,
.navbar .navbar-nav > li > a.btn.btn-default.disabled.focus,
.navbar .navbar-nav > li > a.btn.btn-default.disabled:active,
.navbar .navbar-nav > li > a.btn.btn-default.disabled.active,
.navbar .navbar-nav > li > a.btn.btn-default:disabled,
.navbar .navbar-nav > li > a.btn.btn-default:disabled:hover,
.navbar .navbar-nav > li > a.btn.btn-default:disabled:focus,
.navbar .navbar-nav > li > a.btn.btn-default:disabled.focus,
.navbar .navbar-nav > li > a.btn.btn-default:disabled:active,
.navbar .navbar-nav > li > a.btn.btn-default:disabled.active,
.navbar .navbar-nav > li > a.btn.btn-default[disabled],
.navbar .navbar-nav > li > a.btn.btn-default[disabled]:hover,
.navbar .navbar-nav > li > a.btn.btn-default[disabled]:focus,
.navbar .navbar-nav > li > a.btn.btn-default[disabled].focus,
.navbar .navbar-nav > li > a.btn.btn-default[disabled]:active,
.navbar .navbar-nav > li > a.btn.btn-default[disabled].active,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-default,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-default:hover,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-default:focus,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-default.focus,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-default:active,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-default.active {
  box-shadow: none !important;
}

.btn.btn-simple,
.btn.btn-default.btn-simple,
.navbar .navbar-nav > li > a.btn.btn-simple,
.navbar .navbar-nav > li > a.btn.btn-default.btn-simple {
  background-color: transparent;
  color: #999999;
  box-shadow: none;
}

.btn.btn-simple:hover,
.btn.btn-simple:focus,
.btn.btn-simple:active,
.btn.btn-default.btn-simple:hover,
.btn.btn-default.btn-simple:focus,
.btn.btn-default.btn-simple:active,
.navbar .navbar-nav > li > a.btn.btn-simple:hover,
.navbar .navbar-nav > li > a.btn.btn-simple:focus,
.navbar .navbar-nav > li > a.btn.btn-simple:active,
.navbar .navbar-nav > li > a.btn.btn-default.btn-simple:hover,
.navbar .navbar-nav > li > a.btn.btn-default.btn-simple:focus,
.navbar .navbar-nav > li > a.btn.btn-default.btn-simple:active {
  background-color: transparent;
  color: #999999;
}

.btn.btn-primary,
.navbar .navbar-nav > li > a.btn.btn-primary {
  box-shadow: 0 2px 2px 0 rgba(156, 39, 176, 0.14),
    0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12);
}

.btn.btn-primary,
.btn.btn-primary:hover,
.btn.btn-primary:focus,
.btn.btn-primary:active,
.btn.btn-primary.active,
.btn.btn-primary:active:focus,
.btn.btn-primary:active:hover,
.btn.btn-primary.active:focus,
.btn.btn-primary.active:hover,
.open > .btn.btn-primary.dropdown-toggle,
.open > .btn.btn-primary.dropdown-toggle:focus,
.open > .btn.btn-primary.dropdown-toggle:hover,
.navbar .navbar-nav > li > a.btn.btn-primary,
.navbar .navbar-nav > li > a.btn.btn-primary:hover,
.navbar .navbar-nav > li > a.btn.btn-primary:focus,
.navbar .navbar-nav > li > a.btn.btn-primary:active,
.navbar .navbar-nav > li > a.btn.btn-primary.active,
.navbar .navbar-nav > li > a.btn.btn-primary:active:focus,
.navbar .navbar-nav > li > a.btn.btn-primary:active:hover,
.navbar .navbar-nav > li > a.btn.btn-primary.active:focus,
.navbar .navbar-nav > li > a.btn.btn-primary.active:hover,
.open > .navbar .navbar-nav > li > a.btn.btn-primary.dropdown-toggle,
.open > .navbar .navbar-nav > li > a.btn.btn-primary.dropdown-toggle:focus,
.open > .navbar .navbar-nav > li > a.btn.btn-primary.dropdown-toggle:hover {
  background-color: #fff;
  color: #ffffff;
}

.btn.btn-primary:focus,
.btn.btn-primary:active,
.btn.btn-primary:hover,
.navbar .navbar-nav > li > a.btn.btn-primary:focus,
.navbar .navbar-nav > li > a.btn.btn-primary:active,
.navbar .navbar-nav > li > a.btn.btn-primary:hover {
  box-shadow: 0 14px 26px -12px rgba(156, 39, 176, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(156, 39, 176, 0.2);
}

.btn.btn-primary.disabled,
.btn.btn-primary.disabled:hover,
.btn.btn-primary.disabled:focus,
.btn.btn-primary.disabled.focus,
.btn.btn-primary.disabled:active,
.btn.btn-primary.disabled.active,
.btn.btn-primary:disabled,
.btn.btn-primary:disabled:hover,
.btn.btn-primary:disabled:focus,
.btn.btn-primary:disabled.focus,
.btn.btn-primary:disabled:active,
.btn.btn-primary:disabled.active,
.btn.btn-primary[disabled],
.btn.btn-primary[disabled]:hover,
.btn.btn-primary[disabled]:focus,
.btn.btn-primary[disabled].focus,
.btn.btn-primary[disabled]:active,
.btn.btn-primary[disabled].active,
fieldset[disabled] .btn.btn-primary,
fieldset[disabled] .btn.btn-primary:hover,
fieldset[disabled] .btn.btn-primary:focus,
fieldset[disabled] .btn.btn-primary.focus,
fieldset[disabled] .btn.btn-primary:active,
fieldset[disabled] .btn.btn-primary.active,
.navbar .navbar-nav > li > a.btn.btn-primary.disabled,
.navbar .navbar-nav > li > a.btn.btn-primary.disabled:hover,
.navbar .navbar-nav > li > a.btn.btn-primary.disabled:focus,
.navbar .navbar-nav > li > a.btn.btn-primary.disabled.focus,
.navbar .navbar-nav > li > a.btn.btn-primary.disabled:active,
.navbar .navbar-nav > li > a.btn.btn-primary.disabled.active,
.navbar .navbar-nav > li > a.btn.btn-primary:disabled,
.navbar .navbar-nav > li > a.btn.btn-primary:disabled:hover,
.navbar .navbar-nav > li > a.btn.btn-primary:disabled:focus,
.navbar .navbar-nav > li > a.btn.btn-primary:disabled.focus,
.navbar .navbar-nav > li > a.btn.btn-primary:disabled:active,
.navbar .navbar-nav > li > a.btn.btn-primary:disabled.active,
.navbar .navbar-nav > li > a.btn.btn-primary[disabled],
.navbar .navbar-nav > li > a.btn.btn-primary[disabled]:hover,
.navbar .navbar-nav > li > a.btn.btn-primary[disabled]:focus,
.navbar .navbar-nav > li > a.btn.btn-primary[disabled].focus,
.navbar .navbar-nav > li > a.btn.btn-primary[disabled]:active,
.navbar .navbar-nav > li > a.btn.btn-primary[disabled].active,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-primary,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-primary:hover,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-primary:focus,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-primary.focus,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-primary:active,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-primary.active {
  box-shadow: none !important;
}

.btn.btn-primary.btn-simple,
.navbar .navbar-nav > li > a.btn.btn-primary.btn-simple {
  background-color: transparent;
  color: #004000;
  box-shadow: none;
}

.btn.btn-primary.btn-simple:hover,
.btn.btn-primary.btn-simple:focus,
.btn.btn-primary.btn-simple:active,
.navbar .navbar-nav > li > a.btn.btn-primary.btn-simple:hover,
.navbar .navbar-nav > li > a.btn.btn-primary.btn-simple:focus,
.navbar .navbar-nav > li > a.btn.btn-primary.btn-simple:active {
  background-color: transparent;
  color: #004000;
}

.btn.btn-info,
.navbar .navbar-nav > li > a.btn.btn-info {
  box-shadow: 0 2px 2px 0 rgba(0, 188, 212, 0.14),
    0 3px 1px -2px rgba(0, 188, 212, 0.2), 0 1px 5px 0 rgba(0, 188, 212, 0.12);
}

.btn.btn-info,
.btn.btn-info:hover,
.btn.btn-info:focus,
.btn.btn-info:active,
.btn.btn-info.active,
.btn.btn-info:active:focus,
.btn.btn-info:active:hover,
.btn.btn-info.active:focus,
.btn.btn-info.active:hover,
.open > .btn.btn-info.dropdown-toggle,
.open > .btn.btn-info.dropdown-toggle:focus,
.open > .btn.btn-info.dropdown-toggle:hover,
.navbar .navbar-nav > li > a.btn.btn-info,
.navbar .navbar-nav > li > a.btn.btn-info:hover,
.navbar .navbar-nav > li > a.btn.btn-info:focus,
.navbar .navbar-nav > li > a.btn.btn-info:active,
.navbar .navbar-nav > li > a.btn.btn-info.active,
.navbar .navbar-nav > li > a.btn.btn-info:active:focus,
.navbar .navbar-nav > li > a.btn.btn-info:active:hover,
.navbar .navbar-nav > li > a.btn.btn-info.active:focus,
.navbar .navbar-nav > li > a.btn.btn-info.active:hover,
.open > .navbar .navbar-nav > li > a.btn.btn-info.dropdown-toggle,
.open > .navbar .navbar-nav > li > a.btn.btn-info.dropdown-toggle:focus,
.open > .navbar .navbar-nav > li > a.btn.btn-info.dropdown-toggle:hover {
  background-color: #00bcd4;
  color: #ffffff;
}

.btn.btn-info:focus,
.btn.btn-info:active,
.btn.btn-info:hover,
.navbar .navbar-nav > li > a.btn.btn-info:focus,
.navbar .navbar-nav > li > a.btn.btn-info:active,
.navbar .navbar-nav > li > a.btn.btn-info:hover {
  box-shadow: 0 14px 26px -12px rgba(0, 188, 212, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 188, 212, 0.2);
}

.btn.btn-info.disabled,
.btn.btn-info.disabled:hover,
.btn.btn-info.disabled:focus,
.btn.btn-info.disabled.focus,
.btn.btn-info.disabled:active,
.btn.btn-info.disabled.active,
.btn.btn-info:disabled,
.btn.btn-info:disabled:hover,
.btn.btn-info:disabled:focus,
.btn.btn-info:disabled.focus,
.btn.btn-info:disabled:active,
.btn.btn-info:disabled.active,
.btn.btn-info[disabled],
.btn.btn-info[disabled]:hover,
.btn.btn-info[disabled]:focus,
.btn.btn-info[disabled].focus,
.btn.btn-info[disabled]:active,
.btn.btn-info[disabled].active,
fieldset[disabled] .btn.btn-info,
fieldset[disabled] .btn.btn-info:hover,
fieldset[disabled] .btn.btn-info:focus,
fieldset[disabled] .btn.btn-info.focus,
fieldset[disabled] .btn.btn-info:active,
fieldset[disabled] .btn.btn-info.active,
.navbar .navbar-nav > li > a.btn.btn-info.disabled,
.navbar .navbar-nav > li > a.btn.btn-info.disabled:hover,
.navbar .navbar-nav > li > a.btn.btn-info.disabled:focus,
.navbar .navbar-nav > li > a.btn.btn-info.disabled.focus,
.navbar .navbar-nav > li > a.btn.btn-info.disabled:active,
.navbar .navbar-nav > li > a.btn.btn-info.disabled.active,
.navbar .navbar-nav > li > a.btn.btn-info:disabled,
.navbar .navbar-nav > li > a.btn.btn-info:disabled:hover,
.navbar .navbar-nav > li > a.btn.btn-info:disabled:focus,
.navbar .navbar-nav > li > a.btn.btn-info:disabled.focus,
.navbar .navbar-nav > li > a.btn.btn-info:disabled:active,
.navbar .navbar-nav > li > a.btn.btn-info:disabled.active,
.navbar .navbar-nav > li > a.btn.btn-info[disabled],
.navbar .navbar-nav > li > a.btn.btn-info[disabled]:hover,
.navbar .navbar-nav > li > a.btn.btn-info[disabled]:focus,
.navbar .navbar-nav > li > a.btn.btn-info[disabled].focus,
.navbar .navbar-nav > li > a.btn.btn-info[disabled]:active,
.navbar .navbar-nav > li > a.btn.btn-info[disabled].active,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-info,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-info:hover,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-info:focus,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-info.focus,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-info:active,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-info.active {
  box-shadow: none !important;
}

.btn.btn-info.btn-simple,
.navbar .navbar-nav > li > a.btn.btn-info.btn-simple {
  background-color: transparent;
  color: #00bcd4;
  box-shadow: none;
}

.btn.btn-info.btn-simple:hover,
.btn.btn-info.btn-simple:focus,
.btn.btn-info.btn-simple:active,
.navbar .navbar-nav > li > a.btn.btn-info.btn-simple:hover,
.navbar .navbar-nav > li > a.btn.btn-info.btn-simple:focus,
.navbar .navbar-nav > li > a.btn.btn-info.btn-simple:active {
  background-color: transparent;
  color: #00bcd4;
}

.btn.btn-success,
.navbar .navbar-nav > li > a.btn.btn-success {
  box-shadow: 0 2px 2px 0 rgba(76, 175, 80, 0.14),
    0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12);
}

.btn.btn-success,
.btn.btn-success:hover,
.btn.btn-success:focus,
.btn.btn-success:active,
.btn.btn-success.active,
.btn.btn-success:active:focus,
.btn.btn-success:active:hover,
.btn.btn-success.active:focus,
.btn.btn-success.active:hover,
.open > .btn.btn-success.dropdown-toggle,
.open > .btn.btn-success.dropdown-toggle:focus,
.open > .btn.btn-success.dropdown-toggle:hover,
.navbar .navbar-nav > li > a.btn.btn-success,
.navbar .navbar-nav > li > a.btn.btn-success:hover,
.navbar .navbar-nav > li > a.btn.btn-success:focus,
.navbar .navbar-nav > li > a.btn.btn-success:active,
.navbar .navbar-nav > li > a.btn.btn-success.active,
.navbar .navbar-nav > li > a.btn.btn-success:active:focus,
.navbar .navbar-nav > li > a.btn.btn-success:active:hover,
.navbar .navbar-nav > li > a.btn.btn-success.active:focus,
.navbar .navbar-nav > li > a.btn.btn-success.active:hover,
.open > .navbar .navbar-nav > li > a.btn.btn-success.dropdown-toggle,
.open > .navbar .navbar-nav > li > a.btn.btn-success.dropdown-toggle:focus,
.open > .navbar .navbar-nav > li > a.btn.btn-success.dropdown-toggle:hover {
  background-color: #4caf50;
  color: #ffffff;
}

.btn.btn-success:focus,
.btn.btn-success:active,
.btn.btn-success:hover,
.navbar .navbar-nav > li > a.btn.btn-success:focus,
.navbar .navbar-nav > li > a.btn.btn-success:active,
.navbar .navbar-nav > li > a.btn.btn-success:hover {
  box-shadow: 0 14px 26px -12px rgba(76, 175, 80, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(76, 175, 80, 0.2);
}

.btn.btn-success.disabled,
.btn.btn-success.disabled:hover,
.btn.btn-success.disabled:focus,
.btn.btn-success.disabled.focus,
.btn.btn-success.disabled:active,
.btn.btn-success.disabled.active,
.btn.btn-success:disabled,
.btn.btn-success:disabled:hover,
.btn.btn-success:disabled:focus,
.btn.btn-success:disabled.focus,
.btn.btn-success:disabled:active,
.btn.btn-success:disabled.active,
.btn.btn-success[disabled],
.btn.btn-success[disabled]:hover,
.btn.btn-success[disabled]:focus,
.btn.btn-success[disabled].focus,
.btn.btn-success[disabled]:active,
.btn.btn-success[disabled].active,
fieldset[disabled] .btn.btn-success,
fieldset[disabled] .btn.btn-success:hover,
fieldset[disabled] .btn.btn-success:focus,
fieldset[disabled] .btn.btn-success.focus,
fieldset[disabled] .btn.btn-success:active,
fieldset[disabled] .btn.btn-success.active,
.navbar .navbar-nav > li > a.btn.btn-success.disabled,
.navbar .navbar-nav > li > a.btn.btn-success.disabled:hover,
.navbar .navbar-nav > li > a.btn.btn-success.disabled:focus,
.navbar .navbar-nav > li > a.btn.btn-success.disabled.focus,
.navbar .navbar-nav > li > a.btn.btn-success.disabled:active,
.navbar .navbar-nav > li > a.btn.btn-success.disabled.active,
.navbar .navbar-nav > li > a.btn.btn-success:disabled,
.navbar .navbar-nav > li > a.btn.btn-success:disabled:hover,
.navbar .navbar-nav > li > a.btn.btn-success:disabled:focus,
.navbar .navbar-nav > li > a.btn.btn-success:disabled.focus,
.navbar .navbar-nav > li > a.btn.btn-success:disabled:active,
.navbar .navbar-nav > li > a.btn.btn-success:disabled.active,
.navbar .navbar-nav > li > a.btn.btn-success[disabled],
.navbar .navbar-nav > li > a.btn.btn-success[disabled]:hover,
.navbar .navbar-nav > li > a.btn.btn-success[disabled]:focus,
.navbar .navbar-nav > li > a.btn.btn-success[disabled].focus,
.navbar .navbar-nav > li > a.btn.btn-success[disabled]:active,
.navbar .navbar-nav > li > a.btn.btn-success[disabled].active,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-success,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-success:hover,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-success:focus,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-success.focus,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-success:active,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-success.active {
  box-shadow: none !important;
}

.btn.btn-success.btn-simple,
.navbar .navbar-nav > li > a.btn.btn-success.btn-simple {
  background-color: transparent;
  color: #4caf50;
  box-shadow: none;
}

.btn.btn-success.btn-simple:hover,
.btn.btn-success.btn-simple:focus,
.btn.btn-success.btn-simple:active,
.navbar .navbar-nav > li > a.btn.btn-success.btn-simple:hover,
.navbar .navbar-nav > li > a.btn.btn-success.btn-simple:focus,
.navbar .navbar-nav > li > a.btn.btn-success.btn-simple:active {
  background-color: transparent;
  color: #4caf50;
}

.btn.btn-warning,
.navbar .navbar-nav > li > a.btn.btn-warning {
  box-shadow: 0 2px 2px 0 rgba(255, 152, 0, 0.14),
    0 3px 1px -2px rgba(255, 152, 0, 0.2), 0 1px 5px 0 rgba(255, 152, 0, 0.12);
}

.btn.btn-warning,
.btn.btn-warning:hover,
.btn.btn-warning:focus,
.btn.btn-warning:active,
.btn.btn-warning.active,
.btn.btn-warning:active:focus,
.btn.btn-warning:active:hover,
.btn.btn-warning.active:focus,
.btn.btn-warning.active:hover,
.open > .btn.btn-warning.dropdown-toggle,
.open > .btn.btn-warning.dropdown-toggle:focus,
.open > .btn.btn-warning.dropdown-toggle:hover,
.navbar .navbar-nav > li > a.btn.btn-warning,
.navbar .navbar-nav > li > a.btn.btn-warning:hover,
.navbar .navbar-nav > li > a.btn.btn-warning:focus,
.navbar .navbar-nav > li > a.btn.btn-warning:active,
.navbar .navbar-nav > li > a.btn.btn-warning.active,
.navbar .navbar-nav > li > a.btn.btn-warning:active:focus,
.navbar .navbar-nav > li > a.btn.btn-warning:active:hover,
.navbar .navbar-nav > li > a.btn.btn-warning.active:focus,
.navbar .navbar-nav > li > a.btn.btn-warning.active:hover,
.open > .navbar .navbar-nav > li > a.btn.btn-warning.dropdown-toggle,
.open > .navbar .navbar-nav > li > a.btn.btn-warning.dropdown-toggle:focus,
.open > .navbar .navbar-nav > li > a.btn.btn-warning.dropdown-toggle:hover {
  background-color: #ff9800;
  color: #ffffff;
}

.btn.btn-warning:focus,
.btn.btn-warning:active,
.btn.btn-warning:hover,
.navbar .navbar-nav > li > a.btn.btn-warning:focus,
.navbar .navbar-nav > li > a.btn.btn-warning:active,
.navbar .navbar-nav > li > a.btn.btn-warning:hover {
  box-shadow: 0 14px 26px -12px rgba(255, 152, 0, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(255, 152, 0, 0.2);
}

.btn.btn-warning.disabled,
.btn.btn-warning.disabled:hover,
.btn.btn-warning.disabled:focus,
.btn.btn-warning.disabled.focus,
.btn.btn-warning.disabled:active,
.btn.btn-warning.disabled.active,
.btn.btn-warning:disabled,
.btn.btn-warning:disabled:hover,
.btn.btn-warning:disabled:focus,
.btn.btn-warning:disabled.focus,
.btn.btn-warning:disabled:active,
.btn.btn-warning:disabled.active,
.btn.btn-warning[disabled],
.btn.btn-warning[disabled]:hover,
.btn.btn-warning[disabled]:focus,
.btn.btn-warning[disabled].focus,
.btn.btn-warning[disabled]:active,
.btn.btn-warning[disabled].active,
fieldset[disabled] .btn.btn-warning,
fieldset[disabled] .btn.btn-warning:hover,
fieldset[disabled] .btn.btn-warning:focus,
fieldset[disabled] .btn.btn-warning.focus,
fieldset[disabled] .btn.btn-warning:active,
fieldset[disabled] .btn.btn-warning.active,
.navbar .navbar-nav > li > a.btn.btn-warning.disabled,
.navbar .navbar-nav > li > a.btn.btn-warning.disabled:hover,
.navbar .navbar-nav > li > a.btn.btn-warning.disabled:focus,
.navbar .navbar-nav > li > a.btn.btn-warning.disabled.focus,
.navbar .navbar-nav > li > a.btn.btn-warning.disabled:active,
.navbar .navbar-nav > li > a.btn.btn-warning.disabled.active,
.navbar .navbar-nav > li > a.btn.btn-warning:disabled,
.navbar .navbar-nav > li > a.btn.btn-warning:disabled:hover,
.navbar .navbar-nav > li > a.btn.btn-warning:disabled:focus,
.navbar .navbar-nav > li > a.btn.btn-warning:disabled.focus,
.navbar .navbar-nav > li > a.btn.btn-warning:disabled:active,
.navbar .navbar-nav > li > a.btn.btn-warning:disabled.active,
.navbar .navbar-nav > li > a.btn.btn-warning[disabled],
.navbar .navbar-nav > li > a.btn.btn-warning[disabled]:hover,
.navbar .navbar-nav > li > a.btn.btn-warning[disabled]:focus,
.navbar .navbar-nav > li > a.btn.btn-warning[disabled].focus,
.navbar .navbar-nav > li > a.btn.btn-warning[disabled]:active,
.navbar .navbar-nav > li > a.btn.btn-warning[disabled].active,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-warning,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-warning:hover,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-warning:focus,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-warning.focus,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-warning:active,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-warning.active {
  box-shadow: none !important;
}

.btn.btn-warning.btn-simple,
.navbar .navbar-nav > li > a.btn.btn-warning.btn-simple {
  background-color: transparent;
  color: #ff9800;
  box-shadow: none;
}

.btn.btn-warning.btn-simple:hover,
.btn.btn-warning.btn-simple:focus,
.btn.btn-warning.btn-simple:active,
.navbar .navbar-nav > li > a.btn.btn-warning.btn-simple:hover,
.navbar .navbar-nav > li > a.btn.btn-warning.btn-simple:focus,
.navbar .navbar-nav > li > a.btn.btn-warning.btn-simple:active {
  background-color: transparent;
  color: #ff9800;
}

.btn.btn-danger,
.navbar .navbar-nav > li > a.btn.btn-danger {
  box-shadow: 0 2px 2px 0 rgba(244, 67, 54, 0.14),
    0 3px 1px -2px rgba(244, 67, 54, 0.2), 0 1px 5px 0 rgba(244, 67, 54, 0.12);
}

.btn.btn-danger,
.btn.btn-danger:hover,
.btn.btn-danger:focus,
.btn.btn-danger:active,
.btn.btn-danger.active,
.btn.btn-danger:active:focus,
.btn.btn-danger:active:hover,
.btn.btn-danger.active:focus,
.btn.btn-danger.active:hover,
.open > .btn.btn-danger.dropdown-toggle,
.open > .btn.btn-danger.dropdown-toggle:focus,
.open > .btn.btn-danger.dropdown-toggle:hover,
.navbar .navbar-nav > li > a.btn.btn-danger,
.navbar .navbar-nav > li > a.btn.btn-danger:hover,
.navbar .navbar-nav > li > a.btn.btn-danger:focus,
.navbar .navbar-nav > li > a.btn.btn-danger:active,
.navbar .navbar-nav > li > a.btn.btn-danger.active,
.navbar .navbar-nav > li > a.btn.btn-danger:active:focus,
.navbar .navbar-nav > li > a.btn.btn-danger:active:hover,
.navbar .navbar-nav > li > a.btn.btn-danger.active:focus,
.navbar .navbar-nav > li > a.btn.btn-danger.active:hover,
.open > .navbar .navbar-nav > li > a.btn.btn-danger.dropdown-toggle,
.open > .navbar .navbar-nav > li > a.btn.btn-danger.dropdown-toggle:focus,
.open > .navbar .navbar-nav > li > a.btn.btn-danger.dropdown-toggle:hover {
  background-color: #f44336;
  color: #ffffff;
}

.btn.btn-danger:focus,
.btn.btn-danger:active,
.btn.btn-danger:hover,
.navbar .navbar-nav > li > a.btn.btn-danger:focus,
.navbar .navbar-nav > li > a.btn.btn-danger:active,
.navbar .navbar-nav > li > a.btn.btn-danger:hover {
  box-shadow: 0 14px 26px -12px rgba(244, 67, 54, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(244, 67, 54, 0.2);
}

.btn.btn-danger.disabled,
.btn.btn-danger.disabled:hover,
.btn.btn-danger.disabled:focus,
.btn.btn-danger.disabled.focus,
.btn.btn-danger.disabled:active,
.btn.btn-danger.disabled.active,
.btn.btn-danger:disabled,
.btn.btn-danger:disabled:hover,
.btn.btn-danger:disabled:focus,
.btn.btn-danger:disabled.focus,
.btn.btn-danger:disabled:active,
.btn.btn-danger:disabled.active,
.btn.btn-danger[disabled],
.btn.btn-danger[disabled]:hover,
.btn.btn-danger[disabled]:focus,
.btn.btn-danger[disabled].focus,
.btn.btn-danger[disabled]:active,
.btn.btn-danger[disabled].active,
fieldset[disabled] .btn.btn-danger,
fieldset[disabled] .btn.btn-danger:hover,
fieldset[disabled] .btn.btn-danger:focus,
fieldset[disabled] .btn.btn-danger.focus,
fieldset[disabled] .btn.btn-danger:active,
fieldset[disabled] .btn.btn-danger.active,
.navbar .navbar-nav > li > a.btn.btn-danger.disabled,
.navbar .navbar-nav > li > a.btn.btn-danger.disabled:hover,
.navbar .navbar-nav > li > a.btn.btn-danger.disabled:focus,
.navbar .navbar-nav > li > a.btn.btn-danger.disabled.focus,
.navbar .navbar-nav > li > a.btn.btn-danger.disabled:active,
.navbar .navbar-nav > li > a.btn.btn-danger.disabled.active,
.navbar .navbar-nav > li > a.btn.btn-danger:disabled,
.navbar .navbar-nav > li > a.btn.btn-danger:disabled:hover,
.navbar .navbar-nav > li > a.btn.btn-danger:disabled:focus,
.navbar .navbar-nav > li > a.btn.btn-danger:disabled.focus,
.navbar .navbar-nav > li > a.btn.btn-danger:disabled:active,
.navbar .navbar-nav > li > a.btn.btn-danger:disabled.active,
.navbar .navbar-nav > li > a.btn.btn-danger[disabled],
.navbar .navbar-nav > li > a.btn.btn-danger[disabled]:hover,
.navbar .navbar-nav > li > a.btn.btn-danger[disabled]:focus,
.navbar .navbar-nav > li > a.btn.btn-danger[disabled].focus,
.navbar .navbar-nav > li > a.btn.btn-danger[disabled]:active,
.navbar .navbar-nav > li > a.btn.btn-danger[disabled].active,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-danger,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-danger:hover,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-danger:focus,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-danger.focus,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-danger:active,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-danger.active {
  box-shadow: none !important;
}

.btn.btn-danger.btn-simple,
.navbar .navbar-nav > li > a.btn.btn-danger.btn-simple {
  background-color: transparent;
  color: #f44336;
  box-shadow: none;
}

.btn.btn-danger.btn-simple:hover,
.btn.btn-danger.btn-simple:focus,
.btn.btn-danger.btn-simple:active,
.navbar .navbar-nav > li > a.btn.btn-danger.btn-simple:hover,
.navbar .navbar-nav > li > a.btn.btn-danger.btn-simple:focus,
.navbar .navbar-nav > li > a.btn.btn-danger.btn-simple:active {
  background-color: transparent;
  color: #f44336;
}

.btn.btn-rose,
.navbar .navbar-nav > li > a.btn.btn-rose {
  box-shadow: 0 2px 2px 0 rgba(233, 30, 99, 0.14),
    0 3px 1px -2px rgba(233, 30, 99, 0.2), 0 1px 5px 0 rgba(233, 30, 99, 0.12);
}

.btn.btn-rose,
.btn.btn-rose:hover,
.btn.btn-rose:focus,
.btn.btn-rose:active,
.btn.btn-rose.active,
.btn.btn-rose:active:focus,
.btn.btn-rose:active:hover,
.btn.btn-rose.active:focus,
.btn.btn-rose.active:hover,
.open > .btn.btn-rose.dropdown-toggle,
.open > .btn.btn-rose.dropdown-toggle:focus,
.open > .btn.btn-rose.dropdown-toggle:hover,
.navbar .navbar-nav > li > a.btn.btn-rose,
.navbar .navbar-nav > li > a.btn.btn-rose:hover,
.navbar .navbar-nav > li > a.btn.btn-rose:focus,
.navbar .navbar-nav > li > a.btn.btn-rose:active,
.navbar .navbar-nav > li > a.btn.btn-rose.active,
.navbar .navbar-nav > li > a.btn.btn-rose:active:focus,
.navbar .navbar-nav > li > a.btn.btn-rose:active:hover,
.navbar .navbar-nav > li > a.btn.btn-rose.active:focus,
.navbar .navbar-nav > li > a.btn.btn-rose.active:hover,
.open > .navbar .navbar-nav > li > a.btn.btn-rose.dropdown-toggle,
.open > .navbar .navbar-nav > li > a.btn.btn-rose.dropdown-toggle:focus,
.open > .navbar .navbar-nav > li > a.btn.btn-rose.dropdown-toggle:hover {
  background-color: #e91e63;
  color: #ffffff;
}

.btn.btn-rose:focus,
.btn.btn-rose:active,
.btn.btn-rose:hover,
.navbar .navbar-nav > li > a.btn.btn-rose:focus,
.navbar .navbar-nav > li > a.btn.btn-rose:active,
.navbar .navbar-nav > li > a.btn.btn-rose:hover {
  box-shadow: 0 14px 26px -12px rgba(233, 30, 99, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(233, 30, 99, 0.2);
}

.btn.btn-rose.disabled,
.btn.btn-rose.disabled:hover,
.btn.btn-rose.disabled:focus,
.btn.btn-rose.disabled.focus,
.btn.btn-rose.disabled:active,
.btn.btn-rose.disabled.active,
.btn.btn-rose:disabled,
.btn.btn-rose:disabled:hover,
.btn.btn-rose:disabled:focus,
.btn.btn-rose:disabled.focus,
.btn.btn-rose:disabled:active,
.btn.btn-rose:disabled.active,
.btn.btn-rose[disabled],
.btn.btn-rose[disabled]:hover,
.btn.btn-rose[disabled]:focus,
.btn.btn-rose[disabled].focus,
.btn.btn-rose[disabled]:active,
.btn.btn-rose[disabled].active,
fieldset[disabled] .btn.btn-rose,
fieldset[disabled] .btn.btn-rose:hover,
fieldset[disabled] .btn.btn-rose:focus,
fieldset[disabled] .btn.btn-rose.focus,
fieldset[disabled] .btn.btn-rose:active,
fieldset[disabled] .btn.btn-rose.active,
.navbar .navbar-nav > li > a.btn.btn-rose.disabled,
.navbar .navbar-nav > li > a.btn.btn-rose.disabled:hover,
.navbar .navbar-nav > li > a.btn.btn-rose.disabled:focus,
.navbar .navbar-nav > li > a.btn.btn-rose.disabled.focus,
.navbar .navbar-nav > li > a.btn.btn-rose.disabled:active,
.navbar .navbar-nav > li > a.btn.btn-rose.disabled.active,
.navbar .navbar-nav > li > a.btn.btn-rose:disabled,
.navbar .navbar-nav > li > a.btn.btn-rose:disabled:hover,
.navbar .navbar-nav > li > a.btn.btn-rose:disabled:focus,
.navbar .navbar-nav > li > a.btn.btn-rose:disabled.focus,
.navbar .navbar-nav > li > a.btn.btn-rose:disabled:active,
.navbar .navbar-nav > li > a.btn.btn-rose:disabled.active,
.navbar .navbar-nav > li > a.btn.btn-rose[disabled],
.navbar .navbar-nav > li > a.btn.btn-rose[disabled]:hover,
.navbar .navbar-nav > li > a.btn.btn-rose[disabled]:focus,
.navbar .navbar-nav > li > a.btn.btn-rose[disabled].focus,
.navbar .navbar-nav > li > a.btn.btn-rose[disabled]:active,
.navbar .navbar-nav > li > a.btn.btn-rose[disabled].active,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-rose,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-rose:hover,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-rose:focus,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-rose.focus,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-rose:active,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.btn-rose.active {
  box-shadow: none !important;
}

.btn.btn-rose.btn-simple,
.navbar .navbar-nav > li > a.btn.btn-rose.btn-simple {
  background-color: transparent;
  color: #e91e63;
  box-shadow: none;
}

.btn.btn-rose.btn-simple:hover,
.btn.btn-rose.btn-simple:focus,
.btn.btn-rose.btn-simple:active,
.navbar .navbar-nav > li > a.btn.btn-rose.btn-simple:hover,
.navbar .navbar-nav > li > a.btn.btn-rose.btn-simple:focus,
.navbar .navbar-nav > li > a.btn.btn-rose.btn-simple:active {
  background-color: transparent;
  color: #e91e63;
}

.btn.btn-white,
.btn.btn-white:focus,
.btn.btn-white:hover,
.navbar .navbar-nav > li > a.btn.btn-white,
.navbar .navbar-nav > li > a.btn.btn-white:focus,
.navbar .navbar-nav > li > a.btn.btn-white:hover {
  background-color: #ffffff;
  color: #999999;
}

.btn.btn-white.btn-simple,
.navbar .navbar-nav > li > a.btn.btn-white.btn-simple {
  color: #ffffff;
  background: transparent;
  box-shadow: none;
}

.btn.btn-facebook,
.navbar .navbar-nav > li > a.btn.btn-facebook {
  background-color: #3b5998;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(59, 89, 152, 0.14),
    0 3px 1px -2px rgba(59, 89, 152, 0.2), 0 1px 5px 0 rgba(59, 89, 152, 0.12);
}

.btn.btn-facebook:focus,
.btn.btn-facebook:active,
.btn.btn-facebook:hover,
.navbar .navbar-nav > li > a.btn.btn-facebook:focus,
.navbar .navbar-nav > li > a.btn.btn-facebook:active,
.navbar .navbar-nav > li > a.btn.btn-facebook:hover {
  background-color: #3b5998;
  color: #fff;
  box-shadow: 0 14px 26px -12px rgba(59, 89, 152, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(59, 89, 152, 0.2);
}

.btn.btn-facebook.btn-simple,
.navbar .navbar-nav > li > a.btn.btn-facebook.btn-simple {
  color: #3b5998;
  background-color: transparent;
  box-shadow: none;
}

.btn.btn-facebook.btn-white,
.navbar .navbar-nav > li > a.btn.btn-facebook.btn-white {
  color: #3b5998;
  background-color: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14),
    0 3px 1px -2px rgba(153, 153, 153, 0.2),
    0 1px 5px 0 rgba(153, 153, 153, 0.12);
}

.btn.btn-twitter,
.navbar .navbar-nav > li > a.btn.btn-twitter {
  background-color: #55acee;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(85, 172, 238, 0.14),
    0 3px 1px -2px rgba(85, 172, 238, 0.2), 0 1px 5px 0 rgba(85, 172, 238, 0.12);
}

.btn.btn-twitter:focus,
.btn.btn-twitter:active,
.btn.btn-twitter:hover,
.navbar .navbar-nav > li > a.btn.btn-twitter:focus,
.navbar .navbar-nav > li > a.btn.btn-twitter:active,
.navbar .navbar-nav > li > a.btn.btn-twitter:hover {
  background-color: #55acee;
  color: #fff;
  box-shadow: 0 14px 26px -12px rgba(85, 172, 238, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 172, 238, 0.2);
}

.btn.btn-twitter.btn-simple,
.navbar .navbar-nav > li > a.btn.btn-twitter.btn-simple {
  color: #55acee;
  background-color: transparent;
  box-shadow: none;
}

.btn.btn-twitter.btn-white,
.navbar .navbar-nav > li > a.btn.btn-twitter.btn-white {
  color: #55acee;
  background-color: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14),
    0 3px 1px -2px rgba(153, 153, 153, 0.2),
    0 1px 5px 0 rgba(153, 153, 153, 0.12);
}

.btn.btn-pinterest,
.navbar .navbar-nav > li > a.btn.btn-pinterest {
  background-color: #cc2127;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(204, 33, 39, 0.14),
    0 3px 1px -2px rgba(204, 33, 39, 0.2), 0 1px 5px 0 rgba(204, 33, 39, 0.12);
}

.btn.btn-pinterest:focus,
.btn.btn-pinterest:active,
.btn.btn-pinterest:hover,
.navbar .navbar-nav > li > a.btn.btn-pinterest:focus,
.navbar .navbar-nav > li > a.btn.btn-pinterest:active,
.navbar .navbar-nav > li > a.btn.btn-pinterest:hover {
  background-color: #cc2127;
  color: #fff;
  box-shadow: 0 14px 26px -12px rgba(204, 33, 39, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(204, 33, 39, 0.2);
}

.btn.btn-pinterest.btn-simple,
.navbar .navbar-nav > li > a.btn.btn-pinterest.btn-simple {
  color: #cc2127;
  background-color: transparent;
  box-shadow: none;
}

.btn.btn-pinterest.btn-white,
.navbar .navbar-nav > li > a.btn.btn-pinterest.btn-white {
  color: #cc2127;
  background-color: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14),
    0 3px 1px -2px rgba(153, 153, 153, 0.2),
    0 1px 5px 0 rgba(153, 153, 153, 0.12);
}

.btn.btn-google,
.navbar .navbar-nav > li > a.btn.btn-google {
  background-color: #dd4b39;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(221, 75, 57, 0.14),
    0 3px 1px -2px rgba(221, 75, 57, 0.2), 0 1px 5px 0 rgba(221, 75, 57, 0.12);
}

.btn.btn-google:focus,
.btn.btn-google:active,
.btn.btn-google:hover,
.navbar .navbar-nav > li > a.btn.btn-google:focus,
.navbar .navbar-nav > li > a.btn.btn-google:active,
.navbar .navbar-nav > li > a.btn.btn-google:hover {
  background-color: #dd4b39;
  color: #fff;
  box-shadow: 0 14px 26px -12px rgba(221, 75, 57, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(221, 75, 57, 0.2);
}

.btn.btn-google.btn-simple,
.navbar .navbar-nav > li > a.btn.btn-google.btn-simple {
  color: #dd4b39;
  background-color: transparent;
  box-shadow: none;
}

.btn.btn-google.btn-white,
.navbar .navbar-nav > li > a.btn.btn-google.btn-white {
  color: #dd4b39;
  background-color: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14),
    0 3px 1px -2px rgba(153, 153, 153, 0.2),
    0 1px 5px 0 rgba(153, 153, 153, 0.12);
}

.btn.btn-linkedin,
.navbar .navbar-nav > li > a.btn.btn-linkedin {
  background-color: #0976b4;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(9, 118, 180, 0.14),
    0 3px 1px -2px rgba(9, 118, 180, 0.2), 0 1px 5px 0 rgba(9, 118, 180, 0.12);
}

.btn.btn-linkedin:focus,
.btn.btn-linkedin:active,
.btn.btn-linkedin:hover,
.navbar .navbar-nav > li > a.btn.btn-linkedin:focus,
.navbar .navbar-nav > li > a.btn.btn-linkedin:active,
.navbar .navbar-nav > li > a.btn.btn-linkedin:hover {
  background-color: #0976b4;
  color: #fff;
  box-shadow: 0 14px 26px -12px rgba(9, 118, 180, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(9, 118, 180, 0.2);
}

.btn.btn-linkedin.btn-simple,
.navbar .navbar-nav > li > a.btn.btn-linkedin.btn-simple {
  color: #0976b4;
  background-color: transparent;
  box-shadow: none;
}

.btn.btn-linkedin.btn-white,
.navbar .navbar-nav > li > a.btn.btn-linkedin.btn-white {
  color: #0976b4;
  background-color: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14),
    0 3px 1px -2px rgba(153, 153, 153, 0.2),
    0 1px 5px 0 rgba(153, 153, 153, 0.12);
}

.btn.btn-dribbble,
.navbar .navbar-nav > li > a.btn.btn-dribbble {
  background-color: #ea4c89;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(234, 76, 137, 0.14),
    0 3px 1px -2px rgba(234, 76, 137, 0.2), 0 1px 5px 0 rgba(234, 76, 137, 0.12);
}

.btn.btn-dribbble:focus,
.btn.btn-dribbble:active,
.btn.btn-dribbble:hover,
.navbar .navbar-nav > li > a.btn.btn-dribbble:focus,
.navbar .navbar-nav > li > a.btn.btn-dribbble:active,
.navbar .navbar-nav > li > a.btn.btn-dribbble:hover {
  background-color: #ea4c89;
  color: #fff;
  box-shadow: 0 14px 26px -12px rgba(234, 76, 137, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(234, 76, 137, 0.2);
}

.btn.btn-dribbble.btn-simple,
.navbar .navbar-nav > li > a.btn.btn-dribbble.btn-simple {
  color: #ea4c89;
  background-color: transparent;
  box-shadow: none;
}

.btn.btn-dribbble.btn-white,
.navbar .navbar-nav > li > a.btn.btn-dribbble.btn-white {
  color: #ea4c89;
  background-color: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14),
    0 3px 1px -2px rgba(153, 153, 153, 0.2),
    0 1px 5px 0 rgba(153, 153, 153, 0.12);
}

.btn.btn-github,
.navbar .navbar-nav > li > a.btn.btn-github {
  background-color: #333333;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(51, 51, 51, 0.14),
    0 3px 1px -2px rgba(51, 51, 51, 0.2), 0 1px 5px 0 rgba(51, 51, 51, 0.12);
}

.btn.btn-github:focus,
.btn.btn-github:active,
.btn.btn-github:hover,
.navbar .navbar-nav > li > a.btn.btn-github:focus,
.navbar .navbar-nav > li > a.btn.btn-github:active,
.navbar .navbar-nav > li > a.btn.btn-github:hover {
  background-color: #333333;
  color: #fff;
  box-shadow: 0 14px 26px -12px rgba(51, 51, 51, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(51, 51, 51, 0.2);
}

.btn.btn-github.btn-simple,
.navbar .navbar-nav > li > a.btn.btn-github.btn-simple {
  color: #333333;
  background-color: transparent;
  box-shadow: none;
}

.btn.btn-github.btn-white,
.navbar .navbar-nav > li > a.btn.btn-github.btn-white {
  color: #333333;
  background-color: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14),
    0 3px 1px -2px rgba(153, 153, 153, 0.2),
    0 1px 5px 0 rgba(153, 153, 153, 0.12);
}

.btn.btn-youtube,
.navbar .navbar-nav > li > a.btn.btn-youtube {
  background-color: #e52d27;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(229, 45, 39, 0.14),
    0 3px 1px -2px rgba(229, 45, 39, 0.2), 0 1px 5px 0 rgba(229, 45, 39, 0.12);
}

.btn.btn-youtube:focus,
.btn.btn-youtube:active,
.btn.btn-youtube:hover,
.navbar .navbar-nav > li > a.btn.btn-youtube:focus,
.navbar .navbar-nav > li > a.btn.btn-youtube:active,
.navbar .navbar-nav > li > a.btn.btn-youtube:hover {
  background-color: #e52d27;
  color: #fff;
  box-shadow: 0 14px 26px -12px rgba(229, 45, 39, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(229, 45, 39, 0.2);
}

.btn.btn-youtube.btn-simple,
.navbar .navbar-nav > li > a.btn.btn-youtube.btn-simple {
  color: #e52d27;
  background-color: transparent;
  box-shadow: none;
}

.btn.btn-youtube.btn-white,
.navbar .navbar-nav > li > a.btn.btn-youtube.btn-white {
  color: #e52d27;
  background-color: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14),
    0 3px 1px -2px rgba(153, 153, 153, 0.2),
    0 1px 5px 0 rgba(153, 153, 153, 0.12);
}

.btn.btn-instagram,
.navbar .navbar-nav > li > a.btn.btn-instagram {
  background-color: #125688;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(18, 86, 136, 0.14),
    0 3px 1px -2px rgba(18, 86, 136, 0.2), 0 1px 5px 0 rgba(18, 86, 136, 0.12);
}

.btn.btn-instagram:focus,
.btn.btn-instagram:active,
.btn.btn-instagram:hover,
.navbar .navbar-nav > li > a.btn.btn-instagram:focus,
.navbar .navbar-nav > li > a.btn.btn-instagram:active,
.navbar .navbar-nav > li > a.btn.btn-instagram:hover {
  background-color: #125688;
  color: #fff;
  box-shadow: 0 14px 26px -12px rgba(18, 86, 136, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(18, 86, 136, 0.2);
}

.btn.btn-instagram.btn-simple,
.navbar .navbar-nav > li > a.btn.btn-instagram.btn-simple {
  color: #125688;
  background-color: transparent;
  box-shadow: none;
}

.btn.btn-instagram.btn-white,
.navbar .navbar-nav > li > a.btn.btn-instagram.btn-white {
  color: #125688;
  background-color: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14),
    0 3px 1px -2px rgba(153, 153, 153, 0.2),
    0 1px 5px 0 rgba(153, 153, 153, 0.12);
}

.btn.btn-reddit,
.navbar .navbar-nav > li > a.btn.btn-reddit {
  background-color: #ff4500;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(255, 69, 0, 0.14),
    0 3px 1px -2px rgba(255, 69, 0, 0.2), 0 1px 5px 0 rgba(255, 69, 0, 0.12);
}

.btn.btn-reddit:focus,
.btn.btn-reddit:active,
.btn.btn-reddit:hover,
.navbar .navbar-nav > li > a.btn.btn-reddit:focus,
.navbar .navbar-nav > li > a.btn.btn-reddit:active,
.navbar .navbar-nav > li > a.btn.btn-reddit:hover {
  background-color: #ff4500;
  color: #fff;
  box-shadow: 0 14px 26px -12px rgba(255, 69, 0, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(255, 69, 0, 0.2);
}

.btn.btn-reddit.btn-simple,
.navbar .navbar-nav > li > a.btn.btn-reddit.btn-simple {
  color: #ff4500;
  background-color: transparent;
  box-shadow: none;
}

.btn.btn-reddit.btn-white,
.navbar .navbar-nav > li > a.btn.btn-reddit.btn-white {
  color: #ff4500;
  background-color: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14),
    0 3px 1px -2px rgba(153, 153, 153, 0.2),
    0 1px 5px 0 rgba(153, 153, 153, 0.12);
}

.btn.btn-tumblr,
.navbar .navbar-nav > li > a.btn.btn-tumblr {
  background-color: #35465c;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(53, 70, 92, 0.14),
    0 3px 1px -2px rgba(53, 70, 92, 0.2), 0 1px 5px 0 rgba(53, 70, 92, 0.12);
}

.btn.btn-tumblr:focus,
.btn.btn-tumblr:active,
.btn.btn-tumblr:hover,
.navbar .navbar-nav > li > a.btn.btn-tumblr:focus,
.navbar .navbar-nav > li > a.btn.btn-tumblr:active,
.navbar .navbar-nav > li > a.btn.btn-tumblr:hover {
  background-color: #35465c;
  color: #fff;
  box-shadow: 0 14px 26px -12px rgba(53, 70, 92, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(53, 70, 92, 0.2);
}

.btn.btn-tumblr.btn-simple,
.navbar .navbar-nav > li > a.btn.btn-tumblr.btn-simple {
  color: #35465c;
  background-color: transparent;
  box-shadow: none;
}

.btn.btn-tumblr.btn-white,
.navbar .navbar-nav > li > a.btn.btn-tumblr.btn-white {
  color: #35465c;
  background-color: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14),
    0 3px 1px -2px rgba(153, 153, 153, 0.2),
    0 1px 5px 0 rgba(153, 153, 153, 0.12);
}

.btn.btn-behance,
.navbar .navbar-nav > li > a.btn.btn-behance {
  background-color: #1769ff;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(23, 105, 255, 0.14),
    0 3px 1px -2px rgba(23, 105, 255, 0.2), 0 1px 5px 0 rgba(23, 105, 255, 0.12);
}

.btn.btn-behance:focus,
.btn.btn-behance:active,
.btn.btn-behance:hover,
.navbar .navbar-nav > li > a.btn.btn-behance:focus,
.navbar .navbar-nav > li > a.btn.btn-behance:active,
.navbar .navbar-nav > li > a.btn.btn-behance:hover {
  background-color: #1769ff;
  color: #fff;
  box-shadow: 0 14px 26px -12px rgba(23, 105, 255, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(23, 105, 255, 0.2);
}

.btn.btn-behance.btn-simple,
.navbar .navbar-nav > li > a.btn.btn-behance.btn-simple {
  color: #1769ff;
  background-color: transparent;
  box-shadow: none;
}

.btn.btn-behance.btn-white,
.navbar .navbar-nav > li > a.btn.btn-behance.btn-white {
  color: #1769ff;
  background-color: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14),
    0 3px 1px -2px rgba(153, 153, 153, 0.2),
    0 1px 5px 0 rgba(153, 153, 153, 0.12);
}

.btn.btn-discord,
.navbar .navbar-nav > li > a.btn.btn-discord {
  background-color: #1769ff;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(23, 105, 255, 0.14),
    0 3px 1px -2px rgba(23, 105, 255, 0.2), 0 1px 5px 0 rgba(23, 105, 255, 0.12);
}

.btn.btn-discord:focus,
.btn.btn-discord:active,
.btn.btn-discord:hover,
.navbar .navbar-nav > li > a.btn.btn-discord:focus,
.navbar .navbar-nav > li > a.btn.btn-discord:active,
.navbar .navbar-nav > li > a.btn.btn-discord:hover {
  background-color: #1769ff;
  color: #fff;
  box-shadow: 0 14px 26px -12px rgba(23, 105, 255, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(23, 105, 255, 0.2);
}

.btn.btn-discord.btn-simple,
.navbar .navbar-nav > li > a.btn.btn-discord.btn-simple {
  color: #1769ff;
  background-color: transparent;
  box-shadow: none;
}

.btn.btn-discord.btn-white,
.navbar .navbar-nav > li > a.btn.btn-discord.btn-white {
  color: #1769ff;
  background-color: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14),
    0 3px 1px -2px rgba(153, 153, 153, 0.2),
    0 1px 5px 0 rgba(153, 153, 153, 0.12);
}

.btn:focus,
.btn:active,
.btn:active:focus,
.navbar .navbar-nav > li > a.btn:focus,
.navbar .navbar-nav > li > a.btn:active,
.navbar .navbar-nav > li > a.btn:active:focus {
  outline: 0;
}

.btn.btn-round,
.navbar .navbar-nav > li > a.btn.btn-round {
  border-radius: 30px;
}

.btn:not(.btn-just-icon):not(.btn-fab) .fa,
.navbar .navbar-nav > li > a.btn:not(.btn-just-icon):not(.btn-fab) .fa {
  font-size: 18px;
  margin-top: -2px;
  position: relative;
  top: 2px;
}

.btn.btn-fab,
.navbar .navbar-nav > li > a.btn.btn-fab {
  border-radius: 50%;
  font-size: 24px;
  height: 56px;
  margin: auto;
  min-width: 56px;
  width: 56px;
  padding: 0;
  overflow: hidden;
  position: relative;
  line-height: normal;
}

.btn.btn-fab .ripple-container,
.navbar .navbar-nav > li > a.btn.btn-fab .ripple-container {
  border-radius: 50%;
}

.btn.btn-fab.btn-fab-mini,
.btn-group-sm .btn.btn-fab,
.navbar .navbar-nav > li > a.btn.btn-fab.btn-fab-mini,
.btn-group-sm .navbar .navbar-nav > li > a.btn.btn-fab {
  height: 40px;
  min-width: 40px;
  width: 40px;
}

.btn.btn-fab.btn-fab-mini.material-icons,
.btn-group-sm .btn.btn-fab.material-icons,
.navbar .navbar-nav > li > a.btn.btn-fab.btn-fab-mini.material-icons,
.btn-group-sm .navbar .navbar-nav > li > a.btn.btn-fab.material-icons {
  top: -3.5px;
  left: -3.5px;
}

.btn.btn-fab.btn-fab-mini .material-icons,
.btn-group-sm .btn.btn-fab .material-icons,
.navbar .navbar-nav > li > a.btn.btn-fab.btn-fab-mini .material-icons,
.btn-group-sm .navbar .navbar-nav > li > a.btn.btn-fab .material-icons {
  font-size: 17px;
}

.btn.btn-fab i.material-icons,
.navbar .navbar-nav > li > a.btn.btn-fab i.material-icons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-12px, -12px);
  line-height: 24px;
  width: 24px;
  font-size: 24px;
}

.btn.btn-lg,
.btn-group-lg .btn,
.navbar .navbar-nav > li > a.btn.btn-lg,
.btn-group-lg .navbar .navbar-nav > li > a.btn {
  font-size: 14px;
  padding: 18px 36px;
}

.btn.btn-sm,
.btn-group-sm .btn,
.navbar .navbar-nav > li > a.btn.btn-sm,
.btn-group-sm .navbar .navbar-nav > li > a.btn {
  padding: 5px 20px;
  font-size: 11px;
}

.btn.btn-xs,
.btn-group-xs .btn,
.navbar .navbar-nav > li > a.btn.btn-xs,
.btn-group-xs .navbar .navbar-nav > li > a.btn {
  padding: 4px 15px;
  font-size: 10px;
}

.btn.btn-just-icon,
.navbar .navbar-nav > li > a.btn.btn-just-icon {
  font-size: 20px;
  padding: 12px 12px;
  line-height: 1em;
}

.btn.btn-just-icon i,
.navbar .navbar-nav > li > a.btn.btn-just-icon i {
  width: 20px;
}

.btn.btn-just-icon.btn-lg,
.navbar .navbar-nav > li > a.btn.btn-just-icon.btn-lg {
  font-size: 22px;
  padding: 13px 18px;
}

.btn .material-icons {
  vertical-align: middle;
  font-size: 17px;
  top: -1px;
  position: relative;
}

.btn .caret {
  margin-left: 3px;
}

.navbar .navbar-nav > li > a.btn {
  margin-top: 2px;
  margin-bottom: 2px;
}

.navbar .navbar-nav > li > a.btn.btn-fab {
  margin: 5px 2px;
}

.navbar .navbar-nav > li > a:not(.btn) .material-icons {
  margin-top: -3px;
  top: 0px;
  position: relative;
  margin-right: 3px;
}

.navbar .navbar-nav > li > .profile-photo {
  margin: 5px 2px;
}

.navbar-default:not(.navbar-transparent)
  .navbar-nav
  > li
  > a.btn.btn-white.btn-simple {
  color: #555555;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  margin: 10px 1px;
}

.btn-group .dropdown-menu,
.btn-group-vertical .dropdown-menu {
  border-radius: 0 0 3px 3px;
}

.btn-group.btn-group-raised,
.btn-group-vertical.btn-group-raised {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.btn-group .btn + .btn,
.btn-group .btn,
.btn-group .btn:active,
.btn-group .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn,
.btn-group-vertical .btn:active,
.btn-group-vertical .btn-group {
  margin: 0;
}

.close {
  font-size: inherit;
  color: #ffffff;
  opacity: 0.9;
  text-shadow: none;
}

.close:hover,
.close:focus {
  opacity: 1;
  color: #ffffff;
}

.close i {
  font-size: 20px;
}

.checkbox label {
  cursor: pointer;
  padding-left: 0;
  color: rgba(0, 0, 0, 0.26);
}

.form-group.is-focused .checkbox label {
  color: rgba(0, 0, 0, 0.26);
}

.form-group.is-focused .checkbox label:hover,
.form-group.is-focused .checkbox label:focus {
  color: rgba(0, 0, 0, 0.54);
}

fieldset[disabled] .form-group.is-focused .checkbox label {
  color: rgba(0, 0, 0, 0.26);
}

.checkbox input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  margin: 0;
  z-index: -1;
  width: 0;
  height: 0;
  overflow: hidden;
  left: 0;
  pointer-events: none;
}

.checkbox .checkbox-material {
  vertical-align: middle;
  position: relative;
  top: 1px;
  padding-right: 5px;
  display: inline-block;
}

.checkbox .checkbox-material:before {
  display: block;
  position: absolute;
  left: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.84);
  height: 20px;
  width: 20px;
  border-radius: 100%;
  z-index: 1;
  opacity: 0;
  margin: 0;
  top: 0;
  -webkit-transform: scale3d(2.3, 2.3, 1);
  -moz-transform: scale3d(2.3, 2.3, 1);
  -o-transform: scale3d(2.3, 2.3, 1);
  -ms-transform: scale3d(2.3, 2.3, 1);
  transform: scale3d(2.3, 2.3, 1);
}

.checkbox .checkbox-material .check {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.54);
  overflow: hidden;
  z-index: 1;
  border-radius: 3px;
}

.checkbox .checkbox-material .check:before {
  position: absolute;
  content: "";
  transform: rotate(45deg);
  display: block;
  margin-top: -3px;
  margin-left: 7px;
  width: 0;
  height: 0;
  background: red;
  box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0,
    0 0 0 0 inset;
  -webkit-animation: checkbox-off 0.3s forwards;
  -moz-animation: checkbox-off 0.3s forwards;
  -o-animation: checkbox-off 0.3s forwards;
  -ms-animation: checkbox-off 0.3s forwards;
  animation: checkbox-off 0.3s forwards;
}

.checkbox input[type="checkbox"]:focus + .checkbox-material .check:after {
  opacity: 0.2;
}

.checkbox input[type="checkbox"]:checked + .checkbox-material .check {
  background: #004000;
}

.checkbox input[type="checkbox"]:checked + .checkbox-material .check:before {
  color: #ffffff;
  box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px,
    -5px 5px 0 10px, 20px -12px 0 11px;
  -webkit-animation: checkbox-on 0.3s forwards;
  -moz-animation: checkbox-on 0.3s forwards;
  -o-animation: checkbox-on 0.3s forwards;
  -ms-animation: checkbox-on 0.3s forwards;
  animation: checkbox-on 0.3s forwards;
}

.checkbox input[type="checkbox"]:checked + .checkbox-material:before {
  -webkit-animation: rippleOn 500ms;
  -moz-animation: rippleOn 500ms;
  -o-animation: rippleOn 500ms;
  -ms-animation: rippleOn 500ms;
  animation: rippleOn 500ms;
}

.checkbox input[type="checkbox"]:checked + .checkbox-material .check:after {
  -webkit-animation: rippleOn 500ms forwards;
  -moz-animation: rippleOn 500ms forwards;
  -o-animation: rippleOn 500ms forwards;
  -ms-animation: rippleOn 500ms forwards;
  animation: rippleOn 500ms forwards;
}

.checkbox input[type="checkbox"]:not(:checked) + .checkbox-material:before {
  -webkit-animation: rippleOff 500ms;
  -moz-animation: rippleOff 500ms;
  -o-animation: rippleOff 500ms;
  -ms-animation: rippleOff 500ms;
  animation: rippleOff 500ms;
}

.checkbox
  input[type="checkbox"]:not(:checked)
  + .checkbox-material
  .check:after {
  -webkit-animation: rippleOff 500ms;
  -moz-animation: rippleOff 500ms;
  -o-animation: rippleOff 500ms;
  -ms-animation: rippleOff 500ms;
  animation: rippleOff 500ms;
}

fieldset[disabled] .checkbox,
fieldset[disabled] .checkbox input[type="checkbox"],
.checkbox input[type="checkbox"][disabled] ~ .checkbox-material .check,
.checkbox input[type="checkbox"][disabled] + .circle {
  opacity: 0.5;
}

.checkbox input[type="checkbox"][disabled] ~ .checkbox-material .check {
  border-color: #000000;
  opacity: 0.26;
}

.checkbox input[type="checkbox"][disabled] + .checkbox-material .check:after {
  background-color: rgba(0, 0, 0, 0.87);
  transform: rotate(-45deg);
}

@keyframes checkbox-on {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px,
      -5px 5px 0 10px, 15px 2px 0 11px;
  }
  50% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px,
      -5px 5px 0 10px, 20px 2px 0 11px;
  }
  100% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px,
      -5px 5px 0 10px, 20px -12px 0 11px;
  }
}

@keyframes rippleOn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}

@keyframes rippleOff {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}

.togglebutton {
  vertical-align: middle;
}

.togglebutton,
.togglebutton label,
.togglebutton input,
.togglebutton .toggle {
  user-select: none;
}

.togglebutton label {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.26);
}

.form-group.is-focused .togglebutton label {
  color: rgba(0, 0, 0, 0.26);
}

.form-group.is-focused .togglebutton label:hover,
.form-group.is-focused .togglebutton label:focus {
  color: rgba(0, 0, 0, 0.54);
}

fieldset[disabled] .form-group.is-focused .togglebutton label {
  color: rgba(0, 0, 0, 0.26);
}

.togglebutton label input[type="checkbox"] {
  opacity: 0;
  width: 0;
  height: 0;
}

.togglebutton label .toggle {
  text-align: left;
  margin-left: 5px;
}

.togglebutton label .toggle,
.togglebutton label input[type="checkbox"][disabled] + .toggle {
  content: "";
  display: inline-block;
  width: 30px;
  height: 15px;
  background-color: rgba(80, 80, 80, 0.7);
  border-radius: 15px;
  margin-right: 15px;
  transition: background 0.3s ease;
  vertical-align: middle;
}

.togglebutton label .toggle:after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  border-radius: 20px;
  position: relative;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
  left: -5px;
  top: -3px;
  border: 1px solid rgba(0, 0, 0, 0.54);
  transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease;
}

.togglebutton label input[type="checkbox"][disabled] + .toggle:after,
.togglebutton label input[type="checkbox"][disabled]:checked + .toggle:after {
  background-color: #bdbdbd;
}

.togglebutton label input[type="checkbox"] + .toggle:active:after,
.togglebutton label input[type="checkbox"][disabled] + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.1);
}

.togglebutton label input[type="checkbox"]:checked + .toggle:after {
  left: 15px;
}

.togglebutton label input[type="checkbox"]:checked + .toggle {
  background-color: rgba(156, 39, 176, 0.7);
}

.togglebutton label input[type="checkbox"]:checked + .toggle:after {
  border-color: #004000;
}

.togglebutton label input[type="checkbox"]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4),
    0 0 0 15px rgba(156, 39, 176, 0.1);
}

.radio label {
  cursor: pointer;
  padding-left: 35px;
  position: relative;
  color: rgba(0, 0, 0, 0.26);
}

.form-group.is-focused .radio label {
  color: rgba(0, 0, 0, 0.26);
}

.form-group.is-focused .radio label:hover,
.form-group.is-focused .radio label:focus {
  color: rgba(0, 0, 0, 0.54);
}

fieldset[disabled] .form-group.is-focused .radio label {
  color: rgba(0, 0, 0, 0.26);
}

.radio label span {
  display: block;
  position: absolute;
  left: 10px;
  top: 2px;
  transition-duration: 0.2s;
}

.radio label .circle {
  border: 1px solid rgba(0, 0, 0, 0.54);
  height: 15px;
  width: 15px;
  border-radius: 100%;
}

.radio label .check {
  height: 15px;
  width: 15px;
  border-radius: 100%;
  background-color: #004000;
  -webkit-transform: scale3d(0, 0, 0);
  -moz-transform: scale3d(0, 0, 0);
  -o-transform: scale3d(0, 0, 0);
  -ms-transform: scale3d(0, 0, 0);
  transform: scale3d(0, 0, 0);
}

.radio label .check:after {
  display: block;
  position: absolute;
  content: "";
  background-color: rgba(0, 0, 0, 0.87);
  left: -18px;
  top: -18px;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  z-index: 1;
  opacity: 0;
  margin: 0;
  -webkit-transform: scale3d(1.5, 1.5, 1);
  -moz-transform: scale3d(1.5, 1.5, 1);
  -o-transform: scale3d(1.5, 1.5, 1);
  -ms-transform: scale3d(1.5, 1.5, 1);
  transform: scale3d(1.5, 1.5, 1);
}

.radio label input[type="radio"]:not(:checked) ~ .check:after {
  -webkit-animation: rippleOff 500ms;
  -moz-animation: rippleOff 500ms;
  -o-animation: rippleOff 500ms;
  -ms-animation: rippleOff 500ms;
  animation: rippleOff 500ms;
}

.radio label input[type="radio"]:checked ~ .check:after {
  -webkit-animation: rippleOff 500ms;
  -moz-animation: rippleOff 500ms;
  -o-animation: rippleOff 500ms;
  -ms-animation: rippleOff 500ms;
  animation: rippleOff 500ms;
}

.radio input[type="radio"] {
  opacity: 0;
  height: 0;
  width: 0;
  overflow: hidden;
}

.radio input[type="radio"]:checked ~ .check,
.radio input[type="radio"]:checked ~ .circle {
  opacity: 1;
}

.radio input[type="radio"]:checked ~ .check {
  background-color: #004000;
}

.radio input[type="radio"]:checked ~ .circle {
  border-color: #004000;
}

.radio input[type="radio"]:checked ~ .check {
  -webkit-transform: scale3d(0.65, 0.65, 1);
  -moz-transform: scale3d(0.65, 0.65, 1);
  -o-transform: scale3d(0.65, 0.65, 1);
  -ms-transform: scale3d(0.65, 0.65, 1);
  transform: scale3d(0.65, 0.65, 1);
}

.radio input[type="radio"][disabled] ~ .check,
.radio input[type="radio"][disabled] ~ .circle {
  opacity: 0.26;
}

.radio input[type="radio"][disabled] ~ .check {
  background-color: #000000;
}

.radio input[type="radio"][disabled] ~ .circle {
  border-color: #000000;
}

.checkbox + .checkbox,
.radio + .radio {
  margin-top: 0;
}

@keyframes rippleOn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}

@keyframes rippleOff {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}

legend {
  margin-bottom: 20px;
  font-size: 21px;
}

output {
  padding-top: 8px;
  font-size: 14px;
  line-height: 1.42857;
}

.form-control {
  height: 36px;
  padding: 7px 0;
  font-size: 14px;
  line-height: 1.42857;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"].form-control,
  input[type="time"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control {
    line-height: 36px;
  }
  input[type="date"].input-sm,
  .input-group-sm input[type="date"],
  input[type="time"].input-sm,
  .input-group-sm input[type="time"],
  input[type="datetime-local"].input-sm,
  .input-group-sm input[type="datetime-local"],
  input[type="month"].input-sm,
  .input-group-sm input[type="month"] {
    line-height: 24px;
  }
  input[type="date"].input-lg,
  .input-group-lg input[type="date"],
  input[type="time"].input-lg,
  .input-group-lg input[type="time"],
  input[type="datetime-local"].input-lg,
  .input-group-lg input[type="datetime-local"],
  input[type="month"].input-lg,
  .input-group-lg input[type="month"] {
    line-height: 44px;
  }
}

.radio label,
.checkbox label {
  min-height: 20px;
}

.form-control-static {
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 34px;
}

.input-sm .input-sm {
  height: 24px;
  padding: 3px 0;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 0;
}

.input-sm select.input-sm {
  height: 24px;
  line-height: 24px;
}

.input-sm textarea.input-sm,
.input-sm select[multiple].input-sm {
  height: auto;
}

.form-group-sm .form-control {
  height: 24px;
  padding: 3px 0;
  font-size: 11px;
  line-height: 1.5;
}

.form-group-sm select.form-control {
  height: 24px;
  line-height: 24px;
}

.form-group-sm textarea.form-control,
.form-group-sm select[multiple].form-control {
  height: auto;
}

.form-group-sm .form-control-static {
  height: 24px;
  min-height: 31px;
  padding: 4px 0;
  font-size: 11px;
  line-height: 1.5;
}

.input-lg .input-lg {
  height: 44px;
  padding: 9px 0;
  font-size: 18px;
  line-height: 1.33333;
  border-radius: 0;
}

.input-lg select.input-lg {
  height: 44px;
  line-height: 44px;
}

.input-lg textarea.input-lg,
.input-lg select[multiple].input-lg {
  height: auto;
}

.form-group-lg .form-control {
  height: 44px;
  padding: 9px 0;
  font-size: 18px;
  line-height: 1.33333;
}

.form-group-lg select.form-control {
  height: 44px;
  line-height: 44px;
}

.form-group-lg textarea.form-control,
.form-group-lg select[multiple].form-control {
  height: auto;
}

.form-group-lg .form-control-static {
  height: 44px;
  min-height: 38px;
  padding: 10px 0;
  font-size: 18px;
  line-height: 1.33333;
}

.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  padding-top: 8px;
}

.form-horizontal .radio,
.form-horizontal .checkbox {
  min-height: 28px;
}

@media (min-width: 768px) {
  .form-horizontal .control-label {
    padding-top: 8px;
  }
}

@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 13px;
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  .form-horizontal .form-group-sm .control-label {
    padding-top: 4px;
    font-size: 11px;
  }
}

.label {
  border-radius: 2px;
}

.label,
.label.label-default {
  background-color: #ffffff;
}

.label.label-inverse {
  background-color: #212121;
}

.label.label-primary {
  background-color: #004000;
}

.label.label-success {
  background-color: #4caf50;
}

.label.label-info {
  background-color: #00bcd4;
}

.label.label-warning {
  background-color: #ff9800;
}

.label.label-danger {
  background-color: #f44336;
}

.label.label-rose {
  background-color: #e91e63;
}

.form-control,
.form-group .form-control {
  border: 0;
  background-image: linear-gradient(#004000, #004000),
    linear-gradient(#d2d2d2, #d2d2d2);
  background-size: 0 2px, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px);
  background-color: rgba(0, 0, 0, 0);
  transition: background 0s ease-out;
  float: none;
  box-shadow: none;
  border-radius: 0;
  font-weight: 400;
}

.form-control::-moz-placeholder,
.form-group .form-control::-moz-placeholder {
  color: #aaaaaa;
  font-weight: 400;
}

.form-control:-ms-input-placeholder,
.form-group .form-control:-ms-input-placeholder {
  color: #aaaaaa;
  font-weight: 400;
}

.form-control::-webkit-input-placeholder,
.form-group .form-control::-webkit-input-placeholder {
  color: #aaaaaa;
  font-weight: 400;
}

.form-control[readonly],
.form-control[disabled],
fieldset[disabled] .form-control,
.form-group .form-control[readonly],
.form-group .form-control[disabled],
fieldset[disabled] .form-group .form-control {
  background-color: rgba(0, 0, 0, 0);
}

.form-control[disabled],
fieldset[disabled] .form-control,
.form-group .form-control[disabled],
fieldset[disabled] .form-group .form-control {
  background-image: none;
  border-bottom: 1px dotted #d2d2d2;
}

.form-group {
  position: relative;
}

.form-group.label-static label.control-label,
.form-group.label-placeholder label.control-label,
.form-group.label-floating label.control-label {
  position: absolute;
  pointer-events: none;
  transition: 0.3s ease all;
}

.form-group.label-floating label.control-label {
  will-change: left, top, contents;
}

.form-group.label-placeholder:not(.is-empty) label.control-label {
  display: none;
}

.form-group .help-block {
  position: absolute;
  display: none;
}

.form-group.is-focused .form-control {
  outline: none;
  background-image: linear-gradient(#004000, #004000),
    linear-gradient(#d2d2d2, #d2d2d2);
  background-size: 100% 2px, 100% 1px;
  box-shadow: none;
  transition-duration: 0.3s;
}

.form-group.is-focused .form-control .material-input:after {
  background-color: #004000;
}

.form-group.is-focused.form-info .form-control {
  background-image: linear-gradient(#00bcd4, #00bcd4),
    linear-gradient(#d2d2d2, #d2d2d2);
}

.form-group.is-focused.form-success .form-control {
  background-image: linear-gradient(#4caf50, #4caf50),
    linear-gradient(#d2d2d2, #d2d2d2);
}

.form-group.is-focused.form-warning .form-control {
  background-image: linear-gradient(#ff9800, #ff9800),
    linear-gradient(#d2d2d2, #d2d2d2);
}

.form-group.is-focused.form-danger .form-control {
  background-image: linear-gradient(#f44336, #f44336),
    linear-gradient(#d2d2d2, #d2d2d2);
}

.form-group.is-focused.form-rose .form-control {
  background-image: linear-gradient(#e91e63, #e91e63),
    linear-gradient(#d2d2d2, #d2d2d2);
}

.form-group.is-focused.form-white .form-control {
  background-image: linear-gradient(#ffffff, #ffffff),
    linear-gradient(#d2d2d2, #d2d2d2);
}

.form-group.is-focused.label-placeholder label,
.form-group.is-focused.label-placeholder label.control-label {
  color: #aaaaaa;
}

.form-group.is-focused .help-block {
  display: block;
}

.form-group.has-warning .form-control {
  box-shadow: none;
}

.form-group.has-warning.is-focused .form-control {
  background-image: linear-gradient(#ff9800, #ff9800),
    linear-gradient(#d2d2d2, #d2d2d2);
}

.form-group.has-warning label.control-label,
.form-group.has-warning .help-block {
  color: #ff9800;
}

.form-group.has-error .form-control {
  box-shadow: none;
}

.form-group.has-error.is-focused .form-control {
  background-image: linear-gradient(#f44336, #f44336),
    linear-gradient(#d2d2d2, #d2d2d2);
}

.form-group.has-error label.control-label,
.form-group.has-error .help-block {
  color: #f44336;
}

.form-group.has-success .form-control {
  box-shadow: none;
}

.form-group.has-success.is-focused .form-control {
  background-image: linear-gradient(#4caf50, #4caf50),
    linear-gradient(#d2d2d2, #d2d2d2);
}

.form-group.has-success label.control-label,
.form-group.has-success .help-block {
  color: #4caf50;
}

.form-group.has-info .form-control {
  box-shadow: none;
}

.form-group.has-info.is-focused .form-control {
  background-image: linear-gradient(#00bcd4, #00bcd4),
    linear-gradient(#d2d2d2, #d2d2d2);
}

.form-group.has-info label.control-label,
.form-group.has-info .help-block {
  color: #00bcd4;
}

.form-group textarea {
  resize: none;
}

.form-group textarea ~ .form-control-highlight {
  margin-top: -11px;
}

.form-group select {
  appearance: none;
}

.form-group select ~ .material-input:after {
  display: none;
}

.form-control {
  margin-bottom: 7px;
}

.form-control::-moz-placeholder {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}

.form-control:-ms-input-placeholder {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}

.form-control::-webkit-input-placeholder {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}

.checkbox label,
.radio label,
label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}

label.control-label {
  font-size: 11px;
  line-height: 1.07143;
  color: #aaaaaa;
  font-weight: 400;
  margin: 16px 0 0 0;
}

.help-block {
  margin-top: 0;
  font-size: 11px;
}

.form-group {
  padding-bottom: 7px;
  margin: 27px 0 0 0;
}

.form-group .form-control {
  margin-bottom: 7px;
}

.form-group .form-control::-moz-placeholder {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}

.form-group .form-control:-ms-input-placeholder {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}

.form-group .form-control::-webkit-input-placeholder {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}

.form-group .checkbox label,
.form-group .radio label,
.form-group label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}

.form-group label.control-label {
  font-size: 11px;
  line-height: 1.07143;
  color: #aaaaaa;
  font-weight: 400;
  margin: 16px 0 0 0;
}

.form-group .help-block {
  margin-top: 0;
  font-size: 11px;
}

.form-group.label-floating label.control-label,
.form-group.label-placeholder label.control-label {
  top: -7px;
  font-size: 14px;
  line-height: 1.42857;
}

.form-group.label-static label.control-label,
.form-group.label-floating.is-focused label.control-label,
.form-group.label-floating:not(.is-empty) label.control-label {
  top: -28px;
  left: 0;
  font-size: 11px;
  line-height: 1.07143;
}

.form-group.label-floating
  input.form-control:-webkit-autofill
  ~ label.control-label
  label.control-label {
  top: -28px;
  left: 0;
  font-size: 11px;
  line-height: 1.07143;
}

.form-group.form-group-sm {
  padding-bottom: 3px;
  margin: 21px 0 0 0;
}

.form-group.form-group-sm .form-control {
  margin-bottom: 3px;
}

.form-group.form-group-sm .form-control::-moz-placeholder {
  font-size: 11px;
  line-height: 1.5;
  color: #aaaaaa;
  font-weight: 400;
}

.form-group.form-group-sm .form-control:-ms-input-placeholder {
  font-size: 11px;
  line-height: 1.5;
  color: #aaaaaa;
  font-weight: 400;
}

.form-group.form-group-sm .form-control::-webkit-input-placeholder {
  font-size: 11px;
  line-height: 1.5;
  color: #aaaaaa;
  font-weight: 400;
}

.form-group.form-group-sm .checkbox label,
.form-group.form-group-sm .radio label,
.form-group.form-group-sm label {
  font-size: 11px;
  line-height: 1.5;
  color: #aaaaaa;
  font-weight: 400;
}

.form-group.form-group-sm label.control-label {
  font-size: 9px;
  line-height: 1.125;
  color: #aaaaaa;
  font-weight: 400;
  margin: 16px 0 0 0;
}

.form-group.form-group-sm .help-block {
  margin-top: 0;
  font-size: 9px;
}

.form-group.form-group-sm.label-floating label.control-label,
.form-group.form-group-sm.label-placeholder label.control-label {
  top: -11px;
  font-size: 11px;
  line-height: 1.5;
}

.form-group.form-group-sm.label-static label.control-label,
.form-group.form-group-sm.label-floating.is-focused label.control-label,
.form-group.form-group-sm.label-floating:not(.is-empty) label.control-label {
  top: -25px;
  left: 0;
  font-size: 9px;
  line-height: 1.125;
}

.form-group.form-group-sm.label-floating
  input.form-control:-webkit-autofill
  ~ label.control-label
  label.control-label {
  top: -25px;
  left: 0;
  font-size: 9px;
  line-height: 1.125;
}

.form-group.form-group-lg {
  padding-bottom: 9px;
  margin: 30px 0 0 0;
}

.form-group.form-group-lg .form-control {
  margin-bottom: 9px;
}

.form-group.form-group-lg .form-control::-moz-placeholder {
  font-size: 18px;
  line-height: 1.33333;
  color: #aaaaaa;
  font-weight: 400;
}

.form-group.form-group-lg .form-control:-ms-input-placeholder {
  font-size: 18px;
  line-height: 1.33333;
  color: #aaaaaa;
  font-weight: 400;
}

.form-group.form-group-lg .form-control::-webkit-input-placeholder {
  font-size: 18px;
  line-height: 1.33333;
  color: #aaaaaa;
  font-weight: 400;
}

.form-group.form-group-lg .checkbox label,
.form-group.form-group-lg .radio label,
.form-group.form-group-lg label {
  font-size: 18px;
  line-height: 1.33333;
  color: #aaaaaa;
  font-weight: 400;
}

.form-group.form-group-lg label.control-label {
  font-size: 14px;
  line-height: 1;
  color: #aaaaaa;
  font-weight: 400;
  margin: 16px 0 0 0;
}

.form-group.form-group-lg .help-block {
  margin-top: 0;
  font-size: 14px;
}

.form-group.form-group-lg.label-floating label.control-label,
.form-group.form-group-lg.label-placeholder label.control-label {
  top: -5px;
  font-size: 18px;
  line-height: 1.33333;
}

.form-group.form-group-lg.label-static label.control-label,
.form-group.form-group-lg.label-floating.is-focused label.control-label,
.form-group.form-group-lg.label-floating:not(.is-empty) label.control-label {
  top: -32px;
  left: 0;
  font-size: 14px;
  line-height: 1;
}

.form-group.form-group-lg.label-floating
  input.form-control:-webkit-autofill
  ~ label.control-label
  label.control-label {
  top: -32px;
  left: 0;
  font-size: 14px;
  line-height: 1;
}

select.form-control {
  border: 0;
  box-shadow: none;
  border-radius: 0;
}

.form-group.is-focused select.form-control {
  box-shadow: none;
  border-color: #d2d2d2;
}

select.form-control[multiple],
.form-group.is-focused select.form-control[multiple] {
  height: 85px;
}

.input-group-btn .btn {
  margin: 0 0 7px 0;
}

.form-group.form-group-sm .input-group-btn .btn {
  margin: 0 0 3px 0;
}

.form-group.form-group-lg .input-group-btn .btn {
  margin: 0 0 9px 0;
}

.input-group .input-group-btn {
  padding: 0 12px;
}

.input-group .input-group-addon {
  border: 0;
  background: transparent;
  padding: 6px 15px 0px;
}

.form-group input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.form-control-feedback {
  opacity: 0;
}

.has-success .form-control-feedback {
  color: #4caf50;
  opacity: 1;
}

.has-error .form-control-feedback {
  color: #f44336;
  opacity: 1;
}

.pagination > li > a,
.pagination > li > span {
  border: 0;
  border-radius: 30px !important;
  transition: all 0.3s;
  padding: 0px 11px;
  margin: 0 3px;
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  color: #999999;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
  background: transparent;
}

.pagination > li > a:hover,
.pagination > li > a:focus,
.pagination > li > span:hover,
.pagination > li > span:focus {
  color: #999999;
}

.pagination > .active > a,
.pagination > .active > span {
  color: #999999;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background-color: #004000;
  border-color: #004000;
  color: #ffffff;
  box-shadow: 0 4px 5px 0 rgba(156, 39, 176, 0.14),
    0 1px 10px 0 rgba(156, 39, 176, 0.12),
    0 2px 4px -1px rgba(156, 39, 176, 0.2);
}

.pagination.pagination-info > .active > a,
.pagination.pagination-info > .active > a:focus,
.pagination.pagination-info > .active > a:hover,
.pagination.pagination-info > .active > span,
.pagination.pagination-info > .active > span:focus,
.pagination.pagination-info > .active > span:hover {
  background-color: #00bcd4;
  border-color: #00bcd4;
  box-shadow: 0 4px 5px 0 rgba(0, 188, 212, 0.14),
    0 1px 10px 0 rgba(0, 188, 212, 0.12), 0 2px 4px -1px rgba(0, 188, 212, 0.2);
}

.pagination.pagination-success > .active > a,
.pagination.pagination-success > .active > a:focus,
.pagination.pagination-success > .active > a:hover,
.pagination.pagination-success > .active > span,
.pagination.pagination-success > .active > span:focus,
.pagination.pagination-success > .active > span:hover {
  background-color: #4caf50;
  border-color: #4caf50;
  box-shadow: 0 4px 5px 0 rgba(76, 175, 80, 0.14),
    0 1px 10px 0 rgba(76, 175, 80, 0.12), 0 2px 4px -1px rgba(76, 175, 80, 0.2);
}

.pagination.pagination-warning > .active > a,
.pagination.pagination-warning > .active > a:focus,
.pagination.pagination-warning > .active > a:hover,
.pagination.pagination-warning > .active > span,
.pagination.pagination-warning > .active > span:focus,
.pagination.pagination-warning > .active > span:hover {
  background-color: #ff9800;
  border-color: #ff9800;
  box-shadow: 0 4px 5px 0 rgba(255, 152, 0, 0.14),
    0 1px 10px 0 rgba(255, 152, 0, 0.12), 0 2px 4px -1px rgba(255, 152, 0, 0.2);
}

.pagination.pagination-danger > .active > a,
.pagination.pagination-danger > .active > a:focus,
.pagination.pagination-danger > .active > a:hover,
.pagination.pagination-danger > .active > span,
.pagination.pagination-danger > .active > span:focus,
.pagination.pagination-danger > .active > span:hover {
  background-color: #f44336;
  border-color: #f44336;
  box-shadow: 0 4px 5px 0 rgba(244, 67, 54, 0.14),
    0 1px 10px 0 rgba(244, 67, 54, 0.12), 0 2px 4px -1px rgba(244, 67, 54, 0.2);
}

.label {
  border-radius: 12px;
  padding: 5px 12px;
  text-transform: uppercase;
  font-size: 10px;
}

.label.label-default {
  background-color: #999999;
}

.section-dark .nav-pills > li > a,
.section-image .nav-pills > li > a {
  color: #999999;
}

.section-dark .nav-pills > li > a:hover,
.section-dark .nav-pills > li > a:focus,
.section-image .nav-pills > li > a:hover,
.section-image .nav-pills > li > a:focus {
  background-color: #eeeeee;
}

.nav-pills > li > a {
  line-height: 24px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  min-width: 100px;
  text-align: center;
  color: #555555;
  transition: all 0.3s;
}

.nav-pills > li > a:hover {
  background-color: rgba(200, 200, 200, 0.2);
}

.nav-pills > li i {
  display: block;
  font-size: 30px;
  padding: 15px 0;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover {
  background-color: #004000;
  color: #ffffff;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(156, 39, 176, 0.6);
}

.nav-pills:not(.nav-pills-icons) > li > a {
  border-radius: 30px;
}

.nav-pills.nav-stacked > li + li {
  margin-top: 5px;
}

.nav-pills.nav-pills-info > li.active > a,
.nav-pills.nav-pills-info > li.active > a:focus,
.nav-pills.nav-pills-info > li.active > a:hover {
  background-color: #00bcd4;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(0, 188, 212, 0.6);
}

.nav-pills.nav-pills-success > li.active > a,
.nav-pills.nav-pills-success > li.active > a:focus,
.nav-pills.nav-pills-success > li.active > a:hover {
  background-color: #4caf50;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(76, 175, 80, 0.6);
}

.nav-pills.nav-pills-warning > li.active > a,
.nav-pills.nav-pills-warning > li.active > a:focus,
.nav-pills.nav-pills-warning > li.active > a:hover {
  background-color: #ff9800;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(255, 152, 0, 0.6);
}

.nav-pills.nav-pills-danger > li.active > a,
.nav-pills.nav-pills-danger > li.active > a:focus,
.nav-pills.nav-pills-danger > li.active > a:hover {
  background-color: #f44336;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(255, 152, 0, 0.6);
}

.nav-pills.nav-pills-rose > li.active > a,
.nav-pills.nav-pills-rose > li.active > a:focus,
.nav-pills.nav-pills-rose > li.active > a:hover {
  background-color: #e91e63;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(233, 30, 99, 0.6);
}

.tab-space {
  padding: 20px 0 50px 0px;
}

.navbar {
  border: 0;
  border-radius: 3px;
  padding: 10px 0;
}

.navbar .navbar-brand {
  position: relative;
  height: 50px;
  line-height: 30px;
  color: inherit;
  padding: 10px 15px;
}

.navbar .navbar-brand:hover,
.navbar .navbar-brand:focus {
  color: inherit;
  background-color: transparent;
}

.navbar .navbar-text {
  color: inherit;
  margin-top: 15px;
  margin-bottom: 15px;
}

.navbar .navbar-nav > li > a {
  color: inherit;
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
}

.navbar .navbar-nav > li > a:hover,
.navbar .navbar-nav > li > a:focus {
  color: inherit;
  background-color: transparent;
}

.navbar .navbar-nav > li > a .material-icons,
.navbar .navbar-nav > li > a .fa {
  font-size: 20px;
  max-width: 24px;
}

.navbar .navbar-nav > li > a:not(.btn-just-icon) .fa {
  position: relative;
  top: 2px;
  margin-top: -4px;
  margin-right: 4px;
}

.navbar .navbar-nav > li > .dropdown-menu {
  margin-top: -20px;
}

.navbar .navbar-nav > li.open > .dropdown-menu {
  margin-top: 0;
}

.navbar .navbar-nav > .active > a,
.navbar .navbar-nav > .active > a:hover,
.navbar .navbar-nav > .active > a:focus {
  color: inherit;
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar .navbar-nav > .disabled > a,
.navbar .navbar-nav > .disabled > a:hover,
.navbar .navbar-nav > .disabled > a:focus {
  color: inherit;
  background-color: transparent;
  opacity: 0.9;
}

.navbar .navbar-nav .profile-photo {
  padding: 0;
}

.navbar .navbar-nav .profile-photo .profile-photo-small {
  height: 40px;
  width: 40px;
}

.navbar .navbar-nav .caret {
  margin-top: -3px;
}

.navbar .navbar-toggle {
  border: 0;
}

.navbar .navbar-toggle:hover,
.navbar .navbar-toggle:focus {
  background-color: transparent;
}

.navbar .navbar-toggle .icon-bar {
  background-color: inherit;
  border: 1px solid;
  color: #ffffff;
}

.navbar .navbar-default .navbar-toggle,
.navbar .navbar-inverse .navbar-toggle {
  border-color: transparent;
}

.navbar .navbar-collapse,
.navbar .navbar-form {
  border-top: none;
  box-shadow: none;
}

.navbar .navbar-nav > .open > a,
.navbar .navbar-nav > .open > a:hover,
.navbar .navbar-nav > .open > a:focus {
  background-color: transparent;
  color: inherit;
}

@media (max-width: 767px) {
  .navbar .navbar-nav .navbar-text {
    color: inherit;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .navbar .navbar-nav .open .dropdown-menu > .dropdown-header {
    border: 0;
    color: inherit;
  }
  .navbar .navbar-nav .open .dropdown-menu .divider {
    border-bottom: 1px solid;
    opacity: 0.08;
  }
}

.navbar.navbar-default .logo-container .brand {
  color: #555555;
}

.navbar .navbar-link {
  color: inherit;
}

.navbar .navbar-link:hover {
  color: inherit;
}

.navbar .btn {
  margin-top: 0;
  margin-bottom: 0;
}

.navbar .btn-link {
  color: inherit;
}

.navbar .btn-link:hover,
.navbar .btn-link:focus {
  color: inherit;
}

.navbar .btn-link[disabled]:hover,
.navbar .btn-link[disabled]:focus,
fieldset[disabled] .navbar .btn-link:hover,
fieldset[disabled] .navbar .btn-link:focus {
  color: inherit;
}

.navbar .navbar-form {
  margin: 4px 0 0;
}

.navbar .navbar-form .form-group {
  margin: 0;
  padding: 0;
}

.navbar .navbar-form .form-group .material-input:before,
.navbar .navbar-form .form-group.is-focused .material-input:after {
  background-color: inherit;
}

.navbar .navbar-form .form-group .form-control,
.navbar .navbar-form .form-control {
  border-color: inherit;
  color: inherit;
  padding: 0;
  margin: 0;
  height: 28px;
  font-size: 14px;
  line-height: 1.42857;
}

.navbar,
.navbar.navbar-default {
  background-color: #ffffff;
  color: #555555;
  box-shadow: 0 4px 18px 0px rgba(0, 0, 0, 0.12),
    0 7px 10px -5px rgba(0, 0, 0, 0.15);
}

.navbar .navbar-form .form-group input.form-control::-moz-placeholder,
.navbar .navbar-form input.form-control::-moz-placeholder,
.navbar.navbar-default
  .navbar-form
  .form-group
  input.form-control::-moz-placeholder,
.navbar.navbar-default .navbar-form input.form-control::-moz-placeholder {
  color: #555555;
}

.navbar .navbar-form .form-group input.form-control:-ms-input-placeholder,
.navbar .navbar-form input.form-control:-ms-input-placeholder,
.navbar.navbar-default
  .navbar-form
  .form-group
  input.form-control:-ms-input-placeholder,
.navbar.navbar-default .navbar-form input.form-control:-ms-input-placeholder {
  color: #555555;
}

.navbar .navbar-form .form-group input.form-control::-webkit-input-placeholder,
.navbar .navbar-form input.form-control::-webkit-input-placeholder,
.navbar.navbar-default
  .navbar-form
  .form-group
  input.form-control::-webkit-input-placeholder,
.navbar.navbar-default
  .navbar-form
  input.form-control::-webkit-input-placeholder {
  color: #555555;
}

.navbar .dropdown-menu,
.navbar.navbar-default .dropdown-menu {
  border-radius: 3px !important;
}

.navbar .dropdown-menu li > a:hover,
.navbar .dropdown-menu li > a:focus,
.navbar.navbar-default .dropdown-menu li > a:hover,
.navbar.navbar-default .dropdown-menu li > a:focus {
  color: #ffffff;
  background-color: #ffffff;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(255, 255, 255, 0.4);
}

.navbar .dropdown-menu .active > a,
.navbar.navbar-default .dropdown-menu .active > a {
  background-color: #ffffff;
  color: #555555;
}

.navbar .dropdown-menu .active > a:hover,
.navbar .dropdown-menu .active > a:focus,
.navbar.navbar-default .dropdown-menu .active > a:hover,
.navbar.navbar-default .dropdown-menu .active > a:focus {
  color: #555555;
}

.navbar.navbar-inverse {
  background-color: #212121;
  color: #fff;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 12px -5px rgba(33, 33, 33, 0.46);
}

.navbar.navbar-inverse
  .navbar-form
  .form-group
  input.form-control::-moz-placeholder,
.navbar.navbar-inverse .navbar-form input.form-control::-moz-placeholder {
  color: #fff;
}

.navbar.navbar-inverse
  .navbar-form
  .form-group
  input.form-control:-ms-input-placeholder,
.navbar.navbar-inverse .navbar-form input.form-control:-ms-input-placeholder {
  color: #fff;
}

.navbar.navbar-inverse
  .navbar-form
  .form-group
  input.form-control::-webkit-input-placeholder,
.navbar.navbar-inverse
  .navbar-form
  input.form-control::-webkit-input-placeholder {
  color: #fff;
}

.navbar.navbar-inverse .dropdown-menu {
  border-radius: 3px !important;
}

.navbar.navbar-inverse .dropdown-menu li > a:hover,
.navbar.navbar-inverse .dropdown-menu li > a:focus {
  color: #ffffff;
  background-color: #212121;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(33, 33, 33, 0.4);
}

.navbar.navbar-inverse .dropdown-menu .active > a {
  background-color: #212121;
  color: #fff;
}

.navbar.navbar-inverse .dropdown-menu .active > a:hover,
.navbar.navbar-inverse .dropdown-menu .active > a:focus {
  color: #fff;
}

.navbar.navbar-primary {
  background-color: #004000;
  color: #ffffff;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 12px -5px rgba(156, 39, 176, 0.46);
}

.navbar.navbar-primary
  .navbar-form
  .form-group
  input.form-control::-moz-placeholder,
.navbar.navbar-primary .navbar-form input.form-control::-moz-placeholder {
  color: #ffffff;
}

.navbar.navbar-primary
  .navbar-form
  .form-group
  input.form-control:-ms-input-placeholder,
.navbar.navbar-primary .navbar-form input.form-control:-ms-input-placeholder {
  color: #ffffff;
}

.navbar.navbar-primary
  .navbar-form
  .form-group
  input.form-control::-webkit-input-placeholder,
.navbar.navbar-primary
  .navbar-form
  input.form-control::-webkit-input-placeholder {
  color: #ffffff;
}

.navbar.navbar-primary .dropdown-menu {
  border-radius: 3px !important;
}

.navbar.navbar-primary .dropdown-menu li > a:hover,
.navbar.navbar-primary .dropdown-menu li > a:focus {
  color: #ffffff;
  background-color: #004000;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(156, 39, 176, 0.4);
}

.navbar.navbar-primary .dropdown-menu .active > a {
  background-color: #004000;
  color: #ffffff;
}

.navbar.navbar-primary .dropdown-menu .active > a:hover,
.navbar.navbar-primary .dropdown-menu .active > a:focus {
  color: #ffffff;
}

.navbar.navbar-success {
  background-color: #4caf50;
  color: #ffffff;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 12px -5px rgba(76, 175, 80, 0.46);
}

.navbar.navbar-success
  .navbar-form
  .form-group
  input.form-control::-moz-placeholder,
.navbar.navbar-success .navbar-form input.form-control::-moz-placeholder {
  color: #ffffff;
}

.navbar.navbar-success
  .navbar-form
  .form-group
  input.form-control:-ms-input-placeholder,
.navbar.navbar-success .navbar-form input.form-control:-ms-input-placeholder {
  color: #ffffff;
}

.navbar.navbar-success
  .navbar-form
  .form-group
  input.form-control::-webkit-input-placeholder,
.navbar.navbar-success
  .navbar-form
  input.form-control::-webkit-input-placeholder {
  color: #ffffff;
}

.navbar.navbar-success .dropdown-menu {
  border-radius: 3px !important;
}

.navbar.navbar-success .dropdown-menu li > a:hover,
.navbar.navbar-success .dropdown-menu li > a:focus {
  color: #ffffff;
  background-color: #4caf50;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(76, 175, 80, 0.4);
}

.navbar.navbar-success .dropdown-menu .active > a {
  background-color: #4caf50;
  color: #ffffff;
}

.navbar.navbar-success .dropdown-menu .active > a:hover,
.navbar.navbar-success .dropdown-menu .active > a:focus {
  color: #ffffff;
}

.navbar.navbar-info {
  background-color: #00bcd4;
  color: #ffffff;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 12px -5px rgba(0, 188, 212, 0.46);
}

.navbar.navbar-info
  .navbar-form
  .form-group
  input.form-control::-moz-placeholder,
.navbar.navbar-info .navbar-form input.form-control::-moz-placeholder {
  color: #ffffff;
}

.navbar.navbar-info
  .navbar-form
  .form-group
  input.form-control:-ms-input-placeholder,
.navbar.navbar-info .navbar-form input.form-control:-ms-input-placeholder {
  color: #ffffff;
}

.navbar.navbar-info
  .navbar-form
  .form-group
  input.form-control::-webkit-input-placeholder,
.navbar.navbar-info .navbar-form input.form-control::-webkit-input-placeholder {
  color: #ffffff;
}

.navbar.navbar-info .dropdown-menu {
  border-radius: 3px !important;
}

.navbar.navbar-info .dropdown-menu li > a:hover,
.navbar.navbar-info .dropdown-menu li > a:focus {
  color: #ffffff;
  background-color: #00bcd4;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(0, 188, 212, 0.4);
}

.navbar.navbar-info .dropdown-menu .active > a {
  background-color: #00bcd4;
  color: #ffffff;
}

.navbar.navbar-info .dropdown-menu .active > a:hover,
.navbar.navbar-info .dropdown-menu .active > a:focus {
  color: #ffffff;
}

.navbar.navbar-warning {
  background-color: #ff9800;
  color: #ffffff;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 12px -5px rgba(255, 152, 0, 0.46);
}

.navbar.navbar-warning
  .navbar-form
  .form-group
  input.form-control::-moz-placeholder,
.navbar.navbar-warning .navbar-form input.form-control::-moz-placeholder {
  color: #ffffff;
}

.navbar.navbar-warning
  .navbar-form
  .form-group
  input.form-control:-ms-input-placeholder,
.navbar.navbar-warning .navbar-form input.form-control:-ms-input-placeholder {
  color: #ffffff;
}

.navbar.navbar-warning
  .navbar-form
  .form-group
  input.form-control::-webkit-input-placeholder,
.navbar.navbar-warning
  .navbar-form
  input.form-control::-webkit-input-placeholder {
  color: #ffffff;
}

.navbar.navbar-warning .dropdown-menu {
  border-radius: 3px !important;
}

.navbar.navbar-warning .dropdown-menu li > a:hover,
.navbar.navbar-warning .dropdown-menu li > a:focus {
  color: #ffffff;
  background-color: #ff9800;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(255, 152, 0, 0.4);
}

.navbar.navbar-warning .dropdown-menu .active > a {
  background-color: #ff9800;
  color: #ffffff;
}

.navbar.navbar-warning .dropdown-menu .active > a:hover,
.navbar.navbar-warning .dropdown-menu .active > a:focus {
  color: #ffffff;
}

.navbar.navbar-danger {
  background-color: #f44336;
  color: #ffffff;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 12px -5px rgba(244, 67, 54, 0.46);
}

.navbar.navbar-danger
  .navbar-form
  .form-group
  input.form-control::-moz-placeholder,
.navbar.navbar-danger .navbar-form input.form-control::-moz-placeholder {
  color: #ffffff;
}

.navbar.navbar-danger
  .navbar-form
  .form-group
  input.form-control:-ms-input-placeholder,
.navbar.navbar-danger .navbar-form input.form-control:-ms-input-placeholder {
  color: #ffffff;
}

.navbar.navbar-danger
  .navbar-form
  .form-group
  input.form-control::-webkit-input-placeholder,
.navbar.navbar-danger
  .navbar-form
  input.form-control::-webkit-input-placeholder {
  color: #ffffff;
}

.navbar.navbar-danger .dropdown-menu {
  border-radius: 3px !important;
}

.navbar.navbar-danger .dropdown-menu li > a:hover,
.navbar.navbar-danger .dropdown-menu li > a:focus {
  color: #ffffff;
  background-color: #f44336;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(244, 67, 54, 0.4);
}

.navbar.navbar-danger .dropdown-menu .active > a {
  background-color: #f44336;
  color: #ffffff;
}

.navbar.navbar-danger .dropdown-menu .active > a:hover,
.navbar.navbar-danger .dropdown-menu .active > a:focus {
  color: #ffffff;
}

.navbar.navbar-rose {
  background-color: #e91e63;
  color: #ffffff;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 12px -5px rgba(233, 30, 99, 0.46);
}

.navbar.navbar-rose
  .navbar-form
  .form-group
  input.form-control::-moz-placeholder,
.navbar.navbar-rose .navbar-form input.form-control::-moz-placeholder {
  color: #ffffff;
}

.navbar.navbar-rose
  .navbar-form
  .form-group
  input.form-control:-ms-input-placeholder,
.navbar.navbar-rose .navbar-form input.form-control:-ms-input-placeholder {
  color: #ffffff;
}

.navbar.navbar-rose
  .navbar-form
  .form-group
  input.form-control::-webkit-input-placeholder,
.navbar.navbar-rose .navbar-form input.form-control::-webkit-input-placeholder {
  color: #ffffff;
}

.navbar.navbar-rose .dropdown-menu {
  border-radius: 3px !important;
}

.navbar.navbar-rose .dropdown-menu li > a:hover,
.navbar.navbar-rose .dropdown-menu li > a:focus {
  color: #ffffff;
  background-color: #e91e63;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(233, 30, 99, 0.4);
}

.navbar.navbar-rose .dropdown-menu .active > a {
  background-color: #e91e63;
  color: #ffffff;
}

.navbar.navbar-rose .dropdown-menu .active > a:hover,
.navbar.navbar-rose .dropdown-menu .active > a:focus {
  color: #ffffff;
}

.navbar-inverse {
  background-color: #3f51b5;
}

.navbar.navbar-transparent {
  background-color: transparent;
  box-shadow: none;
  color: #fff;
  padding-top: 25px;
}

.navbar.navbar-transparent .logo-container .brand {
  color: #ffffff;
}

.navbar-fixed-top {
  border-radius: 0;
}

@media (max-width: 1199px) {
  .navbar {
    /*
    .navbar-form {
      margin-top: 10px;
    }
*/
  }
  .navbar .navbar-brand {
    height: 50px;
    padding: 10px 15px;
  }
  .navbar .navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.navbar .alert {
  border-radius: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 85px;
  width: 100%;
  z-index: 3;
  transition: all 0.3s;
}

.nav-align-center {
  text-align: center;
}

.nav-align-center .nav-pills {
  display: inline-block;
}

.navbar-absolute {
  position: absolute;
  width: 100%;
  padding-top: 10px;
  z-index: 1029;
}

.dropdown-menu {
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.dropdown-menu .divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.dropdown-menu li > a {
  font-size: 13px;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 2px;
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear;
}

.dropdown-menu li > a:hover,
.dropdown-menu li > a:focus {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(156, 39, 176, 0.4);
}

.dropdown-menu.dropdown-with-icons li > a {
  padding: 12px 20px 12px 12px;
}

.dropdown-menu.dropdown-with-icons li > a .material-icons {
  vertical-align: middle;
  font-size: 24px;
  position: relative;
  margin-top: -4px;
  top: 1px;
  margin-right: 12px;
  opacity: 0.5;
}

.dropdown-menu li {
  position: relative;
}

.dropdown-menu li a:hover,
.dropdown-menu li a:focus,
.dropdown-menu li a:active {
  color: #004000;
  background-color: #fff;
}

.dropdown-menu .divider {
  margin: 5px 0;
}

.navbar .dropdown-menu li > a:hover,
.navbar .dropdown-menu li > a:focus,
.navbar.navbar-default .dropdown-menu li > a:hover,
.navbar.navbar-default .dropdown-menu li > a:focus {
  color: #004000;
  background-color: rgba(0, 64, 0, 0.55);
  box-shadow: 0 0px 0px 0px rgba(0, 64, 0, 0),
    0 0px 0px 0px rgba(156, 39, 176, 0.4);

}

.dropdown .dropdown-menu,
.dropup:not(.bootstrap-select) .dropdown-menu,
.bootstrap-select .dropdown-menu,
.dropdown-menu.bootstrap-datetimepicker-widget {
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear;
  -webkit-transform: translate3d(0, -20px, 0);
  -moz-transform: translate3d(0, -20px, 0);
  -o-transform: translate3d(0, -20px, 0);
  -ms-transform: translate3d(0, -20px, 0);
  transform: translate3d(0, -20px, 0);
  visibility: hidden;
  display: block;
  opacity: 0;
}

.dropdown.open .dropdown-menu,
.bootstrap-select.open .dropdown-menu,
.dropdown-menu.bootstrap-datetimepicker-widget.open {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate3d(0, 1px, 0);
  -moz-transform: translate3d(0, 1px, 0);
  -o-transform: translate3d(0, 1px, 0);
  -ms-transform: translate3d(0, 1px, 0);
  transform: translate3d(0, 1px, 0);
}

.bootstrap-select.dropup .dropdown-menu,
.dropup:not(.bootstrap-select) .dropdown-menu {
  -webkit-transform: translate3d(0, 20px, 0);
  -moz-transform: translate3d(0, 20px, 0);
  -o-transform: translate3d(0, 20px, 0);
  -ms-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0);
}

.bootstrap-select.dropup.open .dropdown-menu,
.dropup:not(.bootstrap-select).open .dropdown-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate3d(0, -2px, 0);
  -moz-transform: translate3d(0, -2px, 0);
  -o-transform: translate3d(0, -2px, 0);
  -ms-transform: translate3d(0, -2px, 0);
  transform: translate3d(0, -2px, 0);
}

.alert {
  border: 0;
  border-radius: 0;
  padding: 20px 15px;
  line-height: 20px;
}

.alert b {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
}

.alert,
.alert.alert-default {
  background-color: white;
  color: #555555;
}

.alert a,
.alert .alert-link,
.alert.alert-default a,
.alert.alert-default .alert-link {
  color: #555555;
}

.alert.alert-inverse {
  background-color: #292929;
  color: #fff;
}

.alert.alert-inverse a,
.alert.alert-inverse .alert-link {
  color: #fff;
}

.alert.alert-primary {
  background-color: #a72abd;
  color: #ffffff;
}

.alert.alert-primary a,
.alert.alert-primary .alert-link {
  color: #ffffff;
}

.alert.alert-success {
  background-color: #55b559;
  color: #ffffff;
}

.alert.alert-success a,
.alert.alert-success .alert-link {
  color: #ffffff;
}

.alert.alert-info {
  background-color: #00cae3;
  color: #ffffff;
}

.alert.alert-info a,
.alert.alert-info .alert-link {
  color: #ffffff;
}

.alert.alert-warning {
  background-color: #ff9e0f;
  color: #ffffff;
}

.alert.alert-warning a,
.alert.alert-warning .alert-link {
  color: #ffffff;
}

.alert.alert-danger {
  background-color: #f55145;
  color: #ffffff;
}

.alert.alert-danger a,
.alert.alert-danger .alert-link {
  color: #ffffff;
}

.alert.alert-rose {
  background-color: #ea2c6d;
  color: #ffffff;
}

.alert.alert-rose a,
.alert.alert-rose .alert-link {
  color: #ffffff;
}

.alert-info,
.alert-danger,
.alert-warning,
.alert-success {
  color: #ffffff;
}

.alert-default a,
.alert-default .alert-link {
  color: rgba(0, 0, 0, 0.87);
}

.alert .alert-icon {
  display: block;
  float: left;
  margin-right: 15px;
}

.alert .alert-icon i {
  margin-top: -7px;
  top: 5px;
  position: relative;
}

.progress {
  height: 4px;
  border-radius: 0;
  box-shadow: none;
  background: #dddddd;
}

.progress .progress-bar {
  box-shadow: none;
}

.progress .progress-bar,
.progress .progress-bar.progress-bar-default {
  background-color: #ffffff;
}

.progress .progress-bar.progress-bar-inverse {
  background-color: #212121;
}

.progress .progress-bar.progress-bar-primary {
  background-color: #004000;
}

.progress .progress-bar.progress-bar-success {
  background-color: #4caf50;
}

.progress .progress-bar.progress-bar-info {
  background-color: #00bcd4;
}

.progress .progress-bar.progress-bar-warning {
  background-color: #ff9800;
}

.progress .progress-bar.progress-bar-danger {
  background-color: #f44336;
}

.progress .progress-bar.progress-bar-rose {
  background-color: #e91e63;
}

.progress.progress-line-primary {
  background: rgba(156, 39, 176, 0.2);
}

.progress.progress-line-info {
  background: rgba(0, 188, 212, 0.2);
}

.progress.progress-line-success {
  background: rgba(76, 175, 80, 0.2);
}

.progress.progress-line-warning {
  background: rgba(255, 152, 0, 0.2);
}

.progress.progress-line-danger {
  background: rgba(244, 67, 54, 0.2);
}

.progress .progress-bar,
.progress .progress-bar.progress-bar-default {
  background-color: #004000;
}

h1,
.h1 {
  font-size: 3.8em;
  line-height: 1.15em;
}

h2,
.h2 {
  font-size: 2.6em;
}

h3,
.h3 {
  font-size: 1.825em;
  line-height: 1.4em;
  margin: 20px 0 10px;
}

h4,
.h4 {
  font-size: 1.3em;
  line-height: 1.55em;
}

h5,
.h5 {
  font-size: 1.25em;
  line-height: 1.55em;
  margin-bottom: 15px;
}

h6,
.h6 {
  font-size: 0.9em;
  text-transform: uppercase;
  font-weight: 500;
}

.title,
.card-title,
.info-title,
.footer-brand,
.footer-big h5,
.footer-big h4,
.media .media-heading {
  font-weight: 700;
  font-family: "Roboto Slab", "Times New Roman", serif;
}

.title,
.title a,
.card-title,
.card-title a,
.info-title,
.info-title a,
.footer-brand,
.footer-brand a,
.footer-big h5,
.footer-big h5 a,
.footer-big h4,
.footer-big h4 a,
.media .media-heading,
.media .media-heading a {
  color: #3c4858;
  text-decoration: none;
}

h2.title {
  margin-bottom: 30px;
}

.description,
.card-description,
.footer-big p {
  color: #999999;
}

.text-warning {
  color: #ff9800;
}

.text-primary {
  color: #004000;
}

.text-danger {
  color: #f44336;
}

.text-success {
  color: #4caf50;
}

.text-info {
  color: #00bcd4;
}

.text-rose {
  color: #e91e63;
}

.text-gray {
  color: #999999;
}

.nav-tabs {
  background: #004000;
  border: 0;
  border-radius: 3px;
  padding: 0 15px;
}

.nav-tabs > li > a {
  color: #ffffff;
  border: 0;
  margin: 0;
  border-radius: 3px;
  line-height: 24px;
  text-transform: uppercase;
  font-size: 12px;
}

.nav-tabs > li > a:hover {
  background-color: transparent;
  border: 0;
}

.nav-tabs > li > a,
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  background-color: transparent;
  border: 0 !important;
  color: #ffffff !important;
  font-weight: 500;
}

.nav-tabs > li.disabled > a,
.nav-tabs > li.disabled > a:hover {
  color: rgba(255, 255, 255, 0.5);
}

.nav-tabs > li .material-icons {
  margin: -1px 5px 0 0;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  background-color: rgba(255, 255, 255, 0.2);
  transition: background-color 0.1s 0.2s;
}

.popover,
.tooltip-inner {
  color: #555555;
  line-height: 1.5em;
  background: #ffffff;
  border: none;
  border-radius: 3px;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.popover {
  padding: 0;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.popover.left > .arrow,
.popover.right > .arrow,
.popover.top > .arrow,
.popover.bottom > .arrow {
  border: none;
}

.popover-title {
  background-color: #ffffff;
  border: none;
  padding: 15px 15px 5px;
  font-size: 1.3em;
}

.popover-content {
  padding: 10px 15px 15px;
  line-height: 1.4;
}

.tooltip.in {
  opacity: 1;
  -webkit-transform: translate3d(0, 0px, 0);
  -moz-transform: translate3d(0, 0px, 0);
  -o-transform: translate3d(0, 0px, 0);
  -ms-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
}

.tooltip {
  opacity: 0;
  transition: opacity, transform 0.2s ease;
  -webkit-transform: translate3d(0, 5px, 0);
  -moz-transform: translate3d(0, 5px, 0);
  -o-transform: translate3d(0, 5px, 0);
  -ms-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
}

.tooltip.left .tooltip-arrow {
  border-left-color: #ffffff;
}

.tooltip.right .tooltip-arrow {
  border-right-color: #ffffff;
}

.tooltip.top .tooltip-arrow {
  border-top-color: #ffffff;
}

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #ffffff;
}

.tooltip-inner {
  padding: 10px 15px;
  min-width: 130px;
}

.carousel .carousel-control.left,
.carousel .carousel-control.right {
  background-image: none;
}

.carousel .carousel-control.right i {
  right: 0;
}

.carousel .item {
  text-align: center;
}

.carousel.carousel-full-nagivation .carousel-control {
  width: 50%;
}

.carousel.carousel-full-nagivation .carousel-control.left,
.carousel.carousel-full-nagivation .carousel-control.right {
  background-image: none;
}

.carousel.carousel-full-nagivation .carousel-control .material-icons,
.carousel.carousel-full-nagivation .carousel-control .fa {
  display: none;
}

.carousel.carousel-full-nagivation .left {
  cursor: url("../img/arrow-left.png"), url("../img/arrow-left.cur"), default !important;
}

.carousel.carousel-full-nagivation .right {
  cursor: url("../img/arrow-right.png"), url("../img/arrow-right.cur"), default !important;
}

.carousel .carousel-control {
  height: 15%;
  margin: auto;
}

.carousel .carousel-control .material-icons {
  z-index: 5;
  display: inline-block;
  font-size: 50px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50px;
  margin: auto;
}

.carousel .carousel-indicators {
  bottom: 5px;
}

.carousel .carousel-indicators li,
.carousel .carousel-indicators .active {
  margin: 11px 10px;
}

.carousel .carousel-indicators li {
  background: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 2px;
}

.carousel .carousel-indicators .active {
  margin-top: 10px;
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -o-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.carousel .carousel-caption {
  padding-bottom: 45px;
}

.carousel .carousel-caption .material-icons {
  position: relative;
  top: 5px;
}

.card {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.card .card-height-indicator {
  margin-top: 100%;
}

.card.no-shadow .card-image,
.card.no-shadow .card-image img {
  box-shadow: none;
}

.card .card-image {
  height: 60%;
  position: relative;
  z-index: 1;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: -30px;
  border-radius: 6px;
}

.card .card-image img {
  width: 100%;
  border-radius: 6px;
  pointer-events: none;
  box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.card .card-image .card-title {
  position: absolute;
  bottom: 15px;
  left: 15px;
  color: #fff;
  font-size: 1.3em;
  text-shadow: 0 2px 5px rgba(33, 33, 33, 0.5);
}

.card .card-image .colored-shadow {
  transform: scale(0.94);
  top: 12px;
  filter: blur(12px);
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: -1;
  transition: opacity 0.45s;
  opacity: 0;
}

.card .card-image.no-shadow {
  box-shadow: none;
}

.card .card-image.no-shadow.shadow-normal {
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56),
    0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.card .card-image.no-shadow .colored-shadow {
  display: none !important;
}

.card.no-shadow .nft-image,
.card.no-shadow .nft-image img {
  box-shadow: none;
}

.card .nft-image {
  height: 60%;
  position: relative;
  z-index: 1;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 1px;
}

.card .nft-image img {
  width: 64%;
  height: 64%;
  border-radius: 0px;
  pointer-events: none;
  box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.card .nft-image .card-title {
  position: absolute;
  bottom: 15px;
  left: 15px;
  color: #fff;
  font-size: 1.3em;
  text-shadow: 0 2px 5px rgba(33, 33, 33, 0.5);
}

.card .nft-image .colored-shadow {
  transform: scale(0.94);
  top: 12px;
  filter: blur(12px);
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: -1;
  transition: opacity 0.45s;
  opacity: 0;
}

.card .nft-image.no-shadow {
  box-shadow: none;
}

.card .nft-image.no-shadow.shadow-normal {
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56),
    0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.card .nft-image.no-shadow .colored-shadow {
  display: none !important;
}

.card .category:not([class*="text-"]) {
  color: #999999;
}

.card .card-content {
  padding: 15px 30px;
}

.card .card-body {
  height: 30%;
  padding: 18px;
}

.card .card-footer {
  height: 10%;
  padding: 18px;
}

.card .card-footer button,
.card .card-footer a {
  margin: 0 !important;
  position: relative;
  bottom: 25px;
  width: auto;
}

.card .card-footer button:first-child,
.card .card-footer a:first-child {
  left: -15px;
}

.card .header {
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56),
    0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  margin: 15px;
  border-radius: 3px;
  padding: 15px 0;
  background-color: #ffffff;
}

.card .header .title {
  color: #ffffff;
}

.card .header-primary,
.card .content-primary {
  background: linear-gradient(60deg, #ab47bc, #7b1fa2);
}

.card .header-info,
.card .content-info {
  background: linear-gradient(60deg, #26c6da, #0097a7);
}

.card .header-success,
.card .content-success {
  background: linear-gradient(60deg, #66bb6a, #388e3c);
}

.card .header-warning,
.card .content-warning {
  background: linear-gradient(60deg, #ffa726, #f57c00);
}

.card .header-danger,
.card .content-danger {
  background: linear-gradient(60deg, #ef5350, #d32f2f);
}

.card .header-rose,
.card .content-rose {
  background: linear-gradient(60deg, #ec407a, #c2185b);
}

.card .header-primary {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(156, 39, 176, 0.6);
}

.card .header-danger {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(244, 67, 54, 0.6);
}

.card .header-rose {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(233, 30, 99, 0.6);
}

.card .header-warning {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(255, 152, 0, 0.6);
}

.card .header-info {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(0, 188, 212, 0.6);
}

.card .header-success {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(76, 175, 80, 0.6);
}

.card [class*="header-"],
.card [class*="content-"] {
  color: #ffffff;
}

.card [class*="header-"] .card-title a,
.card [class*="header-"] .card-title,
.card [class*="header-"] .icon i,
.card [class*="content-"] .card-title a,
.card [class*="content-"] .card-title,
.card [class*="content-"] .icon i {
  color: #ffffff;
}

.card [class*="header-"] .icon i,
.card [class*="content-"] .icon i {
  border-color: rgba(255, 255, 255, 0.25);
}

.card [class*="header-"] .author a,
.card [class*="header-"] .footer .stats,
.card [class*="header-"] .category,
.card [class*="header-"] .card-description,
.card [class*="content-"] .author a,
.card [class*="content-"] .footer .stats,
.card [class*="content-"] .category,
.card [class*="content-"] .card-description {
  color: rgba(255, 255, 255, 0.8);
}

.card [class*="header-"] .author a:hover,
.card [class*="header-"] .author a:focus,
.card [class*="header-"] .author a:active,
.card [class*="content-"] .author a:hover,
.card [class*="content-"] .author a:focus,
.card [class*="content-"] .author a:active {
  color: #ffffff;
}

.card [class*="content-"] {
  border-radius: 6px;
}

.card [class*="content-"] h1 small,
.card [class*="content-"] h2 small,
.card [class*="content-"] h3 small {
  color: rgba(255, 255, 255, 0.8);
}

.card img {
  width: 100%;
  height: auto;
}

.card .category .material-icons {
  position: relative;
  top: 6px;
  line-height: 0;
}

.card .category-social .fa {
  font-size: 24px;
  position: relative;
  margin-top: -4px;
  top: 2px;
  margin-right: 5px;
}

.card .category-social .material-icons {
  position: relative;
  top: 5px;
}

.card .author .avatar {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 5px;
}

.card .author a {
  color: #3c4858;
  text-decoration: none;
}

.card .author a .ripple-container {
  display: none;
}

.card .footer {
  margin-top: 15px;
}

.card .footer div {
  display: inline-block;
}

.card .footer .author {
  color: #999999;
}

.card .footer .stats {
  float: right;
  line-height: 30px;
  color: #999999;
}

.card .footer .stats .material-icons {
  position: relative;
  top: 4px;
  font-size: 18px;
}

.carousel .card {
  max-width: 650px;
  margin: 60px auto;
}

.card .checkbox,
.card .radio {
  margin-top: 16px;
}

.card-signup .header {
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56),
    0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  margin-left: 20px;
  margin-right: 20px;
  margin-top: -40px;
  padding: 20px 0;
}

.card-signup .text-divider {
  margin-top: 30px;
  margin-bottom: 0px;
  text-align: center;
}

.card-signup .card-content {
  padding: 0px 30px 0px 10px;
}

.card-signup .checkbox {
  margin-top: 20px;
}

.card-signup .checkbox label {
  margin-left: 17px;
}

.card-signup .checkbox .checkbox-material {
  padding-right: 12px;
}

.card-signup .social-line {
  margin-top: 15px;
  text-align: center;
  padding: 0;
}

.card-signup .social-line .btn {
  color: #ffffff;
  margin-left: 5px;
  margin-right: 5px;
}

.card-contact .header {
  margin-top: -20px;
}

.card-contact .header .title {
  margin: 10px 0 10px;
}

.card-nav-tabs {
  margin-top: 45px;
}

.card-nav-tabs .header {
  margin-top: -30px;
}

.card-nav-tabs .nav-tabs {
  background: transparent;
}

.card-plain {
  background: transparent;
  box-shadow: none;
}

.card-plain .header {
  margin-left: 0;
  margin-right: 0;
}

.card-plain .card-content {
  padding-left: 5px;
  padding-right: 5px;
}

.card-plain .card-image {
  margin: 0;
  border-radius: 6px;
}

.card-plain .card-image img {
  border-radius: 6px;
}

.iframe-container iframe {
  width: 100%;
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56),
    0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.card-form-horizontal .card-content {
  padding-left: 15px;
  padding-right: 15px;
}

.card-form-horizontal .form-group {
  padding-bottom: 0px;
  margin: 3px 0 0 0;
}

.card-form-horizontal .form-group .form-control {
  margin-bottom: 0;
}

.card-form-horizontal .btn {
  margin: 0;
}

.card-form-horizontal .input-group .input-group-addon {
  padding-left: 0;
}

.card-profile,
.card-testimonial {
  margin-top: 30px;
  text-align: center;
}

.card-profile .btn-just-icon.btn-raised,
.card-testimonial .btn-just-icon.btn-raised {
  margin-left: 6px;
  margin-right: 6px;
}

.card-profile .card-avatar,
.card-testimonial .card-avatar {
  max-width: 130px;
  max-height: 130px;
  margin: -50px auto 0;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56),
    0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.card-profile .card-avatar + .card-content,
.card-testimonial .card-avatar + .card-content {
  margin-top: 15px;
}

.card-profile.card-plain .card-avatar,
.card-testimonial.card-plain .card-avatar {
  margin-top: 0;
}

.card-testimonial .card-avatar {
  max-width: 100px;
  max-height: 100px;
}

.card-testimonial .footer {
  margin-top: 0;
}

.card-testimonial .footer .card-avatar {
  margin-top: 10px;
  margin-bottom: -50px;
}

.card-testimonial .card-description {
  font-style: italic;
}

.card-testimonial .card-description + .card-title {
  margin-top: 30px;
}

.card-testimonial .icon {
  margin-top: 30px;
}

.card-testimonial .icon .material-icons {
  font-size: 40px;
}

.card-blog {
  margin-top: 30px;
}

.card-blog .row .category {
  margin-bottom: 0;
}

.card-blog .card-title {
  margin-top: 5px;
}

.card-blog .card-image + .category {
  margin-top: 20px;
}

.card-pricing {
  text-align: center;
}

.card-pricing .card-title {
  margin-top: 30px;
}

.card-pricing .card-content {
  padding: 15px !important;
}

.card-pricing .icon {
  padding: 10px 0 0px;
  color: #999999;
}

.card-pricing .icon i {
  font-size: 55px;
  border: 1px solid #e5e5e5;
  border-radius: 50%;
  width: 130px;
  line-height: 130px;
  height: 130px;
}

.card-pricing h1 small {
  font-size: 18px;
}

.card-pricing h1 small:first-child {
  position: relative;
  top: -17px;
  font-size: 26px;
}

.card-pricing ul {
  list-style: none;
  padding: 0;
  max-width: 240px;
  margin: 10px auto;
}

.card-pricing ul li {
  color: #999999;
  text-align: center;
  padding: 12px 0;
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
}

.card-pricing ul li:last-child {
  border: 0;
}

.card-pricing ul li b {
  color: #3c4858;
}

.card-pricing ul li i {
  top: 6px;
  position: relative;
}

.card-pricing.card-background ul li,
.card-pricing [class*="content-"] ul li {
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.3);
}

.card-pricing.card-background ul li b,
.card-pricing [class*="content-"] ul li b {
  color: #ffffff;
}

.card-pricing.card-background [class*="text-"],
.card-pricing [class*="content-"] [class*="text-"] {
  color: #ffffff;
}

.card-pricing.card-background:after {
  background-color: rgba(0, 0, 0, 0.7);
}

.card-raised {
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56),
    0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.card-background,
.back-background,
.front-background {
  background-position: center center;
  background-size: cover;
  text-align: center;
}

.card-background .card-content,
.back-background .card-content,
.front-background .card-content {
  position: relative;
  z-index: 2;
  min-height: 280px;
  padding-top: 40px;
  padding-bottom: 40px;
  max-width: 440px;
  margin: 0 auto;
}

.card-background .category,
.card-background .card-description,
.card-background small,
.back-background .category,
.back-background .card-description,
.back-background small,
.front-background .category,
.front-background .card-description,
.front-background small {
  color: rgba(255, 255, 255, 0.7);
}

.card-background .card-title,
.back-background .card-title,
.front-background .card-title {
  color: #ffffff;
  margin-top: 10px;
}

.card-background:not(.card-pricing) .btn,
.back-background:not(.card-pricing) .btn,
.front-background:not(.card-pricing) .btn {
  margin-bottom: 0;
}

.card-background:after,
.back-background:after,
.front-background:after {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.56);
  border-radius: 6px;
}

.back-background .card-content {
  min-height: auto;
  padding-top: 15px;
  padding-bottom: 15px;
}

.card-product {
  margin-top: 30px;
}

.card-product .btn-simple.btn-just-icon {
  padding: 0;
}

.card-product .footer {
  margin-top: 5px;
}

.card-product .footer .price h4 {
  margin-bottom: 0;
}

.card-product .footer .stats {
  margin-top: 5px;
}

.card-product .card-title,
.card-product .category,
.card-product .card-description {
  text-align: center;
}

.card-product .category {
  margin-bottom: 0;
}

.card-product .category ~ .card-title {
  margin-top: 0;
}

.card-product .price {
  font-size: 18px;
  color: #9a9a9a;
}

.card-product .price-old {
  text-decoration: line-through;
  font-size: 16px;
  color: #9a9a9a;
}

.card-product .price-new {
  color: #f44336;
}

.rotating-card-container {
  -webkit-perspective: 800px;
  -moz-perspective: 800px;
  -o-perspective: 800px;
  -ms-perspective: 800px;
  perspective: 800px;
}

.rotating-card-container .card-rotate {
  background: transparent;
  box-shadow: none;
}

.rotating-card-container .card-rotate:after {
  display: none;
}

.rotating-card-container .card {
  -webkit-transition: transform 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
  -moz-transition: transform 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
  -o-transition: transform 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
  -ms-transition: transform 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
  transition: transform 0.8s cubic-bezier(0.34, 1.45, 0.7, 1);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: relative;
}

.rotating-card-container .card .back,
.rotating-card-container .card .front {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  position: absolute;
  background-color: #ffffff;
  border-radius: 6px;
  top: 0;
  left: 0;
}

.rotating-card-container .card .back .card-content,
.rotating-card-container .card .front .card-content {
  height: 100%;
  justify-content: center;
  align-content: center;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
}

.rotating-card-container .card .front {
  z-index: 2;
  position: relative;
}

.rotating-card-container .card .back {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
  z-index: 5;
  text-align: center;
  width: 100%;
  height: 100%;
}

.rotating-card-container .card .back.back-background:after {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.56);
  border-radius: 6px;
}

.rotating-card-container .card .back.back-background .card-content {
  position: relative;
  z-index: 2;
}

.rotating-card-container .card .back .footer .btn {
  margin: 0;
}

.rotating-card-container .card .back .card-content {
  padding-left: 15px;
  padding-right: 15px;
}

.rotating-card-container:not(.manual-flip):hover .card {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.rotating-card-container.hover.manual-flip .card {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.card-profile .rotating-card-container .front {
  text-align: left;
}

/*       Fix bug for IE      */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rotating-card-container .card .front,
  .rotating-card-container .card .back {
    -ms-backface-visibility: visible;
    backface-visibility: visible;
  }
  .rotating-card-container .card .back {
    visibility: hidden;
    transition: visibility 0.3s cubic-bezier(0.34, 1.45, 0.7, 1);
  }
  .rotating-card-container .card .front {
    z-index: 4;
  }
  .rotating-card-container:not(.manual-flip):hover .card .back,
  .rotating-card-container.manual-flip.hover .card .back {
    z-index: 5;
    visibility: visible;
  }
}

.modal-content {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2),
    0 40px 77px 0 rgba(0, 0, 0, 0.22);
  border-radius: 6px;
  border: none;
}

.modal-content .modal-header {
  border-bottom: none;
  padding-top: 24px;
  padding-right: 24px;
  padding-bottom: 0;
  padding-left: 24px;
}

.modal-content .modal-body {
  padding-top: 24px;
  padding-right: 24px;
  padding-bottom: 16px;
  padding-left: 24px;
}

.modal-content .modal-footer {
  border-top: none;
  padding: 24px;
}

.modal-content .modal-footer.text-center {
  text-align: center;
}

.modal-content .modal-footer button {
  margin: 0;
  padding-left: 16px;
  padding-right: 16px;
  width: auto;
}

.modal-content .modal-footer button.pull-left {
  padding-left: 5px;
  padding-right: 5px;
  position: relative;
  left: -5px;
}

.modal-content .modal-footer button + button {
  margin-bottom: 16px;
}

.modal-content .modal-body + .modal-footer {
  padding-top: 0;
}

.modal-backdrop {
  background: rgba(0, 0, 0, 0.3);
}

.modal .modal-dialog {
  margin-top: 100px;
}

.modal .modal-dialog.modal-login {
  width: 360px;
}

.modal .modal-dialog.modal-login .modal-header .close {
  margin-top: -30px;
  margin-right: 10px;
  color: #ffffff;
}

.modal .modal-dialog.modal-login .modal-footer {
  padding-bottom: 0;
  padding-top: 0;
}

.modal .modal-dialog.modal-login .modal-body {
  padding-left: 4px;
  padding-bottom: 0;
  padding-top: 0;
}

.modal .modal-dialog.modal-login .card-signup {
  margin-bottom: 0;
}

.modal .modal-dialog.modal-signup .info-horizontal {
  padding: 0px 0px 20px;
}

.modal .modal-dialog.modal-signup .modal-footer {
  padding: 0 5px;
}

.modal .modal-dialog.modal-signup .modal-header {
  padding-top: 0;
}

.modal .modal-dialog.modal-signup .card-signup {
  padding: 40px 0;
  margin-bottom: 0;
}

.modal .modal-dialog.modal-signup .modal-body {
  padding-bottom: 0;
  padding-top: 0;
}

.modal .modal-header .close {
  color: #999999;
}

.modal .modal-header .close:hover,
.modal .modal-header .close:focus {
  opacity: 1;
}

.modal .modal-header .close i {
  font-size: 16px;
}

.modal-notice .instruction {
  margin-bottom: 25px;
}

.modal-notice .picture {
  max-width: 150px;
}

.modal-notice .modal-content .btn-raised {
  margin-bottom: 15px;
}

.modal-small {
  width: 300px;
  margin: 0 auto;
}

.modal-small .modal-body {
  margin-top: 20px;
}

.panel {
  background-color: transparent;
  border: 0 none;
  box-shadow: none;
}

.panel .panel-heading {
  background-color: transparent;
  border-bottom: 1px solid #ddd;
  padding: 25px 10px 5px 0px;
}

.panel .panel-heading .panel-title {
  font-size: 15px;
  font-weight: bolder;
}

.panel .panel-heading a {
  color: #3c4858;
}

.panel .panel-heading a:hover,
.panel .panel-heading a:active,
.panel .panel-heading a[aria-expanded="true"] {
  color: #004000;
}

.panel .panel-heading a[aria-expanded="true"] i,
.panel .panel-heading a.expanded i {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.panel .panel-heading i {
  float: right;
}

.panel .panel-body {
  border: 0 none;
  padding: 15px 0px 5px;
}

.panel.panel-default .panel-heading + .panel-collapse .panel-body {
  border: 0 none;
}

hr.on-dark {
  color: #1a1a1a;
}

hr.on-light {
  color: white;
}

@media (-webkit-min-device-pixel-ratio: 0.75),
  (min--moz-device-pixel-ratio: 0.75),
  (-o-device-pixel-ratio: 3 / 4),
  (min-device-pixel-ratio: 0.75),
  (min-resolution: 0.75dppx),
  (min-resolution: 120dpi) {
  hr {
    height: 0.75px;
  }
}

@media (-webkit-min-device-pixel-ratio: 1),
  (min--moz-device-pixel-ratio: 1),
  (-o-device-pixel-ratio: 1),
  (min-device-pixel-ratio: 1),
  (min-resolution: 1dppx),
  (min-resolution: 160dpi) {
  hr {
    height: 1px;
  }
}

@media (-webkit-min-device-pixel-ratio: 1.33),
  (min--moz-device-pixel-ratio: 1.33),
  (-o-device-pixel-ratio: 133 / 100),
  (min-device-pixel-ratio: 1.33),
  (min-resolution: 1.33dppx),
  (min-resolution: 213dpi) {
  hr {
    height: 1.333px;
  }
}

@media (-webkit-min-device-pixel-ratio: 1.5),
  (min--moz-device-pixel-ratio: 1.5),
  (-o-device-pixel-ratio: 3 / 2),
  (min-device-pixel-ratio: 1.5),
  (min-resolution: 1.5dppx),
  (min-resolution: 240dpi) {
  hr {
    height: 1.5px;
  }
}

@media (-webkit-min-device-pixel-ratio: 2),
  (min--moz-device-pixel-ratio: 2),
  (-o-device-pixel-ratio: 2 / 1),
  (min-device-pixel-ratio: 2),
  (min-resolution: 2dppx),
  (min-resolution: 380dpi) {
  hr {
    height: 2px;
  }
}

@media (-webkit-min-device-pixel-ratio: 3),
  (min--moz-device-pixel-ratio: 3),
  (-o-device-pixel-ratio: 3 / 1),
  (min-device-pixel-ratio: 3),
  (min-resolution: 3dppx),
  (min-resolution: 480dpi) {
  hr {
    height: 3px;
  }
}

@media (-webkit-min-device-pixel-ratio: 4),
  (min--moz-device-pixel-ratio: 4),
  (-o-device-pixel-ratio: 4 / 1),
  (min-device-pixel-ratio: 3),
  (min-resolution: 4dppx),
  (min-resolution: 640dpi) {
  hr {
    height: 4px;
  }
}

.img-thumbnail {
  border-radius: 16px;
}

.img-raised {
  box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.media .avatar {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 15px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
}

.media .avatar img {
  width: 100%;
}

.media .media-heading small {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.media .media-body {
  padding-right: 10px;
}

.media .media-body .media .media-body {
  padding-right: 0px;
}

.media .media-footer .btn {
  margin-bottom: 20px;
}

.media .media-footer:after {
  display: table;
  content: " ";
  clear: both;
}

.media p {
  color: #999999;
  font-size: 16px;
  line-height: 1.6em;
}

.media-left,
.media > .pull-left {
  padding: 10px;
}

.info {
  max-width: 360px;
  margin: 0 auto;
  padding: 70px 0 30px;
}

.info .icon {
  color: #999999;
}

.info .icon > i {
  font-size: 4.4em;
}

.info .info-title {
  color: #3c4858;
  margin: 30px 0 15px;
}

.info p {
  color: #999999;
}

.info-horizontal .icon {
  float: left;
  margin-top: 24px;
  margin-right: 10px;
}

.info-horizontal .icon > i {
  font-size: 2.6em;
}

.info-horizontal .description {
  overflow: hidden;
}

.icon.icon-primary {
  color: #004000;
}

.icon.icon-info {
  color: #00bcd4;
}

.icon.icon-success {
  color: #4caf50;
}

.icon.icon-warning {
  color: #ff9800;
}

.icon.icon-danger {
  color: #f44336;
}

.icon.icon-rose {
  color: #e91e63;
}

.table > thead > tr > th {
  border-bottom-width: 1px;
  font-size: 1.25em;
  font-weight: 300;
}

.table .radio,
.table .checkbox {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  width: 15px;
}

.table .radio .icons,
.table .checkbox .icons {
  position: relative;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 12px 8px;
  vertical-align: middle;
}

.table .th-description {
  max-width: 150px;
}

.table .td-price {
  font-size: 26px;
  font-weight: 300;
  margin-top: 5px;
  text-align: right;
}

.table .td-total {
  font-weight: 500;
  font-size: 1.25em;
  padding-top: 20px;
  text-align: right;
}

.table .td-actions .btn {
  margin: 0px;
  padding: 5px;
}

.table > tbody > tr {
  position: relative;
}

.table-shopping > thead > tr > th {
  font-size: 0.9em;
  text-transform: uppercase;
}

.table-shopping > tbody > tr > td {
  font-size: 16px;
}

.table-shopping > tbody > tr > td b {
  display: block;
  margin-bottom: 5px;
}

.table-shopping .td-name {
  font-weight: 400;
  font-size: 1.5em;
}

.table-shopping .td-name small {
  color: #999999;
  font-size: 0.75em;
  font-weight: 300;
}

.table-shopping .td-number {
  font-weight: 300;
  font-size: 1.3em;
}

.table-shopping .td-name {
  min-width: 200px;
}

.table-shopping .td-number {
  text-align: right;
  min-width: 145px;
}

.table-shopping .td-number small {
  margin-right: 3px;
}

.table-shopping .img-container {
  width: 120px;
  max-height: 160px;
  overflow: hidden;
  display: block;
}

.table-shopping .img-container img {
  width: 100%;
}

.dropdownjs {
  position: relative;
}

.dropdownjs * {
  box-sizing: border-box;
}

.dropdownjs > input {
  width: 100%;
  padding-right: 30px;
  text-overflow: ellipsis;
  outline: none !important;
}

.dropdownjs > ul {
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear;
  display: block;
  margin-top: -20px;
  min-width: 200px;
  opacity: 0;
  padding: 0;
  position: absolute;
  visibility: hidden;
  z-index: 10000;
}

.dropdownjs > input.focus ~ ul {
  opacity: 1;
  visibility: visible;
  margin-top: 1px;
}

.dropdownjs > ul > li {
  list-style: none;
  padding: 10px 20px;
}

.dropdownjs > ul > li.dropdownjs-add {
  padding: 0;
}

.dropdownjs > ul > li.dropdownjs-add > input {
  border: 0;
  padding: 10px 20px;
  width: 100%;
}

/* Theme */
.dropdownjs > input[readonly] {
  cursor: pointer;
}

select[data-dropdownjs][disabled] + .dropdownjs > input[readonly] {
  cursor: default;
}

.dropdownjs > ul {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  padding: 5px 0px;
  overflow: auto;
  max-width: 500px;
}

.dropdownjs > ul > li {
  cursor: pointer;
  word-wrap: break-word;
  font-size: 13px;
  font-weight: 400;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 2px;
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear;
}

.dropdownjs > ul > li .selected,
.dropdownjs > ul > li:active,
.dropdownjs > ul > li:focus,
.dropdownjs > ul > li:hover {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  background-color: #004000;
  color: #ffffff;
}

.dropdownjs > ul > li > .close:before {
  content: "\00d7";
  display: block;
  position: absolute;
  right: 15px;
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.6;
}

.dropdownjs > ul > li:h > .close:hover:before {
  opacity: 0.9;
}

.dropdownjs::after {
  right: 10px;
  top: 20px;
  font-size: 25px;
  position: absolute;
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  content: "\e5c5";
  pointer-events: none;
  color: #fff;
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
  width: 100% !important;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__clear {
  position: relative;
}

.select2-container[dir="rtl"]
  .select2-selection--single
  .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-search--inline {
  float: left;
}

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0;
}

.select2-container
  .select2-search--inline
  .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 0;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 200px !important;
  min-width: 200px;
  max-width: 500px;
  left: -100000px;
  width: 100%;
  z-index: 1051;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  float: left;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  min-width: 160px;
  top: 100%;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-results__option[aria-selected] {
  cursor: pointer;
  font-size: 13px;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 2px;
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear;
  display: block;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  color: #999;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #fff transparent transparent transparent;
  border-style: solid;
  border-width: 6px 5px 0 5px;
  height: 0;
  left: 50%;
  margin-left: -21px;
  margin-top: 3px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--default[dir="rtl"]
  .select2-selection--single
  .select2-selection__clear {
  float: left;
}

.select2-container--default[dir="rtl"]
  .select2-selection--single
  .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled
  .select2-selection--single {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled
  .select2-selection--single
  .select2-selection__clear {
  display: none;
}

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered
  li {
  list-style: none;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove:hover {
  color: #333;
}

.select2-container--default[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice,
.select2-container--default[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__placeholder,
.select2-container--default[dir="rtl"]
  .select2-selection--multiple
  .select2-search--inline {
  float: right;
}

.select2-container--default[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}

.select2 .select2-simple .select2-selection__arrow {
  display: none;
}

.select2 .select2-simple {
  border: 0;
  background-image: linear-gradient(#004000, #004000),
    linear-gradient(#d2d2d2, #d2d2d2);
  background-size: 0 2px, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px);
  background-color: rgba(0, 0, 0, 0);
  transition: background 0s ease-out;
  float: none;
  box-shadow: none;
  border-radius: 0;
  height: 30px;
  font-weight: 400;
}

.select2 .select2-simple::-moz-placeholder {
  color: #aaaaaa;
  font-weight: 400;
}

.select2 .select2-simple:-ms-input-placeholder {
  color: #aaaaaa;
  font-weight: 400;
}

.select2 .select2-simple::-webkit-input-placeholder {
  color: #aaaaaa;
  font-weight: 400;
}

.select2.select2-container--open .select2-simple {
  outline: none;
  background-image: linear-gradient(#004000, #004000),
    linear-gradient(#d2d2d2, #d2d2d2);
  background-size: 100% 2px, 100% 1px;
  box-shadow: none;
  transition-duration: 0.3s;
}

.select2.select2-container--open .select2-simple .material-input:after {
  background-color: #004000;
}

.select2-container--default.select2-container--disabled
  .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled
  .select2-selection__choice__remove {
  display: none;
}

.select2-container .select2-dropdown {
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear;
  display: block;
  -webkit-transform: translate3d(0, -20px, 0);
  -moz-transform: translate3d(0, -20px, 0);
  -o-transform: translate3d(0, -20px, 0);
  -ms-transform: translate3d(0, -20px, 0);
  transform: translate3d(0, -20px, 0);
  min-width: 200px;
  opacity: 0;
  padding: 0;
  position: absolute;
  visibility: hidden;
  z-index: 10000;
}

.select2-container--open.show-dropdown-with-animation .select2-dropdown {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate3d(0, 1px, 0);
  -moz-transform: translate3d(0, 1px, 0);
  -o-transform: translate3d(0, 1px, 0);
  -ms-transform: translate3d(0, 1px, 0);
  transform: translate3d(0, 1px, 0);
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 0;
  background-image: linear-gradient(#004000, #004000),
    linear-gradient(#d2d2d2, #d2d2d2);
  background-size: 0 2px, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px);
  background-color: rgba(0, 0, 0, 0);
  transition: background 0s ease-out;
  float: none;
  box-shadow: none;
  border-radius: 0;
  height: 30px;
  font-weight: 400;
}

.select2-container--default
  .select2-search--dropdown
  .select2-search__field:focus {
  outline: none;
  background-image: linear-gradient(#004000, #004000),
    linear-gradient(#d2d2d2, #d2d2d2);
  background-size: 100% 2px, 100% 1px;
  box-shadow: none;
  transition-duration: 0.3s;
}

.select2-container--default
  .select2-search--dropdown
  .select2-search__field:focus
  .material-input:after {
  background-color: #004000;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-results ul {
  padding: 5px 0;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 500px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option[role="group"] {
  padding: 0;
}

.select2-container--default .select2-results__option[aria-disabled="true"] {
  color: #999;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__group {
  padding-left: 0;
}

.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected]:focus,
.select2-container--default
  .select2-results__option--highlighted[aria-selected]:hover,
.select2-container--default
  .select2-results__option--highlighted[aria-selected]:active {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(156, 39, 176, 0.4);
  background-color: #004000;
  color: #ffffff;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic
  .select2-selection--single
  .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--classic
  .select2-selection--single
  .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px;
}

.select2-container--classic
  .select2-selection--single
  .select2-selection__placeholder {
  color: #999;
}

.select2-container--classic
  .select2-selection--single
  .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0);
}

.select2-container--classic
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--classic[dir="rtl"]
  .select2-selection--single
  .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir="rtl"]
  .select2-selection--single
  .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open
  .select2-selection--single
  .select2-selection__arrow {
  background: transparent;
  border: none;
}

.select2-container--classic.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above
  .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below
  .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic
  .select2-selection--multiple
  .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px;
}

.select2-container--classic
  .select2-selection--multiple
  .select2-selection__clear {
  display: none;
}

.select2-container--classic
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--classic
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--classic
  .select2-selection--multiple
  .select2-selection__choice__remove:hover {
  color: #555;
}

.select2-container--classic[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice {
  float: right;
}

.select2-container--classic[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--classic.select2-container--open
  .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above
  .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below
  .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option[role="group"] {
  padding: 0;
}

.select2-container--classic .select2-results__option[aria-disabled="true"] {
  color: grey;
}

.select2-container--classic
  .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

/*
 * bootstrap-tagsinput v0.8.0
 *
 */
.bootstrap-tagsinput {
  display: inline-block;
  padding: 4px 6px;
  max-width: 100%;
  line-height: 22px;
}

.bootstrap-tagsinput input {
  border: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  margin: 0;
  width: 74px;
  max-width: inherit;
}

.bootstrap-tagsinput input:focus {
  border: none;
  box-shadow: none;
}

.bootstrap-tagsinput.form-control input::-moz-placeholder {
  color: #777;
  opacity: 1;
}

.bootstrap-tagsinput.form-control input:-ms-input-placeholder,
.bootstrap-tagsinput.form-control input::-webkit-input-placeholder {
  color: #777;
}

.bootstrap-tagsinput .tag {
  cursor: pointer;
  margin: 5px 3px 5px 0;
  position: relative;
  padding: 3px 8px;
  border-radius: 12px;
  color: #ffffff;
  font-weight: 500;
  font-size: 0.75em;
  text-transform: uppercase;
  display: inline-block;
  line-height: 1.5em;
  padding-left: 0.8em;
}

.bootstrap-tagsinput .tag.tag-primary .tag {
  background-color: #004000;
  color: #ffffff;
}

.bootstrap-tagsinput .tag.tag-primary .tag .tagsinput-remove-link {
  color: #ffffff;
}

.bootstrap-tagsinput .tag.tag-primary .tagsinput-add {
  color: #004000;
}

.bootstrap-tagsinput .tag.tag-info .tag {
  background-color: #00bcd4;
  color: #ffffff;
}

.bootstrap-tagsinput .tag.tag-info .tag .tagsinput-remove-link {
  color: #ffffff;
}

.bootstrap-tagsinput .tag.tag-info .tagsinput-add {
  color: #00bcd4;
}

.bootstrap-tagsinput .tag.tag-success .tag {
  background-color: #4caf50;
  color: #ffffff;
}

.bootstrap-tagsinput .tag.tag-success .tag .tagsinput-remove-link {
  color: #ffffff;
}

.bootstrap-tagsinput .tag.tag-success .tagsinput-add {
  color: #4caf50;
}

.bootstrap-tagsinput .tag.tag-warning .tag {
  background-color: #ff9800;
  color: #ffffff;
}

.bootstrap-tagsinput .tag.tag-warning .tag .tagsinput-remove-link {
  color: #ffffff;
}

.bootstrap-tagsinput .tag.tag-warning .tagsinput-add {
  color: #ff9800;
}

.bootstrap-tagsinput .tag.tag-danger .tag {
  background-color: #f44336;
  color: #ffffff;
}

.bootstrap-tagsinput .tag.tag-danger .tag .tagsinput-remove-link {
  color: #ffffff;
}

.bootstrap-tagsinput .tag.tag-danger .tagsinput-add {
  color: #f44336;
}

.bootstrap-tagsinput .tag.tag-rose .tag {
  background-color: #e91e63;
  color: #ffffff;
}

.bootstrap-tagsinput .tag.tag-rose .tag .tagsinput-remove-link {
  color: #ffffff;
}

.bootstrap-tagsinput .tag.tag-rose .tagsinput-add {
  color: #e91e63;
}

.bootstrap-tagsinput .tag:hover {
  padding-right: 18px;
}

.bootstrap-tagsinput .tag:hover [data-role="remove"] {
  opacity: 1;
  padding-right: 6px;
}

.bootstrap-tagsinput .tag [data-role="remove"] {
  cursor: pointer;
  position: absolute;
  top: 3px;
  right: 0;
  opacity: 0;
}

.bootstrap-tagsinput .tag [data-role="remove"]:after {
  content: "x";
  padding: 0px 2px;
}

.btn-file {
  position: relative;
  overflow: hidden;
  vertical-align: middle;
}

.btn-file > input {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 23px;
  cursor: pointer;
  filter: alpha(opacity=0);
  opacity: 0;
  direction: ltr;
}

.fileinput {
  display: inline-block;
  margin-bottom: 9px;
}

.fileinput .form-control {
  display: inline-block;
  padding-top: 7px;
  padding-bottom: 5px;
  margin-bottom: 0;
  vertical-align: middle;
  cursor: text;
}

.fileinput .thumbnail {
  display: inline-block;
  margin-bottom: 10px;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  max-width: 360px;
}

.fileinput .thumbnail.img-circle {
  border-radius: 50%;
  max-width: 100px;
}

.fileinput .thumbnail > img {
  max-height: 100%;
}

.fileinput .btn {
  vertical-align: middle;
}

.fileinput-exists .fileinput-new,
.fileinput-new .fileinput-exists {
  display: none;
}

.fileinput-inline .fileinput-controls {
  display: inline;
}

.fileinput-filename {
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
}

.form-control .fileinput-filename {
  vertical-align: bottom;
}

.fileinput.input-group {
  display: table;
}

.fileinput.input-group > * {
  position: relative;
  z-index: 2;
}

.fileinput.input-group > .btn-file {
  z-index: 1;
}

.fileinput-new.input-group .btn-file,
.fileinput-new .input-group .btn-file {
  border-radius: 0 4px 4px 0;
}

.fileinput-new.input-group .btn-file.btn-xs,
.fileinput-new .input-group .btn-file.btn-xs,
.fileinput-new.input-group .btn-file.btn-sm,
.fileinput-new .input-group .btn-file.btn-sm {
  border-radius: 0 3px 3px 0;
}

.fileinput-new.input-group .btn-file.btn-lg,
.fileinput-new .input-group .btn-file.btn-lg {
  border-radius: 0 6px 6px 0;
}

.form-group.has-warning .fileinput .fileinput-preview {
  color: #ff9800;
}

.form-group.has-warning .fileinput .thumbnail {
  border-color: #ff9800;
}

.form-group.has-error .fileinput .fileinput-preview {
  color: #f44336;
}

.form-group.has-error .fileinput .thumbnail {
  border-color: #f44336;
}

.form-group.has-success .fileinput .fileinput-preview {
  color: #4caf50;
}

.form-group.has-success .fileinput .thumbnail {
  border-color: #4caf50;
}

.input-group-addon:not(:first-child) {
  border-left: 0;
}

.thumbnail {
  border: 0 none;
  border-radius: 0;
  padding: 0;
}

.nbs-flexisel-container {
  position: relative;
  max-width: 100%;
}

.nbs-flexisel-ul {
  position: relative;
  width: 99999px;
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  text-align: center;
}

.nbs-flexisel-inner {
  overflow: hidden;
  width: 100%;
}

.nbs-flexisel-item {
  float: left;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  position: relative;
  line-height: 0px;
}

.nbs-flexisel-item img {
  max-width: 100%;
  cursor: pointer;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
}

/*** Navigation ***/
.nbs-flexisel-nav-left,
.nbs-flexisel-nav-right {
  top: 40%;
}

.nbs-flexisel-nav-left {
  left: -20px;
}

.nbs-flexisel-nav-left.disabled {
  opacity: 0.4;
}

.nbs-flexisel-nav-right {
  right: -20px;
}

.nbs-flexisel-nav-right.disabled {
  opacity: 0.4;
}

.nbs-flexisel-nav-right,
.nbs-flexisel-nav-left {
  position: absolute;
  cursor: pointer;
  z-index: 100;
  opacity: 0.5;
}

.section-white {
  background: #ffffff;
}

.section-white-to-gray {
  background-color: #fff;
  background: rgba(0, 0, 0, 0) linear-gradient(0deg, #e5e5e5 0%, #fff 100%)
    repeat scroll 0 0;
}

.section-gray {
  background: #e5e5e5;
}

.section-light-gray {
  background: #eee;
}

.section-primary {
  background: #e1bee7;
  /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(60deg, #e1bee7, #ba68c8);
  /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(60deg, #e1bee7, #ba68c8);
  /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(60deg, #e1bee7, #ba68c8);
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(60deg, #e1bee7, #ba68c8);
  /* Standard syntax */
}

.section-info {
  background: #b2ebf2;
  /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(60deg, #b2ebf2, #4dd0e1);
  /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(60deg, #b2ebf2, #4dd0e1);
  /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(60deg, #b2ebf2, #4dd0e1);
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(60deg, #b2ebf2, #4dd0e1);
  /* Standard syntax */
}

.section-success {
  background: #c8e6c9;
  /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(60deg, #c8e6c9, #81c784);
  /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(60deg, #c8e6c9, #81c784);
  /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(60deg, #c8e6c9, #81c784);
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(60deg, #c8e6c9, #81c784);
  /* Standard syntax */
}

.section-warning {
  background: #ffe0b2;
  /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(60deg, #ffe0b2, #ffb74d);
  /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(60deg, #ffe0b2, #ffb74d);
  /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(60deg, #ffe0b2, #ffb74d);
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(60deg, #ffe0b2, #ffb74d);
  /* Standard syntax */
}

.section-danger {
  background: #ffcdd2;
  /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(60deg, #ffcdd2, #e57373);
  /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(60deg, #ffcdd2, #e57373);
  /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(60deg, #ffcdd2, #e57373);
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(60deg, #ffcdd2, #e57373);
  /* Standard syntax */
}

.section-rose {
  background: #f8bbd0;
  /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(60deg, #f8bbd0, #f06292);
  /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(60deg, #f8bbd0, #f06292);
  /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(60deg, #f8bbd0, #f06292);
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(60deg, #f8bbd0, #f06292);
  /* Standard syntax */
}

.section-space {
  height: 70px;
  display: block;
}

.section {
  padding: 70px 0;
}

.section-navbars {
  padding-bottom: 0;
}

.section-full-screen {
  height: 100vh;
}

.section-signup {
  padding-top: 20vh;
}

.section-dark,
.section-image {
  background-color: #343434;
  background-size: 200% 240%;
  background: #232323;
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    #585858 0%,
    #232323 100%
  );
  /* FF3.6+ */
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(0%, #585858),
    color-stop(100%, #232323)
  );
  /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    #585858 0%,
    #232323 100%
  );
  /* Chrome10+,Safari5.1+ */
  background: -o-radial-gradient(
    center,
    ellipse cover,
    #585858 0%,
    #232323 100%
  );
  /* Opera 12+ */
  background: -ms-radial-gradient(
    center,
    ellipse cover,
    #585858 0%,
    #232323 100%
  );
  /* IE10+ */
  background: radial-gradient(ellipse at center, #585858 0%, #232323 100%);
  /* W3C */
  background-size: 550% 450%;
}

.section-dark .title,
.section-dark .card-plain .card-title,
.section-dark .card-plain .icon i,
.section-image .title,
.section-image .card-plain .card-title,
.section-image .card-plain .icon i {
  color: #ffffff;
}

.section-dark .description,
.section-dark .card-plain .card-description,
.section-dark .card-plain .icon,
.section-image .description,
.section-image .card-plain .card-description,
.section-image .card-plain .icon {
  color: rgba(255, 255, 255, 0.76);
}

.section-dark .card-plain [class*="text-"],
.section-dark .card-plain ul li b,
.section-image .card-plain [class*="text-"],
.section-image .card-plain ul li b {
  color: #ffffff;
}

.section-dark .card-plain .category,
.section-image .card-plain .category {
  color: rgba(255, 255, 255, 0.5);
}

.section-dark .card .card-image .colored-shadow,
.section-image .card .card-image .colored-shadow {
  top: 0px;
}

.section-image {
  position: relative;
}

.section-image:after {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.7);
}

.section-image .container {
  z-index: 2;
  position: relative;
}

.section,
.section-image {
  background-position: center center;
  background-size: cover;
}

[class*="features-"],
[class*="team-"],
[class*="projects-"],
[class*="pricing-"],
[class*="testimonials-"],
[class*="contactus-"] {
  padding: 80px 0;
}

.page-header {
  height: 100vh;
  background-position: center center;
  background-size: cover;
  margin: 0;
  padding: 0;
  border: 0;
}

.page-header .carousel .carousel-indicators {
  bottom: 60px;
}

.page-header .container {
  padding-top: 226px;
  color: #ffffff;
}

.page-header .title {
  color: #ffffff;
}

.page-header.header-small {
  height: 65vh;
  min-height: 65vh;
}

.page-header.header-small .container {
  padding-top: 20vh;
}

.header-filter {
  position: relative;
}

.header-filter:before,
.header-filter:after {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  content: "";
}

.header-filter::before {
  background-color: rgba(0, 0, 0, 0.5);
}

.header-filter .container {
  z-index: 2;
  position: relative;
}

.header-filter[filter-color="purple"]:after,
.header-filter[filter-color="primary"]:after {
  background: rgba(225, 190, 231, 0.56);
  /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(
    60deg,
    rgba(225, 190, 231, 0.56),
    rgba(186, 104, 200, 0.95)
  );
  /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(
    60deg,
    rgba(225, 190, 231, 0.56),
    rgba(186, 104, 200, 0.95)
  );
  /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(
    60deg,
    rgba(225, 190, 231, 0.56),
    rgba(186, 104, 200, 0.95)
  );
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(
    60deg,
    rgba(225, 190, 231, 0.56),
    rgba(186, 104, 200, 0.95)
  );
  /* Standard syntax */
}

.header-filter[filter-color="blue"]:after,
.header-filter[filter-color="info"]:after {
  background: rgba(178, 235, 242, 0.56);
  /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(
    60deg,
    rgba(178, 235, 242, 0.56),
    rgba(77, 208, 225, 0.95)
  );
  /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(
    60deg,
    rgba(178, 235, 242, 0.56),
    rgba(77, 208, 225, 0.95)
  );
  /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(
    60deg,
    rgba(178, 235, 242, 0.56),
    rgba(77, 208, 225, 0.95)
  );
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(
    60deg,
    rgba(178, 235, 242, 0.56),
    rgba(77, 208, 225, 0.95)
  );
  /* Standard syntax */
}

.header-filter[filter-color="green"]:after,
.header-filter[filter-color="success"]:after {
  background: rgba(165, 214, 167, 0.56);
  /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(
    60deg,
    rgba(165, 214, 167, 0.56),
    rgba(102, 187, 106, 0.95)
  );
  /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(
    60deg,
    rgba(165, 214, 167, 0.56),
    rgba(102, 187, 106, 0.95)
  );
  /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(
    60deg,
    rgba(165, 214, 167, 0.56),
    rgba(102, 187, 106, 0.95)
  );
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(
    60deg,
    rgba(165, 214, 167, 0.56),
    rgba(102, 187, 106, 0.95)
  );
  /* Standard syntax */
}

.header-filter[filter-color="orange"]:after,
.header-filter[filter-color="warning"]:after {
  background: rgba(255, 224, 178, 0.56);
  /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(
    60deg,
    rgba(255, 224, 178, 0.56),
    rgba(255, 183, 77, 0.95)
  );
  /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(
    60deg,
    rgba(255, 224, 178, 0.56),
    rgba(255, 183, 77, 0.95)
  );
  /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(
    60deg,
    rgba(255, 224, 178, 0.56),
    rgba(255, 183, 77, 0.95)
  );
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(
    60deg,
    rgba(255, 224, 178, 0.56),
    rgba(255, 183, 77, 0.95)
  );
  /* Standard syntax */
}

.header-filter[filter-color="red"]:after,
.header-filter[filter-color="danger"]:after {
  background: rgba(239, 154, 154, 0.56);
  /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(
    60deg,
    rgba(239, 154, 154, 0.56),
    rgba(239, 83, 80, 0.95)
  );
  /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(
    60deg,
    rgba(239, 154, 154, 0.56),
    rgba(239, 83, 80, 0.95)
  );
  /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(
    60deg,
    rgba(239, 154, 154, 0.56),
    rgba(239, 83, 80, 0.95)
  );
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(
    60deg,
    rgba(239, 154, 154, 0.56),
    rgba(239, 83, 80, 0.95)
  );
  /* Standard syntax */
}

.header-filter[filter-color="rose"]:after {
  background: rgba(248, 187, 208, 0.56);
  /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(
    60deg,
    rgba(248, 187, 208, 0.56),
    rgba(240, 98, 146, 0.95)
  );
  /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(
    60deg,
    rgba(248, 187, 208, 0.56),
    rgba(240, 98, 146, 0.95)
  );
  /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(
    60deg,
    rgba(248, 187, 208, 0.56),
    rgba(240, 98, 146, 0.95)
  );
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(
    60deg,
    rgba(248, 187, 208, 0.56),
    rgba(240, 98, 146, 0.95)
  );
  /* Standard syntax */
}

.clear-filter:before {
  display: none;
}

.header-1 .wrapper,
.header-2 .wrapper,
.header-3 .wrapper {
  background: #cccccc;
}

.header-2 .page-header .container {
  padding-top: 25vh;
}

.header-2 .page-header .card {
  margin-top: 60px;
}

.header-3 .btn {
  margin: 0;
}

.header-3 h6 {
  margin-bottom: 0;
}

.phone-container img {
  width: 100%;
}

.features-1 {
  text-align: center;
}

.features-3 .info {
  max-width: none;
  margin: 0 auto;
  padding: 10px 0 0px;
}

.features-3 .phone-container {
  max-width: 200px;
  margin: 0 auto;
}

.features-4 .phone-container {
  max-width: 260px;
  margin: 60px auto 0;
}

.features-4 .info {
  max-width: none;
  margin: 0 auto;
  padding: 10px 0 0px;
}

.features-4 .info:first-child {
  padding-top: 130px;
}

.features-5 {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}

.features-5:after {
  background: rgba(0, 0, 0, 0.55);
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  z-index: 0;
  left: 0;
  top: 0;
}

.features-5 .container,
.features-5 .title {
  position: relative;
  z-index: 2;
}

.features-5 .title,
.features-5 .info-title {
  color: #ffffff;
}

.features-5 .info {
  text-align: center;
  max-width: 310px;
  min-height: 320px;
}

.features-5 .info .icon {
  color: #ffffff;
}

.features-5 .info .icon > i {
  font-size: 2.8em;
}

.features-5 .info p {
  color: #ffffff;
  min-height: 120px;
}

.features-5 .container [class*="col-"] {
  border: 1px solid rgba(255, 255, 255, 0.35);
  border-top: 0;
}

.features-5 .container [class*="col-"] {
  border-left: 0;
}

.features-5 .container [class*="col-"]:last-child {
  border-right: 0;
}

.features-5 .container .row:last-child [class*="col-"] {
  border-bottom: 0;
}

[class*="blogs-"] {
  padding: 50px 0;
}

.blogs-1 .card {
  margin-bottom: 80px;
}

.blogs-4 .card {
  margin-bottom: 60px;
  text-align: center;
}

.team-1 .card .content {
  padding-left: 15px;
  padding-right: 15px;
}

.team-1 .card .description {
  min-height: 90px;
}

.team-1 .card .btn {
  margin-top: 0;
}

.team-1 h5.description {
  margin-bottom: 80px;
}

.team-2 .card .title {
  margin: 10px 0 0;
}

.team-2 .card h6 {
  margin-top: 5px;
}

.team-2 h5.description {
  margin-bottom: 80px;
}

.team-3 .card,
.team-5 .card {
  text-align: left;
}

.team-3 .card .footer,
.team-5 .card .footer {
  margin-top: 0;
}

.team-3 h5.description,
.team-5 h5.description {
  margin-bottom: 70px;
}

.team-4 h5.description {
  margin-bottom: 100px;
}

.projects-1 .title {
  margin-top: 10px;
}

.projects-1 .nav-pills {
  display: inline-block;
}

.projects-2 h2.title {
  margin-top: 5px;
  margin-bottom: 15px;
}

.projects-2 .card-title {
  margin-bottom: 5px;
}

.projects-2 .category {
  color: #999999;
  margin-top: 5px;
}

.projects-2 .card-description {
  margin-top: 20px;
}

.projects-2 .card {
  text-align: center;
}

.projects-3 h2.title,
.projects-3 h3.title {
  margin-bottom: 80px;
  margin-top: 5px;
}

.projects-3 .card {
  margin-bottom: 30px;
  text-align: center;
}

.projects-3 .category {
  margin-bottom: 5px;
}

.projects-3 .card-title {
  margin-top: 5px;
}

.projects-4 h2.title {
  margin-top: 5px;
  margin-bottom: 15px;
}

.projects-4 .info {
  padding: 0;
}

.projects-4 .info p {
  margin: 0;
}

.projects-4 .card-background {
  margin-bottom: 0;
}

.projects-4 .card-background .card-content {
  padding-top: 140px;
  padding-bottom: 140px;
}

.projects-4 hr {
  margin: 70px auto;
  max-width: 970px;
}

.pricing-1 h2.title {
  margin-bottom: 10px;
}

.pricing-1 .card-separator {
  width: 1px;
  display: block;
  height: 100%;
  background-color: rgba(153, 153, 153, 0.2);
  position: absolute;
  left: 0;
  top: 0;
}

.pricing-1.section-image:after {
  background-color: rgba(0, 0, 0, 0.8);
}

.pricing-2 .nav-pills {
  display: inline-block;
  margin-bottom: 50px;
}

.pricing-5 .nav-pills {
  margin-top: 50px;
  margin-bottom: 50px;
}

.pricing-3 .title,
.pricing-4 .title {
  margin-bottom: 10px;
}

.testimonials-1 .description {
  margin-bottom: 50px;
}

.contactus-1 .info {
  padding: 0;
  margin: 0;
}

.contactus-1 .info:first-child {
  margin-top: 30px;
}

.contactus-1 .info .info-title {
  margin-top: 20px;
  color: #ffffff;
}

.contactus-1 .info .icon {
  margin-top: 14px;
  color: #ffffff;
}

.contactus-1 .card-contact {
  margin-top: 30px;
}

.contactus-2 {
  padding: 0;
}

.contactus-2 .card-contact {
  max-width: 560px;
  margin: 80px 0 80px 150px;
}

.contactus-2 .card-contact .info {
  padding: 0;
  margin: 0;
}

.contactus-2 .map {
  width: 100%;
  height: 100%;
  position: absolute;
}

.contactus-2 .info-horizontal .icon {
  margin-top: 26px;
}

.contactus-2 .info-horizontal .icon > i {
  font-size: 2.2em;
  max-width: 45px;
}

footer {
  padding: 15px 0;
  text-align: center;
}

footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

footer ul li {
  display: inline-block;
}

footer ul li a {
  color: inherit;
  padding: 15px;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
  text-decoration: none;
  position: relative;
  display: block;
}

footer ul li a:hover {
  text-decoration: none;
}

footer ul li .btn {
  margin: 0;
}

footer ul.links-horizontal:first-child a {
  padding-left: 0;
}

footer ul.links-horizontal:last-child a {
  padding-right: 0;
}

footer ul.links-vertical li {
  display: block;
  margin-left: -5px;
  margin-right: -5px;
}

footer ul.links-vertical li a {
  padding: 5px;
}

footer .social-buttons a,
footer .social-buttons .btn {
  margin-top: 5px;
  margin-bottom: 5px;
}

footer .footer-brand {
  float: left;
  height: 50px;
  padding: 15px 15px;
  font-size: 18px;
  line-height: 20px;
  margin-left: -15px;
}

footer .footer-brand:hover,
footer .footer-brand:focus {
  color: #3c4858;
}

footer .copyright {
  padding: 15px 0;
}

footer .copyright .material-icons {
  font-size: 18px;
  position: relative;
  top: 3px;
}

footer .pull-center {
  display: inline-block;
  float: none;
}

.footer-big {
  padding: 30px 0;
}

.footer-big .content {
  text-align: left;
}

.footer-big .social-feed i {
  font-size: 20px;
  display: table-cell;
  padding-right: 10px;
}

.footer-big .social-feed p {
  display: table-cell;
  vertical-align: top;
  overflow: hidden;
  padding-bottom: 10px;
  max-width: 300px;
}

.footer-big .gallery-feed img {
  width: 20%;
  margin-right: 5%;
  margin-bottom: 5%;
  float: left;
}

.footer-white {
  background-color: #ffffff;
}

.footer-gray {
  background-color: #eeeeee;
}

.footer-black {
  background: #232323;
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    #585858 0%,
    #232323 100%
  );
  /* FF3.6+ */
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(0%, #585858),
    color-stop(100%, #232323)
  );
  /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    #585858 0%,
    #232323 100%
  );
  /* Chrome10+,Safari5.1+ */
  background: -o-radial-gradient(
    center,
    ellipse cover,
    #585858 0%,
    #232323 100%
  );
  /* Opera 12+ */
  background: -ms-radial-gradient(
    center,
    ellipse cover,
    #585858 0%,
    #232323 100%
  );
  /* IE10+ */
  background: radial-gradient(ellipse at center, #585858 0%, #232323 100%);
  /* W3C */
  background-size: 550% 450%;
}

.footer-black a {
  color: #ffffff;
  opacity: 0.86;
}

.footer-black a:hover,
.footer-black a:focus {
  opacity: 1;
}

.footer-black .footer-brand {
  color: #ffffff;
}

.footer-black .footer-brand:hover,
.footer-black .footer-brand:focus {
  color: #ffffff;
}

.footer-black h5,
.footer-black h4,
.footer-black i,
.footer-black .copyright {
  color: #ffffff;
}

.footer-black hr {
  border-color: rgba(255, 255, 255, 0.2);
}

.social-line {
  padding: 15px 0;
}

.social-line-big-icons [class*="col-"] {
  border-right: 1px solid #eeeeee;
}

.social-line-big-icons [class*="col-"]:last-child {
  border: 0;
}

.social-line-big-icons .btn {
  margin: 0;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
}

.social-line-big-icons .btn .fa {
  font-size: 25px;
}

.subscribe-line {
  padding: 30px 0;
}

.subscribe-line .card {
  margin-top: 30px;
}

.subscribe-line.subscribe-line-image {
  position: relative;
  background-position: top center;
  background-size: cover;
}

.subscribe-line.subscribe-line-image .title {
  color: #ffffff;
}

.subscribe-line.subscribe-line-image:after {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.66);
}

.subscribe-line.subscribe-line-image .container {
  z-index: 2;
  position: relative;
}

.social-line-white,
.subscribe-line-white {
  background-color: #ffffff;
}

.social-line-black,
.subscribe-line-black {
  background: #232323;
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    #585858 0%,
    #232323 100%
  );
  /* FF3.6+ */
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(0%, #585858),
    color-stop(100%, #232323)
  );
  /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    #585858 0%,
    #232323 100%
  );
  /* Chrome10+,Safari5.1+ */
  background: -o-radial-gradient(
    center,
    ellipse cover,
    #585858 0%,
    #232323 100%
  );
  /* Opera 12+ */
  background: -ms-radial-gradient(
    center,
    ellipse cover,
    #585858 0%,
    #232323 100%
  );
  /* IE10+ */
  background: radial-gradient(ellipse at center, #585858 0%, #232323 100%);
  /* W3C */
  background-size: 550% 450%;
}

.social-line-black [class*="col-"],
.subscribe-line-black [class*="col-"] {
  border-color: rgba(255, 255, 255, 0.1);
}

/*! nouislider - 11.1.0 - 2018-04-02 11:18:13 */
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
  direction: ltr;
}

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

/* Wrapper for all connect elements.
 */
.noUi-connects {
  overflow: hidden;
  z-index: 0;
}

.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

/* Offset direction
 */
html:not([dir="rtl"]) .noUi-horizontal .noUi-origin {
  left: auto;
  right: 0;
}

/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
  width: 0;
}

.noUi-horizontal .noUi-origin {
  height: 0;
}

.noUi-handle {
  position: absolute;
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 2px;
  margin: 15px 0;
}

.noUi-vertical {
  width: 18px;
}

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  left: -6px;
  top: -17px;
}

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
  right: -10px;
  left: auto;
}

/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.noUi-target {
  background: #c8c8c8;
  border-radius: 4px;
}

.noUi-connects {
  border-radius: 3px;
}

.noUi-connect {
  background-color: #004000;
  border-radius: 4px;
}

/* Handles and cursors;
 */
.noUi-draggable {
  cursor: ew-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}

.noUi-handle {
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  left: -10px;
  top: -6px;
  cursor: pointer;
  border-radius: 100%;
  transition: all 0.2s ease-out;
  border: 1px solid #004000;
  background: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.noUi-active {
  transform: scale3d(1.5, 1.5, 1);
}

/* Handle stripes;
 */
.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px;
}

.noUi-vertical .noUi-handle:after {
  top: 17px;
}

/* Disabled state;
 */
[disabled] .noUi-connect {
  background: #b8b8b8;
}

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}

/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-pips {
  position: absolute;
  color: #999;
}

/* Values;
 *
 */
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}

.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}

/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #ccc;
}

.noUi-marker-sub {
  background: #aaa;
}

.noUi-marker-large {
  background: #aaa;
}

/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}

.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}

.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
}

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}

/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}

.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%, 0);
  padding-left: 25px;
}

.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
}

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}

.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}

.slider {
  background: #c8c8c8;
}

.slider .noUi-connect {
  background-color: #004000;
  border-radius: 4px;
}

.slider .noUi-handle {
  border-color: #004000;
}

.slider.slider-info .noUi-connect {
  background-color: #00bcd4;
}

.slider.slider-info .noUi-handle {
  border-color: #00bcd4;
}

.slider.slider-success .noUi-connect {
  background-color: #4caf50;
}

.slider.slider-success .noUi-handle {
  border-color: #4caf50;
}

.slider.slider-warning .noUi-connect {
  background-color: #ff9800;
}

.slider.slider-warning .noUi-handle {
  border-color: #ff9800;
}

.slider.slider-danger .noUi-connect {
  background-color: #f44336;
}

.slider.slider-danger .noUi-handle {
  border-color: #f44336;
}

.slider.slider-rose .noUi-connect {
  background-color: #e91e63;
}

.slider.slider-rose .noUi-handle {
  border-color: #e91e63;
}

/*!
 * Datetimepicker for Bootstrap 3
 * ! version : 4.17.37
 * https://github.com/Eonasdan/bootstrap-datetimepicker/
 */
.sr-only,
.bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="clear"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="today"]::after,
.bootstrap-datetimepicker-widget .picker-switch::after,
.bootstrap-datetimepicker-widget table th.prev::after,
.bootstrap-datetimepicker-widget table th.next::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.bootstrap-datetimepicker-widget {
  list-style: none;
}

.bootstrap-datetimepicker-widget a .btn:hover {
  background-color: transparent;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  padding: 4px;
  width: 19em;
}

@media (min-width: 768px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}

@media (min-width: 992px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}

@media (min-width: 1200px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}

.bootstrap-datetimepicker-widget.dropdown-menu.bottom:before,
.bootstrap-datetimepicker-widget.dropdown-menu.bottom:after {
  right: auto;
  left: 12px;
}

.bootstrap-datetimepicker-widget.dropdown-menu.top {
  margin-top: auto;
  margin-bottom: -20px;
}

.bootstrap-datetimepicker-widget.dropdown-menu.top.open {
  margin-top: auto;
  margin-bottom: 5px;
}

.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:before {
  left: auto;
  right: 6px;
}

.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:after {
  left: auto;
  right: 7px;
}

.bootstrap-datetimepicker-widget .list-unstyled {
  margin: 0;
}

.bootstrap-datetimepicker-widget a[data-action] {
  padding: 0;
  margin: 0;
  border-width: 0;
  background-color: transparent;
  color: #004000;
  box-shadow: none;
}

.bootstrap-datetimepicker-widget a[data-action]:hover {
  background-color: transparent;
}

.bootstrap-datetimepicker-widget a[data-action]:hover span {
  background-color: #eeeeee;
  color: #004000;
}

.bootstrap-datetimepicker-widget a[data-action]:active {
  box-shadow: none;
}

.bootstrap-datetimepicker-widget .timepicker-hour,
.bootstrap-datetimepicker-widget .timepicker-minute,
.bootstrap-datetimepicker-widget .timepicker-second {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-weight: 300;
  font-size: 1.3em;
  margin: 0;
  border-radius: 50%;
}

.bootstrap-datetimepicker-widget button[data-action] {
  width: 38px;
  height: 38px;
  margin-right: 3px;
  padding: 0;
}

.bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after {
  content: "Increment Hours";
}

.bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after {
  content: "Increment Minutes";
}

.bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after {
  content: "Decrement Hours";
}

.bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after {
  content: "Decrement Minutes";
}

.bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after {
  content: "Show Hours";
}

.bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after {
  content: "Show Minutes";
}

.bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after {
  content: "Toggle AM/PM";
}

.bootstrap-datetimepicker-widget .btn[data-action="clear"]::after {
  content: "Clear the picker";
}

.bootstrap-datetimepicker-widget .btn[data-action="today"]::after {
  content: "Set the date to today";
}

.bootstrap-datetimepicker-widget .picker-switch {
  text-align: center;
  border-radius: 3px;
}

.bootstrap-datetimepicker-widget .picker-switch::after {
  content: "Toggle Date and Time Screens";
}

.bootstrap-datetimepicker-widget .picker-switch td {
  padding: 0;
  margin: 0;
  height: auto;
  width: auto;
  line-height: inherit;
}

.bootstrap-datetimepicker-widget .picker-switch td span {
  line-height: 2.5;
  height: 2.5em;
  width: 100%;
  border-radius: 3px;
  margin: 2px 0px !important;
}

.bootstrap-datetimepicker-widget table {
  width: 100%;
  margin: 0;
}

.bootstrap-datetimepicker-widget table.table-condensed tr > td {
  text-align: center;
}

.bootstrap-datetimepicker-widget table td > div,
.bootstrap-datetimepicker-widget table th > div {
  text-align: center;
}

.bootstrap-datetimepicker-widget table th {
  height: 20px;
  line-height: 20px;
  width: 20px;
  font-weight: 500;
}

.bootstrap-datetimepicker-widget table th.picker-switch {
  width: 145px;
}

.bootstrap-datetimepicker-widget table th.disabled,
.bootstrap-datetimepicker-widget table th.disabled:hover {
  background: none;
  color: #eeeeee;
  cursor: not-allowed;
}

.bootstrap-datetimepicker-widget table th.prev span,
.bootstrap-datetimepicker-widget table th.next span {
  border-radius: 3px;
  height: 27px;
  width: 27px;
  line-height: 28px;
  font-size: 12px;
  border-radius: 50%;
  text-align: center;
}

.bootstrap-datetimepicker-widget table th.prev::after {
  content: "Previous Month";
}

.bootstrap-datetimepicker-widget table th.next::after {
  content: "Next Month";
}

.bootstrap-datetimepicker-widget table th.dow {
  text-align: center;
  border-bottom: 1px solid #eeeeee;
  font-size: 12px;
  text-transform: uppercase;
  color: #333333;
  font-weight: 400;
  padding-bottom: 5px;
  padding-top: 10px;
}

.bootstrap-datetimepicker-widget table thead tr:first-child th {
  cursor: pointer;
}

.bootstrap-datetimepicker-widget table thead tr:first-child th:hover span,
.bootstrap-datetimepicker-widget
  table
  thead
  tr:first-child
  th.picker-switch:hover {
  background: #eeeeee;
}

.bootstrap-datetimepicker-widget table td > div {
  border-radius: 3px;
  height: 54px;
  line-height: 54px;
  width: 54px;
  text-align: center;
}

.bootstrap-datetimepicker-widget table td.cw > div {
  font-size: 0.8em;
  height: 20px;
  line-height: 20px;
  color: #999999;
}

.bootstrap-datetimepicker-widget table td.day > div {
  height: 30px;
  line-height: 30px;
  width: 30px;
  text-align: center;
  padding: 0px;
  border-radius: 50%;
  position: relative;
  z-index: -1;
}

.bootstrap-datetimepicker-widget table td.minute > div,
.bootstrap-datetimepicker-widget table td.hour > div {
  border-radius: 50%;
}

.bootstrap-datetimepicker-widget table td.day:hover > div,
.bootstrap-datetimepicker-widget table td.hour:hover > div,
.bootstrap-datetimepicker-widget table td.minute:hover > div,
.bootstrap-datetimepicker-widget table td.second:hover > div {
  background: #eeeeee;
  cursor: pointer;
}

.bootstrap-datetimepicker-widget table td.old > div,
.bootstrap-datetimepicker-widget table td.new > div {
  color: #999999;
}

.bootstrap-datetimepicker-widget table td.today > div {
  position: relative;
}

.bootstrap-datetimepicker-widget table td.today > div:before {
  content: "";
  display: inline-block;
  border: 0 0 7px 7px solid transparent;
  border-bottom-color: #004000;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}

.bootstrap-datetimepicker-widget table td.active > div,
.bootstrap-datetimepicker-widget table td.active:hover > div {
  background-color: #004000;
  color: #ffffff;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(156, 39, 176, 0.4);
}

.bootstrap-datetimepicker-widget table td.active.today:before > div {
  border-bottom-color: #ffffff;
}

.bootstrap-datetimepicker-widget table td.disabled > div,
.bootstrap-datetimepicker-widget table td.disabled:hover > div {
  background: none;
  color: #eeeeee;
  cursor: not-allowed;
}

.bootstrap-datetimepicker-widget table td span {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 3px 3px;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
}

.bootstrap-datetimepicker-widget table td span:hover {
  background: #eeeeee;
}

.bootstrap-datetimepicker-widget table td span.active {
  background-color: #004000;
  color: #ffffff;
}

.bootstrap-datetimepicker-widget table td span.old {
  color: #999999;
}

.bootstrap-datetimepicker-widget table td span.disabled,
.bootstrap-datetimepicker-widget table td span.disabled:hover {
  background: none;
  color: #eeeeee;
  cursor: not-allowed;
}

.bootstrap-datetimepicker-widget .timepicker-picker span,
.bootstrap-datetimepicker-widget .timepicker-hours span,
.bootstrap-datetimepicker-widget .timepicker-minutes span {
  border-radius: 50% !important;
}

.bootstrap-datetimepicker-widget.usetwentyfour td.hour {
  height: 27px;
  line-height: 27px;
}

.input-group.date .input-group-addon {
  cursor: pointer;
}

.table-condensed > tbody > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > td,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > thead > tr > th {
  padding: 1px;
  text-align: center;
  z-index: 1;
  cursor: pointer;
}

/*!
 * Bootstrap-select v1.11.2 (http://silviomoreto.github.io/bootstrap-select)
 *
 * Copyright 2013-2016 bootstrap-select
 * Licensed under MIT (https://github.com/silviomoreto/bootstrap-select/blob/master/LICENSE)
 */
select.bs-select-hidden,
select.selectpicker {
  display: none !important;
}

.bootstrap-select {
  width: 220px \0;
  /*IE9 and below*/
}

.bootstrap-select > .dropdown-toggle {
  width: 100%;
  padding-right: 25px;
  z-index: 1;
}

.bootstrap-select > select {
  position: absolute !important;
  bottom: 0;
  left: 50%;
  display: block !important;
  width: 0.5px !important;
  height: 100% !important;
  padding: 0 !important;
  opacity: 0 !important;
  border: none;
}

.bootstrap-select > select.mobile-device {
  top: 0;
  left: 0;
  display: block !important;
  width: 100% !important;
  z-index: 2;
}

.has-error .bootstrap-select .dropdown-toggle,
.error .bootstrap-select .dropdown-toggle {
  border-color: #b94a48;
}

.bootstrap-select.fit-width {
  width: auto !important;
}

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%;
}

.bootstrap-select.form-control {
  margin-bottom: 0;
  padding: 0;
  border: none;
}

.bootstrap-select.form-control:not([class*="col-"]) {
  width: 100%;
}

.bootstrap-select.form-control.input-group-btn {
  z-index: auto;
}

.bootstrap-select.form-control.input-group-btn:not(:first-child):not(:last-child)
  > .btn {
  border-radius: 0;
}

.bootstrap-select.btn-group:not(.input-group-btn),
.bootstrap-select.btn-group[class*="col-"] {
  float: none;
  display: inline-block;
  margin-left: 0;
}

.bootstrap-select.btn-group.dropdown-menu-right,
.bootstrap-select.btn-group[class*="col-"].dropdown-menu-right,
.row .bootstrap-select.btn-group[class*="col-"].dropdown-menu-right {
  float: right;
}

.form-inline .bootstrap-select.btn-group,
.form-horizontal .bootstrap-select.btn-group,
.form-group .bootstrap-select.btn-group {
  margin-bottom: 0;
}

.form-group-lg .bootstrap-select.btn-group.form-control,
.form-group-sm .bootstrap-select.btn-group.form-control {
  padding: 0;
}

.form-inline .bootstrap-select.btn-group .form-control {
  width: 100%;
}

.bootstrap-select.btn-group .dropdown-menu li.disabled a:hover,
.bootstrap-select.btn-group .dropdown-menu li.disabled a:focus {
  box-shadow: none;
}

.bootstrap-select.btn-group.disabled,
.bootstrap-select.btn-group > .disabled {
  cursor: not-allowed;
}

.bootstrap-select.btn-group.disabled:focus,
.bootstrap-select.btn-group > .disabled:focus {
  outline: none !important;
}

.bootstrap-select.btn-group.bs-container {
  position: absolute;
  height: 0 !important;
  padding: 0 !important;
}

.bootstrap-select.btn-group.bs-container .dropdown-menu {
  z-index: 1060;
}

.bootstrap-select.btn-group .dropdown-toggle .filter-option {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  text-align: left;
  text-overflow: ellipsis;
}

.bootstrap-select.btn-group .dropdown-toggle .caret {
  position: absolute;
  top: 50%;
  right: 16px;
  margin-top: -2px;
  vertical-align: middle;
}

.bootstrap-select.btn-group[class*="col-"] .dropdown-toggle {
  width: 100%;
}

.bootstrap-select.btn-group .dropdown-menu {
  border-radius: 4px;
  padding: 0;
  min-width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.bootstrap-select.btn-group .dropdown-menu.inner {
  position: static;
  float: none;
  border: 0;
  padding: 5px 0;
  margin: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -ms-overflow-style: auto;
}

.bootstrap-select.btn-group .dropdown-menu li {
  position: relative;
}

.bootstrap-select.btn-group .dropdown-menu li.active small {
  color: #fff;
}

.bootstrap-select.btn-group .dropdown-menu li.disabled a {
  cursor: not-allowed;
}

.bootstrap-select.btn-group .dropdown-menu li a {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
}

.bootstrap-select.btn-group .dropdown-menu li a:focus,
.bootstrap-select.btn-group .dropdown-menu li a:hover {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(156, 39, 176, 0.6);
}

.bootstrap-select.btn-group .dropdown-menu li a.opt {
  position: relative;
  padding-left: 2.25em;
}

.bootstrap-select.btn-group .dropdown-menu li a span.check-mark {
  display: none;
}

.bootstrap-select.btn-group .dropdown-menu li a span.text {
  display: inline-block;
}

.bootstrap-select.btn-group .dropdown-menu li small {
  padding-left: 0.5em;
}

.bootstrap-select.btn-group .dropdown-menu .notify {
  position: absolute;
  bottom: 5px;
  width: 96%;
  margin: 0 2%;
  min-height: 26px;
  padding: 3px 5px;
  background: #f5f5f5;
  border: 1px solid #e3e3e3;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  pointer-events: none;
  opacity: 0.9;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.bootstrap-select.btn-group .no-results {
  padding: 3px;
  background: #f5f5f5;
  margin: 0 5px;
  white-space: nowrap;
}

.bootstrap-select.btn-group.fit-width .dropdown-toggle .filter-option {
  position: static;
}

.bootstrap-select.btn-group.fit-width .dropdown-toggle .caret {
  position: static;
  top: auto;
  margin-top: -1px;
}

.bootstrap-select.btn-group.show-tick
  .dropdown-menu
  li.selected
  a
  span.check-mark {
  position: absolute;
  display: inline-block;
  right: 15px;
  font-size: 16px;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li a span.text {
  margin-right: 34px;
}

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle {
  z-index: 1061;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle:before {
  content: "";
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgba(204, 204, 204, 0.2);
  position: absolute;
  bottom: -4px;
  left: 9px;
  display: none;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle:after {
  content: "";
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  position: absolute;
  bottom: -4px;
  left: 10px;
  display: none;
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:before {
  bottom: auto;
  top: -3px;
  border-top: 7px solid rgba(204, 204, 204, 0.2);
  border-bottom: 0;
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:after {
  bottom: auto;
  top: -3px;
  border-top: 6px solid white;
  border-bottom: 0;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:before {
  right: 12px;
  left: auto;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:after {
  right: 13px;
  left: auto;
}

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle:before,
.bootstrap-select.show-menu-arrow.open > .dropdown-toggle:after {
  display: block;
}

.bs-searchbox,
.bs-actionsbox,
.bs-donebutton {
  padding: 4px 8px;
}

.bs-actionsbox {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.bs-actionsbox .btn-group button {
  width: 50%;
}

.bs-donebutton {
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.bs-donebutton .btn-group button {
  width: 100%;
}

.bs-searchbox + .bs-actionsbox {
  padding: 0 8px 4px;
}

.bs-searchbox .form-control {
  margin-bottom: 0;
  width: 100%;
  float: none;
}

.select-with-transition {
  border: 0 !important;
  background-image: linear-gradient(#004000, #004000),
    linear-gradient(#d2d2d2, #d2d2d2);
  background-size: 0 2px, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px);
  background-color: rgba(0, 0, 0, 0) !important;
  transition: background 0s ease-out !important;
  float: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  color: #3c4858 !important;
  height: 34px;
  padding-left: 0 !important;
  padding-bottom: 5px !important;
}

.select-with-transition .caret,
.select-with-transition .ripple-container {
  display: none;
}

.btn-group.bootstrap-select.show-tick.open .select-with-transition {
  outline: none !important;
  background-image: linear-gradient(#004000, #004000),
    linear-gradient(#d2d2d2, #d2d2d2) !important;
  background-size: 100% 2px, 100% 1px !important;
  box-shadow: none;
  transition-duration: 0.3s !important;
}

.withripple {
  position: relative;
}

.ripple-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: inherit;
  pointer-events: none;
}

.disabled .ripple-container {
  display: none;
}

.ripple {
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  margin-top: -10px;
  border-radius: 100%;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.05);
  transform: scale(1);
  transform-origin: 50%;
  opacity: 0;
  pointer-events: none;
}

.ripple.ripple-on {
  transition: opacity 0.15s ease-in 0s,
    transform 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
  opacity: 0.1;
}

.ripple.ripple-out {
  transition: opacity 0.1s linear 0s !important;
  opacity: 0;
}

.main {
  background: #ffffff;
  position: relative;
  z-index: 3;
}

.main-raised {
  margin: -60px 30px 0px;
  border-radius: 6px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.gallery .image img {
  width: 100%;
}

.team {
  margin-top: 80px;
}

.team .team-player .title {
  margin: 30px auto;
}

.team .team-player img {
  max-width: 170px;
}

.presentation-page .page-header,
.index-page .page-header {
  height: 90vh;
  overflow: hidden;
}

.presentation-page .brand,
.index-page .brand {
  color: #ffffff;
  text-align: center;
}

.presentation-page .brand h1,
.index-page .brand h1 {
  font-size: 4.8em;
  font-weight: 600;
  display: inline-block;
  position: relative;
}

.presentation-page .brand h3,
.index-page .brand h3 {
  font-size: 1.5em;
  max-width: 490px;
  margin: 10px auto 0;
}

.presentation-page .pro-badge,
.index-page .pro-badge {
  position: absolute;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: bold;
  right: -90px;
  padding: 10px 18px;
  top: -3px;
  background: #ffffff;
  border-radius: 3px;
  color: #444444;
  line-height: 22px;
  box-shadow: 0 5px 5px -2px rgba(31, 31, 31, 0.4);
}

.presentation-page .header-filter:after,
.index-page .header-filter:after {
  background: rgba(132, 13, 121, 0.88);
  background: linear-gradient(
    45deg,
    rgba(132, 13, 121, 0.88) 0%,
    rgba(208, 44, 180, 0.31) 100%
  );
  background: -moz-linear-gradient(
    135deg,
    rgba(132, 13, 121, 0.88) 0%,
    rgba(208, 44, 180, 0.31) 100%
  );
  background: -webkit-linear-gradient(
    135deg,
    rgba(132, 13, 121, 0.88) 0%,
    rgba(208, 44, 180, 0.31) 100%
  );
}

.presentation-page h4.description,
.index-page h4.description {
  font-size: 1.5em;
}

.presentation-page .features-1,
.index-page .features-1 {
  padding-top: 30px;
  padding-bottom: 0;
}

.presentation-page .features-1 .info .info-title,
.index-page .features-1 .info .info-title {
  margin-top: 30px;
}

.presentation-page .features-1 h2,
.index-page .features-1 h2 {
  margin-bottom: 0px;
}

.presentation-page .features-1 .info p,
.index-page .features-1 .info p {
  font-size: 16px;
  line-height: 1.6em;
}

.presentation-page .section-components,
.index-page .section-components {
  padding: 0 0 50px;
  overflow: hidden;
}

.presentation-page .section-components .image-container,
.index-page .section-components .image-container {
  height: 590px;
  position: relative;
}

.presentation-page .section-components .image-container img,
.index-page .section-components .image-container img {
  width: auto;
  left: 0;
  top: 0;
  height: 100%;
  position: absolute;
}

.presentation-page .section-components .title,
.index-page .section-components .title {
  margin-top: 140px;
  margin-bottom: 5px;
}

.presentation-page .section-components h6.description,
.index-page .section-components h6.description {
  margin-top: 5px;
  margin-bottom: 30px;
}

.presentation-page .section-cards,
.index-page .section-cards {
  padding: 50px 0;
}

.presentation-page .section-cards .image-container,
.index-page .section-cards .image-container {
  max-width: 1040px;
  margin-top: -140px;
  position: relative;
  height: 660px;
}

.presentation-page .section-cards .image-container img,
.index-page .section-cards .image-container img {
  max-width: 1040px;
  width: auto;
  position: absolute;
  right: 0;
  top: 0px;
}

.presentation-page .section-cards .col-md-4,
.index-page .section-cards .col-md-4 {
  perspective: 800px;
}

.presentation-page .section-cards .card,
.index-page .section-cards .card {
  transform-style: preserve-3d;
  max-width: 340px;
  margin: 0 auto;
}

.presentation-page .section-cards .section-description,
.index-page .section-cards .section-description {
  margin-top: 130px;
}

.presentation-page .section-content,
.index-page .section-content {
  padding-top: 200px;
}

.presentation-page .section-content .image-container,
.index-page .section-content .image-container {
  max-width: 900px;
  position: relative;
}

.presentation-page .section-content .image-container img,
.index-page .section-content .image-container img {
  width: 100%;
}

.presentation-page .section-content .section-description,
.index-page .section-content .section-description {
  margin-top: 100px;
}

.presentation-page .section-sections,
.index-page .section-sections {
  padding-bottom: 0;
  margin-bottom: -35px;
}

.presentation-page .section-sections .section-description,
.index-page .section-sections .section-description {
  text-align: center;
  margin-bottom: 60px;
}

.presentation-page .section-sections .col-md-3,
.index-page .section-sections .col-md-3 {
  padding: 7.5px;
}

.presentation-page .section-sections img,
.index-page .section-sections img {
  width: 100%;
  border-radius: 3px;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.presentation-page .section-sections img:hover,
.index-page .section-sections img:hover {
  -webkit-transform: scale(1.02);
  -moz-transform: scale(1.02);
  -o-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
}

.presentation-page .section-examples .section-description,
.index-page .section-examples .section-description {
  text-align: center;
  margin-bottom: 90px;
}

.presentation-page .section-examples .card,
.index-page .section-examples .card {
  margin-bottom: 30px;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.presentation-page .section-examples .card img,
.index-page .section-examples .card img {
  border-radius: 4px;
}

.presentation-page .section-examples .card:hover,
.index-page .section-examples .card:hover {
  -webkit-transform: translate3d(0, -10px, 0);
  -moz-transform: translate3d(0, -10px, 0);
  -o-transform: translate3d(0, -10px, 0);
  -ms-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
}

.presentation-page .section-examples h4.title,
.index-page .section-examples h4.title {
  text-align: center;
  margin-bottom: 15px;
}

.presentation-page .section-free-demo .icon-github,
.index-page .section-free-demo .icon-github {
  font-size: 82px;
  color: #777777;
}

.presentation-page .section-free-demo h2.title,
.index-page .section-free-demo h2.title {
  margin-top: 15px;
}

.presentation-page .section-free-demo .iframe-github,
.index-page .section-free-demo .iframe-github {
  top: 6px;
  display: inline-block;
  position: relative;
  margin-left: 10px;
}

.presentation-page .section-free-demo .card-title,
.presentation-page .section-free-demo ul li,
.index-page .section-free-demo .card-title,
.index-page .section-free-demo ul li {
  text-align: left;
}

.presentation-page .section-free-demo ul li b,
.index-page .section-free-demo ul li b {
  min-width: 24px;
  display: inline-block;
  text-align: center;
}

.presentation-page .section-overview .features-5,
.index-page .section-overview .features-5 {
  margin-left: -10px;
  margin-right: -10px;
  border-radius: 6px;
  overflow: hidden;
}

.presentation-page .section-overview .features-5 .info p,
.index-page .section-overview .features-5 .info p {
  font-size: 16px;
  line-height: 1.6em;
}

.presentation-page .section-overview .features-5 .info .icon > i,
.index-page .section-overview .features-5 .info .icon > i {
  font-size: 3.5em;
}

.presentation-page .section-overview .features-5:after,
.index-page .section-overview .features-5:after {
  background: rgba(0, 0, 0, 0.75);
}

.presentation-page .section-testimonials,
.index-page .section-testimonials {
  padding-top: 50px;
  padding-bottom: 0;
}

.presentation-page .section-testimonials h3.title,
.index-page .section-testimonials h3.title {
  margin-top: 10px;
  margin-bottom: 50px;
}

.presentation-page .section-testimonials .card-title,
.index-page .section-testimonials .card-title {
  margin-top: 0px;
}

.presentation-page .section-testimonials .card .content,
.index-page .section-testimonials .card .content {
  padding-top: 0px;
}

.presentation-page .section-testimonials .card-description,
.index-page .section-testimonials .card-description {
  font-size: 16px;
  line-height: 1.6em;
}

.presentation-page .section-testimonials .our-clients,
.index-page .section-testimonials .our-clients {
  text-align: center;
}

.presentation-page .section-testimonials .our-clients img,
.index-page .section-testimonials .our-clients img {
  width: 100%;
  max-width: 140px;
  margin: 0 auto;
  display: inline-block;
}

.presentation-page .section-pricing,
.index-page .section-pricing {
  z-index: 3;
  position: relative;
}

.presentation-page .section-pricing .nav-pills,
.index-page .section-pricing .nav-pills {
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 0px;
}

.presentation-page .section-pricing .card-pricing,
.index-page .section-pricing .card-pricing {
  margin-top: 45px;
}

.presentation-page .section-pricing .description,
.index-page .section-pricing .description {
  text-align: center;
}

.presentation-page .section-pricing .card-margin,
.index-page .section-pricing .card-margin {
  margin-top: 80px;
}

.landing-page .page-header {
  min-height: 100vh;
}

.landing-page .page-header .share {
  margin-top: 150px;
}

.landing-page .page-header .title {
  color: #ffffff;
}

.landing-page .wrapper {
  background: #cccccc;
}

.landing-page .section-contacts {
  padding-top: 0;
}

.profile-page .page-header {
  height: 380px;
  background-position: top center;
}

.profile-page .profile {
  text-align: center;
}

.profile-page .profile img {
  max-width: 160px;
  width: 100%;
  margin: 0 auto;
  -webkit-transform: translate3d(0, -50%, 0);
  -moz-transform: translate3d(0, -50%, 0);
  -o-transform: translate3d(0, -50%, 0);
  -ms-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}

.profile-page .profile .name {
  margin-top: -80px;
}

.profile-page .description {
  margin: 15px auto 0;
  max-width: 600px;
}

.profile-page .profile-tabs {
  margin-top: 60px;
}

.profile-page .gallery {
  margin-top: 45px;
  padding-bottom: 50px;
}

.profile-page .gallery img {
  width: 100%;
  margin-bottom: 30px;
}

.profile-page .follow .btn-fab {
  margin-top: -28px;
}

.profile-page .card-background .content {
  padding: 30% 0;
  min-width: 160px;
}

.profile-page .work {
  padding: 40px 0px;
}

.profile-page .work .collections {
  margin-top: 20px;
}

.profile-page .work .stats ul > li {
  padding: 5px 0px;
  font-size: 1em;
}

.profile-page .work .stats ul > li b {
  font-size: 1.2em;
}

.profile-page .connections {
  padding: 40px 0px;
}

.profile-page .connections .card-profile {
  text-align: left;
}

.blog-post .page-header {
  min-height: 100vh;
}

.blog-post .section-text {
  padding-bottom: 0;
}

.blog-post .section-text p {
  font-size: 1.35em;
  line-height: 1.5em;
  color: #555555;
  margin-bottom: 30px;
}

.blog-post .section-blog-info {
  padding-top: 30px;
  padding-bottom: 0px;
}

.blog-post .section-blog-info .btn {
  margin-top: 0;
  margin-bottom: 0;
}

.blog-post .section-blog-info .blog-tags {
  padding-top: 8px;
}

.blog-post .section-blog-info .card-profile {
  margin-top: 0;
  text-align: left;
}

.blog-post .section-blog-info .card-profile .description {
  font-size: 14px;
}

.blog-post .section-blog-info .card-profile .btn {
  margin-top: 25px;
}

.blog-post .section-comments .title {
  margin-bottom: 30px;
}

.blog-post .media p {
  color: #555555;
}

.login-page .page-header {
  min-height: 100vh;
  height: auto;
}

.login-page .page-header .container {
  padding-top: 20vh;
}

.login-page .footer .container {
  padding: 0;
}

.login-page .footer .copyright,
.login-page .footer a {
  color: #ffffff;
}

.signup-page .page-header {
  min-height: 100vh;
  height: auto;
}

.signup-page .page-header .container {
  padding-top: 20vh;
}

.signup-page .card-signup {
  border-radius: 6px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  margin-bottom: 100px;
  padding: 40px 0px;
}

.signup-page .info-horizontal {
  padding: 0px 0px 20px;
}

.signup-page .social .btn {
  margin: 5px;
}

.signup-page .social h4 {
  margin-top: 20px;
}

.signup-page .footer .container {
  padding: 0;
}

.signup-page .footer .copyright,
.signup-page .footer a {
  color: #ffffff;
}

.landing-page .navbar-transparent,
.profile-page .navbar-transparent,
.login-page .navbar-transparent,
.index-page .navbar-transparent,
.signup-page .navbar-transparent,
.presentation-page .navbar-transparent {
  padding-top: 25px;
}

.contact-page .contact-content {
  padding-bottom: 40px;
  padding-top: 40px;
}

.contact-page .container {
  max-width: 970px;
}

.contact-page .big-map {
  height: 55vh;
  width: 100%;
  display: block;
  position: relative;
}

.contact-page .message {
  margin-top: 20px;
}

.contact-page .info {
  padding-bottom: 10px;
  padding-top: 0px;
}

.contact-page .info .title {
  margin: 25px 0 10px;
}

.product-page {
  background-color: #eee;
}

.product-page .page-header {
  min-height: 60vh;
  height: auto;
  background-position: top center;
}

.product-page .page-header .container {
  padding-top: 18vh;
}

.product-page .main-raised {
  margin: -40vh 0px 0px;
  padding: 40px;
}

.product-page .title-row {
  margin-top: 20px;
}

.product-page .tab-content {
  margin: 30px 0px;
  overflow: hidden;
  width: 100%;
  height: auto;
  text-align: center;
}

.product-page .tab-content img {
  border-radius: 3px;
  max-width: 300px;
  height: auto;
}

.product-page .main-price {
  margin: 10px 0 25px;
}

.product-page .pick-size {
  margin-top: 50px;
}

.product-page .pick-size .form-group {
  margin-top: 5px;
}

.product-page h2.title {
  margin-bottom: 0px;
}

.product-page .flexi-nav {
  margin: 20px 0 30px;
  text-align: center;
}

.product-page .flexi-nav > li a {
  width: 80%;
  max-width: 85px;
  margin: 0 auto;
  padding: 8px;
  border: 1px solid transparent;
  background: transparent;
  border-radius: 3px;
  opacity: 0.8;
}

.product-page .flexi-nav > li a:hover,
.product-page .flexi-nav > li a:focus,
.product-page .flexi-nav > li a:active {
  opacity: 1;
  border-color: #dddddd;
}

.product-page .flexi-nav > li a img {
  border-radius: 3px;
  width: 100%;
  height: auto;
  text-align: center;
}

.product-page .flexi-nav > li.active a {
  opacity: 1;
  border-color: #dddddd;
}

.product-page .related-products {
  margin-top: 50px;
}

.product-page .related-products .title {
  margin-bottom: 80px;
}

.product-page .features {
  padding-top: 30px;
}

.pricing .page-header .container {
  padding-top: 20vh;
  color: #ffffff;
  text-align: center;
}

.pricing .page-header .title {
  color: #ffffff;
}

.pricing .features-2 {
  padding-top: 20px;
}

.pricing .features-2 .info {
  padding-top: 50px;
}

.ecommerce-page .container {
  position: relative;
  z-index: 2;
}

.ecommerce-page .page-header .container {
  padding-top: 20vh;
  color: #ffffff;
  text-align: center;
}

.ecommerce-page .card-refine .panel-collapse {
  max-height: 273px;
  overflow-y: scroll;
}

.ecommerce-page .card-refine .panel-rose a:hover,
.ecommerce-page .card-refine .panel-rose a:focus,
.ecommerce-page .card-refine .panel-rose a[aria-expanded="true"] {
  color: #e91e63;
}

.ecommerce-page .card-refine .panel-body {
  padding-left: 15px;
  padding-right: 15px;
}

.ecommerce-page .card-refine .panel-body.panel-refine {
  padding-left: 15px;
  padding-right: 15px;
}

.ecommerce-page .card-refine .panel-heading i {
  float: none;
}

.ecommerce-page .card-refine .price-right,
.ecommerce-page .card-refine .price-left {
  font-weight: 500;
}

.ecommerce-page .card-refine .btn {
  margin: -3px 0;
}

.ecommerce-page .card-refine .panel {
  margin-bottom: 0;
}

.ecommerce-page
  .card-refine
  .checkbox
  input[type="checkbox"]:checked
  + .checkbox-material
  .check {
  background: #e91e63;
}

.ecommerce-page .card-refine .panel-title {
  display: inline-block;
  width: calc(100% - 28px);
}

.ecommerce-page .card-product .price-container {
  margin-top: 9px;
}

.ecommerce-page .card-product .btn {
  margin-top: 0;
}

.ecommerce-page .card-product .image {
  overflow: hidden;
  border-radius: 6px;
}

.about-us .page-header {
  background-size: cover;
}

.about-us .page-header .container {
  padding-top: 20vh;
  color: #ffffff;
  text-align: center;
}

.about-us .page-header .title {
  color: #ffffff;
}

.about-us .about-description {
  padding: 70px 0px 0px 0px;
}

.about-us .about-services {
  padding-top: 10px;
}

.about-us .about-office .description {
  margin-bottom: 70px;
}

.about-us .about-office img {
  margin: 20px 0px;
}

.about-us .about-contact {
  padding: 80px 0px;
}

.about-us .about-contact .description {
  margin-bottom: 70px;
}

@media (max-width: 768px) {
  .footer .copyright {
    display: inline-block;
    text-align: center;
    padding: 10px 0;
    float: none !important;
    width: 100%;
  }
  .navbar.navbar-transparent {
    padding-top: 10px;
    border-radius: 0;
  }
  .main-raised {
    margin-left: 10px;
    margin-right: 10px;
  }
  .profile-page .page-header {
    min-height: 40vh;
    height: auto;
  }
}

@media (min-width: 768px) {
  .presentation-page .section-sections .row {
    margin-left: -20px;
    margin-right: -20px;
  }
  .presentation-page .section-sections .container-fluid {
    padding: 0;
  }
}

@-webkit-keyframes go-down {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}

@keyframes go-down {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}

@media (min-width: 992px) {
  .navbar .navbar-collapse {
    text-align: center;
  }
  .navbar .navbar-center {
    display: inline-block;
    float: none;
  }
  .table-responsive {
    overflow: auto;
  }
}

@media (max-width: 991px) {
  .page-header {
    height: auto;
    min-height: 60vh;
  }
  .page-header .iframe-container {
    padding: 30px 0;
  }
  .footer nav,
  .footer .social-area {
    float: none !important;
  }
  .card-form-horizontal .form-group {
    padding-bottom: 20px;
  }
  .contactus-2 .card-contact {
    margin: 45px 0 0;
  }
  .contactus-2 .map {
    height: auto;
    min-height: 910px;
  }
  .carousel .page-header {
    padding: 0 30px;
  }
  .carousel .buttons {
    margin-bottom: 100px;
  }
  .btn.btn-simple,
  .navbar .navbar-nav > li > a.btn.btn-simple {
    padding-left: 0;
    padding-right: 0;
    text-align: left;
  }
  .presentation-page .section-components .image-container,
  .index-page .section-components .image-container {
    height: 400px;
  }
  .presentation-page .section-components .image-container img,
  .index-page .section-components .image-container img {
    max-width: 410px;
    height: auto;
  }
  .presentation-page .section-cards .image-container,
  .index-page .section-cards .image-container {
    height: 300px;
    margin-top: -100px;
  }
  .presentation-page .section-cards .image-container img,
  .index-page .section-cards .image-container img {
    max-width: 500px;
  }
  .presentation-page .section-content,
  .index-page .section-content {
    padding-top: 0;
  }
  .presentation-page .section-overview .card .content,
  .index-page .section-overview .card .content {
    text-align: center;
  }
  .presentation-page .section-overview .card-image,
  .index-page .section-overview .card-image {
    max-width: 60px;
    margin: 0 auto 20px;
  }
}

@media (max-width: 767px) {
  h1,
  .h1,
  .presentation-page .brand h1 {
    font-size: 3em;
  }
  .presentation-page .pro-badge {
    font-size: 14px;
    margin-right: 0;
    right: -47px;
    padding: 4px 8px;
    top: -4px;
    display: inline-block;
  }
  .subscribe-line .btn {
    margin-top: 15px;
  }
}

@media all and (max-width: 992px) {
  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 300px;
    content: "";
    z-index: 1029;
    overflow-x: hidden;
  }
  .navbar-header {
    float: none;
  }
  .navbar-header .navbar-toggle {
    display: block;
    float: right;
  }
  .navbar-collapse {
    position: fixed;
    display: block;
    top: 0px;
    height: 100vh;
    width: 230px;
    right: 0;
    margin-right: 0 !important;
    z-index: 1032;
    visibility: visible;
    background-color: #999;
    overflow-y: visible;
    border-top: none;
    text-align: left;
    padding-right: 0;
    padding-left: 0;
    max-height: none !important;
    -webkit-transform: translate3d(230px, 0, 0);
    -moz-transform: translate3d(230px, 0, 0);
    -o-transform: translate3d(230px, 0, 0);
    -ms-transform: translate3d(230px, 0, 0);
    transform: translate3d(230px, 0, 0);
    -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .navbar-collapse.collapse {
    height: 100vh !important;
  }
  .navbar-collapse ul {
    position: relative;
    z-index: 3;
    overflow-y: auto;
    height: 100%;
    float: none !important;
    margin: 0;
  }
  .navbar-collapse .nav > li:after {
    width: calc(100% - 30px);
    content: "";
    display: block;
    height: 1px;
    margin-left: 15px;
    background-color: #e5e5e5;
  }
  .navbar-collapse .nav > li:last-child:after {
    display: none;
  }
  .navbar-collapse::after {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: #ffffff;
    display: block;
    content: "";
    z-index: 1;
  }
  .navbar.navbar-transparent .navbar-toggle .icon-bar {
    color: #ffffff;
  }
  .navbar.navbar-default .navbar-toggle .icon-bar {
    color: inherit;
  }
  .navbar .navbar-collapse .caret {
    position: absolute;
    right: 16px;
    margin-top: 8px;
  }
  .navbar .navbar-collapse .nav > li {
    padding: 0;
    float: none;
  }
  .navbar .navbar-collapse .navbar-nav {
    margin-top: 0;
  }
  .navbar .navbar-collapse .navbar-nav > li > a {
    color: #3c4858;
    margin: 5px 15px;
  }
  .navbar .navbar-collapse .navbar-nav > li > a:hover,
  .navbar .navbar-collapse .navbar-nav > li > a:focus {
    color: #3c4858;
  }
  .navbar .navbar-collapse .navbar-nav > li.button-container > a {
    margin: 15px;
  }
  .navbar .navbar-collapse .navbar-nav > li.open > .dropdown-menu {
    padding-bottom: 10px;
    margin-bottom: 5px;
    box-shadow: none;
  }
  .navbar .navbar-collapse .open .dropdown-menu.dropdown-with-icons > li > a {
    padding: 12px 20px 12px 35px;
  }
  .navbar .navbar-collapse .open .dropdown-menu > li > a {
    color: #3c4858;
    margin: 0;
    padding-left: 46px;
  }
  .navbar .navbar-collapse .open .dropdown-menu > li > a:hover,
  .navbar .navbar-collapse .open .dropdown-menu > li > a:focus {
    color: #ffffff;
  }
  .navbar .navbar-collapse .dropdown-menu li {
    margin: 0 15px;
  }
  .navbar .navbar-collapse .dropdown.open .dropdown-menu {
    display: block;
  }
  .navbar .navbar-collapse .dropdown .dropdown-menu {
    display: none;
  }
  nav .container,
  nav .navbar-header {
    -webkit-transition: transform 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: transform 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: transform 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: transform 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: transform 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .nav-open nav .navbar-header {
    left: 0;
    -webkit-transform: translate3d(-245px, 0, 0);
    -moz-transform: translate3d(-245px, 0, 0);
    -o-transform: translate3d(-245px, 0, 0);
    -ms-transform: translate3d(-245px, 0, 0);
    transform: translate3d(-245px, 0, 0);
  }
  .nav-open .navbar-collapse {
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate3d(0px, 0, 0);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
  }
}

@media all and (max-width: 768px) {
  .features-5 .container [class*="col-"] {
    border-right: 0;
    border-left: 0;
  }
  .features-5 .container .row:last-child [class*="col-"]:last-child,
  .features-5 .container .row:last-child [class*="col-"]:nth-last-child(2) {
    border-top: 1px solid rgba(255, 255, 255, 0.35);
  }
  .modal.modal-signup {
    width: 900px;
  }
}

@media all and (max-width: 1050px) and (min-width: 991px) {
  .main.main-raised .container {
    width: 920px;
  }
}

.cls-1,
.cls-12,
.cls-30,
.cls-31,
.cls-32,
.cls-52,
.cls-53 {
  fill: #493829;
}

.cls-13,
.cls-2 {
  fill: #a9a18c;
}

.cls-3 {
  fill: #b99c6b;
}

.cls-4 {
  fill: #dbca69;
}

.cls-5 {
  fill: #a3adb8;
}

.cls-6 {
  fill: #bdd09f;
}

.cls-7 {
  fill: #fff;
  stroke: #000;
  stroke-miterlimit: 10;
}

.cls-8 {
  font-size: 5px;
  fill: #662d91;
}

.cls-10,
.cls-11,
.cls-12,
.cls-13,
.cls-30,
.cls-31,
.cls-32,
.cls-8,
.cls-9,
.cls-52,
.cls-53 {
  font-family: "Reem Kufi", "Helvetica", sans-serif;
}

.cls-10,
.cls-11,
.cls-9 {
  font-size: 36px;
}

.cls-10 {
  letter-spacing: 0em;
}

.cls-11 {
  letter-spacing: 0em;
}

/* used for text around country flag row in the SVG */
.cls-12 {
  font-size: 130px;
  font-family: "Reem Kufi", "Helvetica", sans-serif;
}
/* font for METHOD row in the SVG  */
.cls-13 {
  font-size: 95px;
  font-family: "Reem Kufi", "Helvetica", sans-serif;
}


.cls-foodchoice {
  /* fill: #a9a18c; */
  fill: #b3b3b3;
  font-size: 65px; 
  font-family:'Reem Kufi', sans-serif;

}
.cls-14 {
  fill: #101010;
}

.cls-15 {
  fill: #787a77;
}

.cls-16 {
  fill: #cccfcd;
}

.cls-17 {
  fill: #d9dcde;
}

.cls-18 {
  fill: #878a88;
}

.cls-19 {
  fill: #f8f8f8;
}

.cls-20 {
  fill: #646560;
}

.cls-21 {
  fill: #adb0ae;
}

.cls-22 {
  fill: #9ea19f;
}

.cls-23 {
  fill: #f2f3f6;
}

.cls-24 {
  fill: #ededed;
}

.cls-25 {
  fill: #b9bcba;
}

.cls-26 {
  fill: #4a4b46;
}

.cls-27 {
  fill: #c3c6c4;
}

.cls-28 {
  fill: #939497;
}

.cls-29 {
  fill: #333534;
}

/* for the verification row  of the SVG */
.cls-30 {
  font-size: 125px;
  font-family: "Reem Kufi", "Helvetica", sans-serif;
}

/* the row where we display the year in the SVG */
.cls-31,
.cls-32 {
  font-size: 300px;
  font-family: "Reem Kufi", "Helvetica", sans-serif;
}

.cls-32 {
  letter-spacing: 0em;
}

/* Transport */
.cls-33 {
  fill: #b3b3b3;
}

/* Electrify */
.cls-34 {
  /* origin .cls-14 */
  fill: #ececf7;
}

.cls-35 {
  /* origin .cls-15 */
  fill: #6f69cb;
}

.cls-36 {
  /* origin .cls-16 */
  fill: #1532bc;
}

/* Ambient */
.cls-37 {
  /* origin .cls-14 */
  fill: #28a3d7;
}

.cls-38 {
  /* origin .cls-15 */
  fill: #28a4d8;
}

.cls-39 {
  /* origin .cls-16 */
  fill: #29a1d4;
}

.cls-40 {
  /* origin .cls-17 */
  fill: #29a3d6;
}

.cls-41 {
  /* origin .cls-18 */
  fill: #299fd1;
}

/* Conserve */
.cls-42 {
  /* origin .cls-13 */
  fill: #418005;
}

.cls-43 {
  /* origin .cls-14 */
  fill: #408004;
}

/* Agriculture */
.cls-44 {
  /* origin .cls-13 */
  fill: #2b2d27;
}

.cls-45 {
  /* origin .cls-14 */
  fill: #4a5449;
}

.cls-46 {
  /* origin .cls-15 */
  fill: #a0927f;
}

.cls-47 {
  /* origin .cls-16 */
  fill: #bdad99;
}

.cls-48 {
  /* origin .cls-17 */
  fill: #d5c4ae;
}

.cls-49 {
  /* origin .cls-18 */
  fill: #87abdd;
}

.cls-50 {
  /* origin .cls-19 */
  fill: #bacee9;
}

.cls-51 {
  /* origin .cls-20 */
  fill: #c4d4e9;
}

.cls-52 {
  font-size: 48px;
  font-weight: 400;
  color: #004d00;
  font-family: "Reem Kufi", "Helvetica", sans-serif;
}

/* quantity row  of SVG */
.cls-53 {
  font-size: 220px;
  font-weight: 500;
  color: #004d00;
  font-family: "Reem Kufi", "Helvetica", sans-serif;
}

.custom-select-box {
  display: flex;
  border: 2px solid #BDBDBD;
  padding-top: 4px;
  padding-left: 24px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.custom-select-label {
  cursor: pointer;
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  white-space: nowrap;
  color: #505050;
}
.custom-select-dropdown-cursor {
  cursor: pointer;
  font-size: 20px;
  line-height: 28px;
  color: #adadad;
  padding: 16px 16px;
}
.custom-select-dropdown-list {
  position: absolute;
  overflow: auto;
  color: #000;
  background-color: #fff;
  white-space: nowrap;
  top: 100%;
  left: 0;
  min-width: 100%;
  max-height: 300px;
  z-index: 50;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06);
}
.custom-select-item {
    padding: 8px 16px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;

}
.custom-select-item:hover {
    color: #ffffff;
    background-color: #004000;
}

.custom-input-box {
  position: relative;
  width: 100%;
  padding-top: 4px;
  padding-left: 24px;
  align-items: center;
  border-radius: 8px;
  z-index: 10;
}
.custom-input-label {
  display: block;
  padding-top: 8px;
  color: #ADADAD;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  white-space: nowrap;
}
.custom-input {
  position: relative;
  padding-left: 8px;
  padding-right: 16px;
  width: 100%;
  height: 50px;
  border-style: none;
  background-color: transparent;
  outline: 2px solid transparent;
  outline-offset: 2px;
  z-index: 30;
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
}
.custom-input-file-icon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 20;
}
.custom-input-file-placeholder {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  left: 16px;
  padding-left: 8px;
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  z-index: 10;
}

span.title {
  margin-top: 100px;
  font-family: Roboto;
  font-size: 44px;
  line-height: 1.15em;
  font-weight: 500;
  color: #004d00;
}
span.title-emphasis {
  display: inline-block;
  font-family: Roboto;
  font-size: 44px;
  line-height: 1.15em;
  font-weight: 800;
  color: #004d00;
}
p.title-description {
  margin-left: 25px;
  margin-right: 25px;
  font-family: Roboto;
  font-size: 24px;
  line-height: 1.5em;
}
